import React from "react";
import "tailwindcss/tailwind.css";

interface TypographyProps {
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | undefined;
  className?: string;
  style?: React.CSSProperties; // Add the style prop
  children: React.ReactNode;
}

const Typography: React.FC<TypographyProps> = ({
  variant,
  className,
  style,
  children,
}) => {
  const variantClasses = {
    h1: "text-4xl",
    h2: "text-3xl",
    h3: "text-[30px]",
    h4: "text-xl",
    h5: "text-lg",
    h6: "text-base",
    p: "text-[13px]",
  };

  const classes = `${variant && variantClasses?.[variant]} ${className || ""}`;

  return (
    <div className={classes} style={{ ...style, fontWeight: "bold" }}>
      {children}
    </div>
  );
};

export default Typography;
