import { useEffect, useState } from "react";
import { DataSourceSidePanel } from "../molecules/MainCopilot/DataSourceSidePanel";
import { IntegrationSidePanel } from "../molecules/MainCopilot/Integrations/IntegrationSidePanel";
import { Copilot } from "../molecules/UseCasesStep3/Copilot";
import { EditName } from "../atoms/EditName/EditName";
import { StepIndicator } from "../atoms/Stepper/HorizantalStepper";
import { Button } from "../atoms/Button/Button";
import { RefreshLogo, ShareLogo, copyIcon } from "../../pages/assets";
import { UseNavigation } from "../../utlits/CustomHooks/Navigation";
import Tooltip from "../atoms/Tooltip/ToolTip";
import { DeletedFrame } from "../atoms/Frames/DeletedFrame";
import { useDispatch, useSelector } from "react-redux";
import { copilotProcess } from "../../API/copilotProcess";
import { setItem } from "../../utlits/localStorage";
import { PostChatQuestionAndAnswer } from "../../API/postChatQuestionAndAnswer";
import { ConfigSidePanel } from "../molecules/MainCopilot/ConfigurationSidePanel";
import { Instructions } from "../molecules/UseCasesStep3/Instructions";
import { DataSource } from "../molecules/UseCasesStep3/DataSource";

var callfunction = 0;

export const UseCasesStep3 = () => {
  const webbot_id: any = useSelector((state: any) => state?.webbot?.id);
  const likeResponse: any = useSelector(
    (state: any) => state?.data?.likesResponse
  );
  const dispatch = useDispatch();
  const skillId = useSelector((state: any) => state?.data?.skillId);
  const navigateTo = UseNavigation();
  const [preview, setPreview] = useState(0);
  const [questionAndAnswers, setQuestionAndAnswers] = useState([]);
  const [textInMessageBox, setTextInMessageBox] = useState("");
  const [postResponse, setPostResponse] = useState();
  const [loading, setLoading] = useState(false);
  const [temporaryText, setTemporaryText] = useState("");
  const [deletedSuccesfully, setDeletedSuccesfully] = useState(false);
  const [layout, setLayout] = useState(false); // to change the layout of the copilot component
  const [showTooltip, setShowTooltip] = useState(false);

  const [chatSuggestions, setChatSuggestions] = useState([]);

  const [conversationId,setConversationId] = useState(null)

  // to handle the layout state
  /* const handlelayout = () => {
    setLayout(!layout);
  }; */

  //------------------------Useeffect to handle the generating conversationid and fetching ques & ans----------------------------------------

  useEffect(() => {
    if (webbot_id&&conversationId===null) {
        copilotProcess.getChatSuggestions(webbot_id, setChatSuggestions);
        generateconversationIdAndFetchData()
    } 
  }, [webbot_id,conversationId]);

  //-------------------------TO GENERATE CONVERSATION ID---------------------------------------

  const generateconversationIdAndFetchData = () => {
    copilotProcess
      .createConversationId(webbot_id)
      .then((id) => {
        setItem("conversationId", id);
        setConversationId(id)
        copilotProcess.getQuestionAndAnswerBasedOnConversationId(id, setQuestionAndAnswers)
        setQuestionAndAnswers([]);
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  };

  //-------------------------TO POST QUESTIONS TO API ---------------------------------------

  const handlePostChat = () => {
    PostChatQuestionAndAnswer(
      webbot_id,
      textInMessageBox,
      setQuestionAndAnswers,
      setTemporaryText,
      setLoading,
      conversationId!,
      skillId,
      setTextInMessageBox,
      dispatch,
      setChatSuggestions
    );
  };

  // postChatQuestionAndAnswer(
  //   text,
  //   setTemporaryText,
  //   setLoading,
  //   dispatch,
  //   webbot_id,
  //   conversationId,
  //   skillId,
  //   setText,
  //   fetchData
  // );
  //-------------------------TO COPY THE CONVERSATION URL---------------------------------------

  const handleCopyUrl = () => {
    const url = `${window.location.origin}/CopilotExport/${webbot_id}`;
    navigator.clipboard.writeText(url); // Copy URL to clipboard
    setShowTooltip(true); // Show the tooltip
    setTimeout(() => setShowTooltip(false), 2000);
  };

  return (
    <div className="lg:w-[80%] m-auto lg:px-[1%] 2xl:w-[80%] mt-[10px]  ">
      {deletedSuccesfully ? (
        <div
          className={`${
            deletedSuccesfully ? "opacity-100" : "opacity-0"
          } transition-transform duration-1000 ease-in-out`}
        >
          <DeletedFrame type={"File"} />
        </div>
      ) : null}
      <div className="w-full">
        <StepIndicator />
        {/* {layout ? (
          <div className="flex items-center justify-between">
            <div className="flex justify-between w-[31%] p-2 border rounded-md bg-[var(--primarybackgroundWhite)] ">
              <div>
                <p className="text-[var(--primaryTextColor)] md:text-[90%] font-bold ">
                  Instructions
                </p>
              </div>
              <div>
                <Button
                  size="xsmall"
                  bgColor="primary"
                  className="py-[3%] rounded-[10px] px-[10px] text-[80%]"
                  onClick={() => navigateTo("CopilotInstructions")}
                >
                  Create Instruction
                </Button>
              </div>
            </div>
            <div className="flex justify-between w-[31%] p-2 border rounded-md bg-[var(--primarybackgroundWhite)]">
              <div>
                <p className="text-[var(--primaryTextColor)] md:text-[90%] font-bold ">
                  Knowledge
                </p>
              </div>
              <div>
                <Button
                  size="xsmall"
                  bgColor="primary"
                  className="py-[3%] rounded-[10px] px-[10px] text-[80%]"
                  onClick={() => console.debug("Button clicked")}
                >
                  Upload Data
                </Button>
              </div>
            </div>
            <div className="flex justify-center w-[31%] p-2 border rounded-md bg-[var(--primarybackgroundWhite)]">
              <div>
                <p className="text-[var(--primaryTextColor)] md:text-[90%] font-bold">
                  Integrate your Copilot
                </p>
              </div>
            </div>
          </div>
        ) : null} */}
      </div>
      <div className="md:flex justify-between w-full">
        <div
          className={`${
            layout ? "hidden" : "block"
          } md:max-w-[34%] max-h-[85vh] overflow-auto horizantal-scrollbar `}
        >
          <Instructions />
           {/* <IntegrationSidePanel preview={preview} setPreview={setPreview} /> */}
          {/* <ConfigSidePanel /> */}
          <DataSource setDeletedSuccesfully={setDeletedSuccesfully} />
          
        </div>
        <div className={` ${layout ? "md:w-[100%]" : "md:w-[64%]"}  h-[81vh]`}>
          <div className="md:w-[full] bg-[var(--primarybackgroundWhite)] rounded-[20px] mt-[20px]">
            {preview !== 0 ? (
           /*    <PreviewController preview={preview} setPreview={setPreview} /> */
            <></>
            ) : (
              <div className=" bg-[var(--primarybackgroundWhite)] md:px-[2%] rounded-[20px] w-full mt-[10px] p-[10px] ">
                <div className="flex justify-between items-center h-9">
                  <EditName />
                  <div className="flex w-[5vw] justify-between ">
                    {/* <Tooltip text="Change Format">
                      <img
                        src={copyIcon}
                        alt="copy"
                        width={"30px"}
                        height={"30px"}
                        className="hover:cursor-pointer"
                        onClick={handlelayout}
                      />
                    </Tooltip> */}

                    <Tooltip text={showTooltip ? "Copied!" : "Copy URL"}>
                      <img
                        src={ShareLogo}
                        alt="copy"
                        width={"30px"}
                        height={"30px"}
                        className="hover:cursor-pointer"
                        onClick={() => handleCopyUrl()}
                      />
                    </Tooltip>
                    <Tooltip text="Refresh Chat">
                      <img
                        src={RefreshLogo}
                        alt="copy"
                        width={"30px"}
                        height={"30px"}
                        className="hover:cursor-pointer"
                        onClick={() => generateconversationIdAndFetchData()}
                      />
                    </Tooltip>
                  </div>
                </div>
                <div className="md:w-[full]">
                  <Copilot
                    onSend={handlePostChat}
                    questionAndAnswers={questionAndAnswers}
                    chatSuggestions={chatSuggestions}
                    textInMessageBox={textInMessageBox}
                    setTextInMessageBox={setTextInMessageBox}
                    temporaryText={temporaryText}
                    setTemporaryText={setTemporaryText}
                    loading={loading}
                    setLoading={setLoading}
                    height="h-[735px]"
                  />
                </div>
              </div>
            )}
          </div>
          {/* <ResizableBox
            renderContent={() => (
              <div className="md:w-[full] bg-[var(--primarybackgroundWhite)] rounded-[20px] mt-[20px]">
                {preview ? (
                  <PreviewCopilot preview={preview} setPreview={setPreview} />
                ) : (
                  <div className=" bg-[var(--primarybackgroundWhite)] md:px-[2%] rounded-[20px] w-full mt-[20px] p-[10px] ">
                    <div className="flex justify-between items-center h-9">
                      <EditName />
                      <div className="flex w-[15%] justify-between">
                        <Tooltip text="Change Format">
                          <img
                            src={copyIcon}
                            alt="copy"
                            width={"30px"}
                            height={"30px"}
                            className="hover:cursor-pointer"
                            onClick={handlelayout}
                          />
                        </Tooltip>

                        <Tooltip text={showTooltip ? "Copied!" : "Copy URL"}>
                          <img
                            src={ShareLogo}
                            alt="copy"
                            width={"30px"}
                            height={"30px"}
                            className="hover:cursor-pointer"
                            onClick={() => handleCopyUrl(conversationId)}
                          />
                        </Tooltip>
                        <Tooltip text="Refresh Chat">
                          <img
                            src={RefreshLogo}
                            alt="copy"
                            width={"30px"}
                            height={"30px"}
                            className="hover:cursor-pointer"
                            // onClick={handlelayout}
                          />
                        </Tooltip>
                      </div>
                    </div>
                    <div className="md:w-[full]">
                      <Copilot
                        onSend={handlePostChat}
                        questionAndAnswers={questionAndAnswers}
                        preview={preview}
                        setPreview={setPreview}
                        text={text}
                        setText={setText}
                        temporaryText={temporaryText}
                        setTemporaryText={setTemporaryText}
                        loading={loading}
                        setLoading={setLoading}
                        height="h-[735px]"
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          /> */}
        </div>
      </div>
    </div>
  );
};
