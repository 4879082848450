// progressSlice.js
import { createSlice } from "@reduxjs/toolkit";

const progressSlice = createSlice({
  name: "progress",
  initialState: { value: 0 }, // Initial progress value
  reducers: {
    setProgress: (state, action) => {
      // Ensure progress doesn't go beyond 100
      state.value = Math.min(action.payload, 100);
    },
  },
});

export const { setProgress } = progressSlice.actions;

export default progressSlice.reducer;
