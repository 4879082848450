import React from "react";
import "../../../styles/ScrollBar.css";
import { Button } from "../../atoms/Button/Button";
import { UseNavigation } from "../../../utlits/CustomHooks/Navigation";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router";

interface UseCaseDescriptionProps {
  setClicked: React.Dispatch<React.SetStateAction<boolean>>;
  clicked: boolean;
}

export const UseCaseDescription = ({
  setClicked,
  clicked,
}: UseCaseDescriptionProps) => {
  const navigateTo = UseNavigation();
  // const webbot_id: any = useSelector((state: any) => state?.webbot?.id);

  return (
    <div className="flex flex-col items-center gap-5 bg-[var(--primaryInputbackgroundWhite)] p-[1%] rounded-[5%] lg:w-[58%] md:w-[50%] h-[76vh] mt-[1.5%]">
      {clicked ? (
        <>
          <div className="px-[5px]  h-[61vh] overflow-scroll overflow-y-auto overflow-x-auto custom-scrollbar flex justify-start">
            <div className="flex flex-col justify-start text-left">
              <p className="text-[15px] font-semibold text-[var(--secondaryTextColor)] text-left mt-[10px]">
                Purpose:
              </p>
              <p className="text-[15px] font-semibold text-[var(--secondaryTextColor)] text-left my-[5px]">
                You are a world class assistant to answer user queries based on
                data provided.
              </p>
              <p className="text-[15px] font-semibold text-[var(--secondaryTextColor)] text-left mt-[10px]">
                Language and Font:
              </p>
              <p className="text-[15px] font-semibold text-[var(--secondaryTextColor)] text-left my-[5px]">
                Your response should be in the same tone & language as user
                queries
              </p>
              <p className="text-[15px] font-semibold text-[var(--secondaryTextColor)] text-left mt-[10px]">
                Length and Format:
              </p>
              <p className="text-[15px] font-semibold text-[var(--secondaryTextColor)] text-left my-[5px]">
                You should respond in less than 100 words in markdown format.
                Use bullet points whenever necessary
              </p>
              <p className="text-[15px] font-semibold text-[var(--secondaryTextColor)] text-left mt-[10px]">
                Reduce Hallucinations:
              </p>
              <p className="text-[15px] font-semibold text-[var(--secondaryTextColor)] text-left my-[5px]">
                You should only respond based on the data provided. If you can't
                find the answer in the data, apologize and say I don't know.
                Think step by step
              </p>
            </div>
          </div>
          <div className=" flex w-full p-[5px] justify-end items-end">
            <Button
              size="xsmall"
              bgColor="primary"
              className="lg:py-[1.5%] rounded-[12px] px-[4%] lg:text-[15px] py-[5px] "
              onClick={() => navigateTo("UseCasesDataSource")}
            >
              Continue
            </Button>
          </div>
        </>
      ) : (
        <div className=" justify-center items-center m-auto">
          <p className="text-[#848e96] lg:text-[18px] text-[20px]">
            Please Select Any Template
          </p>
        </div>
      )}
    </div>
  );
};
