import React, { useState } from "react";
import { Input } from "../../components/atoms/Input/Input";
import { Button } from "../../components/atoms/Button/Button";
import { UseNavigation } from "../../utlits/CustomHooks/Navigation";
import { showPasswordLogo, hidePasswordLogo } from "../assets";
import { AuthNav } from "../../components/molecules/NavBar/AuthNav";
import Typography from "../../components/atoms/Typography/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CustomFailureFrame } from "../../components/atoms/Frames/CustomFailureFrame";
import { authentication } from "../../API/authentication";
import { setItem } from "../../utlits/localStorage";
import { moveNext } from "../../utlits/nextNavigate";
// import { userLoginWithEmail } from "../../API/userLoginWithEmail";

export const LoginPassword = () => {
  const email = sessionStorage.getItem("email");

  const location = useLocation();

  const routeName = location.pathname;

  const [message, setMessage] = useState(""); // message that we need to show in the alert message frame

  const navigateTo = UseNavigation();

  const navigate = useNavigate();

  const [password, setPassword] = React.useState("");

  const [showPassword, setShowPassword] = React.useState(false);

  // for changing the navigation otpion button on the navbar
  const ifRoute =
    routeName === "/login" ||
    routeName === "/forgot_password" ||
    routeName === "/login_password";

  const handlePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // const getWebbotInfo = (email: any, name: any, userInfo: any) => {
  //   const payload = {
  //     email: email,
  //     name: name,
  //     user_info: userInfo,
  //   };

  //   axios
  //     .post(`${NETWORK_URL}/users`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(payload),
  //     })
  //     .then((response) => {
  //       return response;
  //     })
  //     .catch((error) => {
  //       return error;
  //     });
  // };

  // navigation
  const handleNavigate = () => {
    navigate("/forgot_password");
  };

  // to login the user with email and password
  const handleLogin = () => {
    const payload = { email, password };
    authentication
      .login(payload)
      .then((res) => {
        if (res?.data?.data?.token) {
          setItem("token", res?.data?.data?.token);
          setItem("signedUser", "user");
          let next=moveNext()
          if(next){
            navigate(next)
          }else{
            if (res?.data?.data?.user?.maigrateInitialize) {
              navigateTo("dashboard");
            } else {
              navigateTo("intro");
            }
          }
          
        } else {
          setMessage(res?.data?.message);
          setTimeout(() => {
            setMessage("");
            navigateTo("security");
          }, 2000);
        }
      })
      .catch((err) => {
        setMessage(err?.response?.data?.message);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      });
  };

  return (
    <div>
      {message && <CustomFailureFrame message={message} />}
      <AuthNav />
      <div className="md:w-[fit-content] w-[90%] m-auto mt-[25vh]">
        <Typography className="text-[var(--primaryHeadingColor)] md:text-left text-center md:text-[30px] text-[28px]">
          One more step away!
        </Typography>
        <Typography className="tracking-[0.020em] text-[var(--primarySideHeadingColor)] md:text-[13px] text-[10px]">
          Maigrate - Infinite Co-Pilots, Limitless Possibilities!
        </Typography>
        <div className="mt-5">
          <p className="text-left text-[12px] text-[var(--primaryLabelColor)] font-bold">
            Unlock the Secret
          </p>
          <div className="flex border-[2px] border-[var(--primaryBorderColor)] rounded-md mt-1 justify-between">
            <Input
              type={showPassword ? "text" : "password"}
              placeholder="**********"
              className="outline-none border-none"
              onChange={handlePassword}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="text-gray-500 focus:outline-none bg-transparent"
            >
              {showPassword ? (
                <img src={hidePasswordLogo} alt="show" className="w-7 pr-1" />
              ) : (
                <img src={showPasswordLogo} alt="show" className="w-7 pr-1" />
              )}
            </button>
          </div>
        </div>
        <div
          className="text-[12px] text-right font-bold cursor-pointer pt-1"
          onClick={handleNavigate}
        >
          forgot password
        </div>
        <Button
          size="small"
          bgColor="primary"
          className="p-[5px] mt-2 w-[30%] m-auto"
          onClick={handleLogin}
        >
          Continue
        </Button>
      </div>
      <p className="md:hidden block md:text-[20px] lg:text-[16px] text-[15px] mt-[5vh] ">
        {ifRoute ? "Don't have an account ?" : "Do you have an account?"}{" "}
        {ifRoute ? (
          <span
            className="cursor-pointer font-bold "
            onClick={() => navigateTo("signup")}
          >
            Signup
          </span>
        ) : (
          <span
            className="cursor-pointer font-bold"
            onClick={() => navigateTo("login")}
          >
            Login
          </span>
        )}
      </p>
    </div>
  );
};
