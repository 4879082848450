import React, { useEffect, useMemo, useState } from "react";
import { SimpleMDEReactProps, SimpleMdeReact } from "react-simplemde-editor";
import SimpleMDE from 'easymde';
import "easymde/dist/easymde.min.css";
import {
  QuillToolbar,
  modules,
  formats,
} from "../../atoms/ToolBar/QuilToolbar";
import { Button } from "../../atoms/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  skillInstructions,
  toogleAddedSuccesfully,
  toogleUpdatedSuccesfully,
} from "../../../Redux/Reducers/SkillsData";
import { toggleCreateSkillName } from "../../../Redux/Reducers/SkillsData";
import { CopilotForInstructions } from "./CopilotForInstructions";
import { copilotProcess } from "../../../API/copilotProcess";
import { PostChatQuestionAndAnswer } from "../../../API/postChatQuestionAndAnswer";

export const UseCaseEditor = () => {
  const webbot_id: any = useSelector((state: any) => state?.webbot?.id);

  const dispatch = useDispatch();
  const skillName = useSelector((state: any) => state.data?.data?.skill_name);
  const [text, setText] = useState("");
  const [testCopilot, setTestCopilot] = useState(false);
  const [testUpdatedInstuction, setTestUpdatedInstuction] = useState(false);
  const store = useSelector((state: any) => state?.data);

  const newSkillName = useSelector((state: any) => state?.data?.skillName);

  const skillId = useSelector((state: any) => state?.data?.skillId);

  const data = useSelector((state: any) => state.data.data);

  const addedSuccesfully = useSelector(
    (state: any) => state?.data?.addedSuccesfully
  );

  const updatedSuccesfully = useSelector(
    (state: any) => state?.data?.updatedSuccesfully
  );

  const createSkillName = useSelector(
    (state: any) => state?.data?.createSkillName
  );

  const [value, setValue] = useState<any>("");


  useEffect(() => {
    dispatch(skillInstructions(value));
  }, [value])
  //--------------------------TO SET DEFAULT INSTRUCTIONS AND CREATE SKILLS--------------------------------------

  useEffect(() => {
    setValue(
      createSkillName
        ? ""
        : data?.skills?.length > 0
          ? data?.skills
          : undefined
    );
  }, [data, createSkillName]);

  //to Create New copilot Instruction

  const handleCreateCopilot = () => {
    if (newSkillName?.length === 0 && value?.length === 0)
      alert("Make sure to have Task Name and some Instructions");
    else if (newSkillName?.length === 0) alert("Please Enter Task Name");
    else if (value?.length === 0)
      alert("Provide atleast one Instruction");
    else {
      copilotProcess.createTask(webbot_id, newSkillName, value)
        .then((res: any) => {
          dispatch(toggleCreateSkillName());
          dispatch(toogleAddedSuccesfully());
          setTimeout(() => {
            dispatch(toogleAddedSuccesfully());
          }, 2100);
        })
        .catch((err: any) => {
          return err;
        });
    }
  };

  //to update existing copilto Instruction

  const handleUpdateCopilotInstructions = () => {
    //const context_json = { skill_name: store?.data.skill_name, skills: store?.skillInstructions};
    copilotProcess.UpdateTask(webbot_id, skillId, (store.skillName !== '' ? store.skillName : store?.data.skill_name), store?.skillInstructions)
      .then((response) => {
        dispatch(toogleUpdatedSuccesfully());
        setTimeout(() => {
          dispatch(toogleUpdatedSuccesfully());
          setTestUpdatedInstuction(prev => !prev);
        }, 2000);
      })
      .catch((error) => {
        console.error(error)
        return error;
      });
  };

  //-------------------------To create conversation for testing purpose----------------------------

  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(false);
  const [questionAndAnswers, setQuestionAndAnswers] = useState([]);
  const [temporaryText, setTemporaryText] = useState("");
  const [conversationId, setConversationId] = useState("");
  const [chatOptions, setChatOptions] = useState([]);

  useEffect(() => {
      getConversationId();
      copilotProcess.getChatSuggestions(webbot_id, setChatOptions);
  }, []);

  //get conversation ID

  const getConversationId = () => {
    copilotProcess
      .createConversationId(webbot_id)
      .then((id: any) => {
        setConversationId(id);
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  //get question and answers

  const fetchData = () => {
    copilotProcess
      .getQuestionAndAnswerBasedOnConversationId(conversationId)
      .then((response) => {
        setQuestionAndAnswers(response?.data?.questionanswers.reverse());
      })
      .catch((error) => {
        return error;
      });
  };

  //post chat

  const handlePostChat = () => {
    PostChatQuestionAndAnswer(
      webbot_id,
      text,
      setQuestionAndAnswers,
      setTemporaryText,
      setLoading,
      conversationId,
      skillId,
      setText,
      dispatch,
      setChatOptions
    );
  };

  const mdeOptions = useMemo(() => {
    // How to make this left aligned?
    return {
      placeholder: "How do you want the Copilot to respond to users?",
      toolbar: false,
      maxHeight:'300px',
      minHeight:'300px',
      
    } as SimpleMDE.Options;
  }, []);

  return (
    <>
      <div className="p-[2%]">
        <div
          className=" bg-[var(--other)] overflow-hidden relative rounded-[20px] h-[300px] "
          style={{
            overflow: "hidden",
            position: "relative",
            display: "block",
            width: "100%",
            margin: "0 auto",
            border: "none",
          }}
        >
          <SimpleMdeReact 
          value={value} 
          onChange={setValue} 
          options={mdeOptions}
          />;
          {/* <QuillToolbar /> */}

          {/* <ReactQuill
            theme="snow"
            value={value}
            onChange={setValue}
            
            modules={modules}
            formats={formats}
            style={{
              color: "var(--primaryTextColor)",
              overflow: "hidden",
              height: 300,
              fontSize: "24px",
              padding: "10px",
            }}
            className="custom-quill-editor"
          /> */}
        </div>
        <div className="flex items-center justify-center pt-[1%]">
          {createSkillName || skillName === undefined ? (
            <Button
              size="xsmall"
              bgColor="primary"
              className="py-[1%] rounded-[10px] px-[3%] text-[80%] mt-[10px]"
              onClick={handleCreateCopilot}
            >
              {addedSuccesfully ? "Creating..." : "Create"}
            </Button>
          ) : (
            <Button
              size="xsmall"
              bgColor="primary"
              className="py-[1%] rounded-[10px] px-[3%] text-[80%] mt-[10px]"
              onClick={handleUpdateCopilotInstructions}
            >
              {updatedSuccesfully ? "Updating..." : "Update"}
            </Button>
          )}
        </div>
        {/* {testCopilot || testUpdatedInstuction ? ( */}
        <div
          style={{
            opacity: 1,
            pointerEvents:
              "auto"
          }}
        >
          <br></br>
          <p>Try out the  selected task : {skillName} in the copilot below</p>
          
          <div
            className={`border-t-[1px] border-[var(--primaryTextColor)] mt-[3%] p-[1%] pb-[1%]`}
          >
            <div>
              <CopilotForInstructions
                onSend={handlePostChat}
                questionAndAnswers={questionAndAnswers}
                preview={preview}
                setPreview={setPreview}
                text={text}
                setText={setText}
                temporaryText={temporaryText}
                setTemporaryText={setTemporaryText}
                loading={loading}
                setLoading={setLoading}
                chatOptions={chatOptions}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
