import { Link,useParams } from "react-router-dom";
import ChatListItem from "../atoms/ChatItems/ChatListIItem";
import { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes,} from "@fortawesome/free-solid-svg-icons";
import { copilotProcess } from "../../API/copilotProcess";
import { Mail, chevronLeft, searchPurple } from "../../pages/assets";

interface Props {
    setId: (id: number | null) => void;
    id: number | null,
    closePanel: () => void,
    setSkillName: (name: string) => void,
}


const SkillList = ({ setId, id, closePanel, setSkillName }: Props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const user = useSelector((state: any) => state.loginReducer.userData);
    const { webbot_id } = useParams();
    useEffect(() => {
        setLoading(true)
        if (webbot_id) {
            copilotProcess.getInstructions(webbot_id)
                .then(res => {
                    setData(res.data)
                    setSkillName(JSON.parse(res.data?.[0]?.context).skill_name || '')
                    setId(res.data?.[0]?.id || 0)
                })
                .catch(err => {
                    setData([]);
                    console.log(err)
                })
                .finally(() => setLoading(false))
        }
        const setDevice = () => setIsOpen(prev => (prev || window.innerWidth < 600))
        window.addEventListener('resize', setDevice)
        return () => window.removeEventListener('resize', setDevice);
    }, [webbot_id])

    const toggle = () => setIsOpen(prev => !prev)
    return (<>
        <div style={{ flexFlow: 'column' }} className={`flex  lg:${isOpen ? 'max-w-[450px]' : 'max-w-[20px]'} ${isOpen ? 'lg:w-max' : 'lg:w-[50px]'}  border-r-[2px]   h-full ${isOpen ? 'min-w-[320px]' : 'min-w-[20px]'} box-border skill-slider`}>
            <div className={`${isOpen ? 'h-[50px]' : 'h-max pt-2'}  pl-3 pr-0  bg-[#F5EFFF] border-b-[#CBC4E7] border-b-[1px] ${isOpen ? 'flex' : 'block'}   items-center `} >
                <button onClick={closePanel} className="flex lg:hidden mr-4 font-bold">
                    <FontAwesomeIcon className="text-[#a16ef3] text-3xl" icon={faTimes} />
                </button>

                <p className="text-md font-bold flex justify-between w-full pr-4">
                    <span className={`${isOpen ? 'opacity-100 ' : 'opacity-0'} transition-all duration-1000  ease-in`}>
                        {isOpen ? `Choose your Task (${data.length})` : ''}
                    </span>
                    <button className="hidden lg:inline-block mb-3" onClick={toggle}><img className={`h-[20px] ${isOpen ? 'scale-x-[-1]' : ''} `} src={chevronLeft} /></button>
                </p>
               {/*  <button className={`${isOpen ? 'hidden' : 'block'}  mr-3 mt-5 mb-4 `}><img className={`h-[20px]`} src={searchPurple} /></button> */}

            </div>
            <div className={`flex-1 overflow-y-auto`}>
                <div className={`${isOpen ? 'opacity-100' : 'opacity-0'} skill-slider delay-100`}>
                    {(!loading && !data.length && user) && <p className="text-xs mt-2 mx-2 text-left">Sorry , There are no skills/task to select </p>}
                    {isOpen && data.map((data: any) => {
                        let context = { skill_name: '' }
                        try {
                            context = JSON.parse(data.context)
                        } catch {
                            console.error('Error in parsing context');
                         }

                        return (<ChatListItem
                            title={<p className="text-sm font-bold ">{context.skill_name}</p>}
                            /* subTitle={<p className="text-[#5308CF] ">Used by 3 users , Accuracy 79%</p>} */
                            id={data.id}
                            skillName={context.skill_name}
                            active={data.id === id}
                            showAction={false}
                            setID={setId}
                            setSkillName={setSkillName}
                            onClick={closePanel} />)
                    })}
                </div>
            </div>
            <div className={`py-2  w-full px-2 bottom-[5px] bg-[#F5EFFF]`}>
                <div className={`opacity-100 h-[38px]  text-left flex  flex-col justify-center`}>
                    <div className={`${isOpen ? 'w-full overflow-visible' : 'w-0 overflow-hidden'}`}>
                        <p className={`text-xs ${isOpen ? 'opacity-100 ' : 'opacity-0'} transition-all duration-1000 ease-in `}>
                            {isOpen && "Can't find what you're looking for ?"}
                        </p>
                        <Link to='mailto:info@maigrate.com' target="_blank" className={`text-xs underline text-[#0B0080] font-bold ${isOpen ? 'opacity-100 ' : 'opacity-0'} transition-all duration-1000 ease-in`}>
                            {isOpen && "Reach out to us"}
                        </Link>
                    </div>
                   {/*  <img className={`h-[20px] w-auto ${isOpen ? 'hidden' : 'inline-block'}`} src={Mail} /> */}
                </div>
            </div>
        </div>
    </>)
}

export default SkillList;