import React, { useEffect, useRef, useState } from "react";
import { MessageInput } from "../../atoms/MessageInput/MessageInput";
import ResponseComponent from "../../atoms/ResponseComponents/ResponseComponent";
import {
  GeneratingGif,
  sendIcon,
} from "../../../pages/assets";
import { useDispatch, useSelector } from "react-redux";
import { copilotProcess } from "../../../API/copilotProcess";
import RatingComponent from "../../atoms/RatingComponent/RatingComponent";

interface CopilotProps {
  preview: boolean;
  setPreview: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  text: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
  temporaryText: string;
  setTemporaryText: React.Dispatch<React.SetStateAction<string>>;
  questionAndAnswers: string[];
  onSend: () => void;
  chatSuggestions: string[];
  setChatSuggestions: React.Dispatch<React.SetStateAction<string[]>>;
  webbot_id: string;
}

export const CopilotForBot = ({
  preview,
  setPreview,
  questionAndAnswers,
  text,
  setText,
  onSend,
  loading,
  setLoading,
  temporaryText,
  setTemporaryText,
  chatSuggestions,
  setChatSuggestions,
  webbot_id,

}: CopilotProps) => {
  const dispatch = useDispatch();
  const divRef = useRef<HTMLDivElement>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [unlikePopup, setUnlikePopup] = useState(false);
  const [initialMessage, setInitialMessage] = useState("How can I assist you ?");

  useEffect(() => {
    // Scroll to the bottom of the div
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [questionAndAnswers]);

  useEffect(() => {
    copilotProcess.getChatSuggestions(webbot_id, setChatSuggestions);
    copilotProcess.getInitialMessage(webbot_id, setInitialMessage);
  }, []);

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedback("");
  };

  const closeUnlikePopup = () => {
    setUnlikePopup(false);
    setFeedback("");
  };

  const handleLikeFeedBack = () => {
    console.debug("Liked the Response", feedback);
    setFeedback("");
    setIsPopupOpen(false);
  };
  const handleDisLikeFeedBack = () => {
    console.debug("Not Liked the Response", feedback);
    setFeedback("");
    setUnlikePopup(false);
  };

  const [isLikePopupOpen, setIsLikePopupOpen] = useState<any>([]); // State to track the open/close status of LikeResponse popups
  const [isUnlikePopupOpen, setIsUnlikePopupOpen] = useState<any>([]); // State to track the open/close status of LikeResponse popups

  const openLikePopup = (index: any) => {
    const updatedState = [...isLikePopupOpen];
    updatedState[index] = true;
    setIsLikePopupOpen(updatedState);
  };

  // Function to close the LikeResponse popup at a specific index
  const closeLikePopup = (index: any) => {
    const updatedState = [...isLikePopupOpen];
    updatedState[index] = false;
    setIsLikePopupOpen(updatedState);
  };

  const openUnlikePopup = (index: any) => {
    const updatedState = [...isLikePopupOpen];
    updatedState[index] = true;
    setIsUnlikePopupOpen(updatedState);
  };

  // Function to close the LikeResponse popup at a specific index
  const closeUnlikedPopup = (index: any) => {
    const updatedState = [...isLikePopupOpen];
    updatedState[index] = false;
    setIsUnlikePopupOpen(updatedState);
  };

  return (
    <div className="">
      <div
        className={`w-[full] overflow-y-auto custom-scrollbar h-[58vh]`}
        ref={divRef}
      >
        <div>
          {questionAndAnswers?.length >= 0 ? (
            <div className="flex gap-[10px] my-[10px] pr-[8px] ">
              <img
                src={sendIcon}
                alt="copilot"
                className="w-[18px] h-[18px] "
              />
              <div className="py-[1.5%] px-[2%] bg-white rounded-[10px] w-full flex justify-between ">
                <p className="text-[#707070] text-[80%] text-left w-[91%]">
                  {initialMessage}
                </p>
              </div>
            </div>
          ) : null}
          {questionAndAnswers?.map((data: any, index: any) => {
            return (
              <div key={data.id}>
                {data.answer && (
                  <div className="flex gap-[10px] my-[10px] ">
                    <div className="w-[20px] h-[20px] border border-[var(--other)] bg-[var(--other)] rounded-[10px]"></div>
                    <div className="py-[1.5%] px-[2%] bg-[var(--other)] rounded-[10px] w-full mr-2 flex justify-between ">
                      <p className="text-[var(--primaryTextColor)] text-[80%] text-left ">
                        {data?.question}
                      </p>
                    </div>
                  </div>
                )}
                {data.question && (
                  <div className="flex gap-[10px] my-[10px]" key={data.id}>
                    <img
                      src={sendIcon}
                      alt="copilot"
                      className="w-[18px] h-[18px] mt-[2px]"
                    />
                    <div
                      className="flex justify-between py-[1.5%] px-[2%] bg-[white] rounded-[10px] w-full mr-2 "
                      key={data.id}
                    >
                      <div className="w-[90%] text-[#707070]" key={data.id}>
                        <ResponseComponent
                          response={data?.answer}
                          // response={
                          //   "I apologize for the inconvenience. Here are the images of the recommended dance party outfits:\n\n1. Ribbed Signature Dress - Xs / CandyRed\n   ![Ribbed Signature Dress - Xs / CandyRed](https://www.zykaz.com/cdn/shop/products/1666206236-jung12_1800x1800.jpg?v=1688984130)\n\n2. How You Doin Skinfit\n   ![How You Doin Skinfit](https://www.zykaz.com/cdn/shop/files/IMG_4289_1800x1800.jpg?v=1691227363)\n\nPlease let me know if you like any of these options or if you have any specific preferences."
                          // }
                        />
                      </div>
                      <RatingComponent data={data} />
                      {/* <div className="flex gap-1 justify-start items-start ">
                        <img
                          src={likeButton}
                          alt="like"
                          className="w-[15px] h-[15px] cursor-pointer"
                          onClick={() => handleResponseRating(data.id, "like")}
                          // onClick={() => openLikePopup(index)}
                        />
                        {isLikePopupOpen[index] && (
                          <>
                            <LikeResponse
                              onClose={() => closeLikePopup(index)}
                              setFeedBack={(newFeedback) =>
                                setFeedback(newFeedback)
                              }
                              feedback={feedback}
                              handleFunction={handleLikeFeedBack}
                              image={likeFrame}
                              placeholder="What do you like about the response?"
                            />
                            <div
                              className="fixed inset-0 bg-black opacity-50 z-10"
                              onClick={() => closeLikePopup(index)}
                            ></div>
                          </>
                        )}
                        <img
                          src={disLikeButton}
                          alt="like"
                          className="w-[15px] h-[15px] cursor-pointer"
                          onClick={() =>
                            handleResponseRating(data.id, "dislike")
                          }
                          // onClick={() => openUnlikePopup(index)}
                        />
                        {isUnlikePopupOpen[index] && (
                          <>
                            <LikeResponse
                              onClose={() => closeUnlikedPopup(index)}
                              setFeedBack={(newFeedback) =>
                                setFeedback(newFeedback)
                              }
                              feedback={feedback}
                              handleFunction={handleDisLikeFeedBack}
                              image={dislikeFrame}
                              placeholder="What was the issue with the response?How could it be improved?"
                            />
                            <div
                              className="fixed inset-0 bg-black opacity-50 z-10"
                              onClick={() => closeUnlikedPopup(index)}
                            ></div>
                          </>
                        )}
                      </div> */}
                      {/* <div className="flex gap-[10px] justify-start items-start ">
                        {data?.rating === 3 || data?.rating === 1 ? (
                          <img
                            src={likeButton}
                            alt="like"
                            className="w-[20px] h-[20px] cursor-pointer"
                            onClick={() =>
                              copilotProcess.HandleResponseRating(data.id, "like")
                            }
                            // onClick={() => openLikePopup(index)}
                          />
                        ) : null}
                        {data?.rating === 5 && (
                          <img
                            src={liked}
                            alt="like"
                            className="w-[20px] h-[20px] cursor-pointer"
                            onClick={() =>
                              copilotProcess.HandleResponseRating(data.id, "like")
                            }
                            // onClick={() => openLikePopup(index)}
                          />
                        )}

                        {isLikePopupOpen[index] && (
                          <>
                            <LikeResponse
                              onClose={() => closeLikePopup(index)}
                              setFeedBack={(newFeedback) =>
                                setFeedback(newFeedback)
                              }
                              feedback={feedback}
                              handleFunction={handleLikeFeedBack}
                              image={likeFrame}
                              placeholder="What do you like about the response?"
                            />
                            <div
                              className="fixed inset-0 bg-black opacity-50 z-10"
                              onClick={() => closeLikePopup(index)}
                            ></div>
                          </>
                        )}

                        {data?.rating === 3 || data?.rating === 5 ? (
                          <img
                            src={disLikeButton}
                            alt="like"
                            className="w-[20px] h-[20px] cursor-pointer"
                            onClick={() =>
                              copilotProcess.HandleResponseRating(data.id, "dislike")
                            }
                            // onClick={() => openUnlikePopup(index)}
                          />
                        ) : null}
                        {data?.rating === 1 && (
                          <img
                            src={disliked}
                            alt="like"
                            className="w-[20px] h-[20px] cursor-pointer"
                            onClick={() =>
                              copilotProcess.HandleResponseRating(data.id, "dislike")
                            }
                            // onClick={() => openUnlikePopup(index)}
                          />
                        )}

                        {isUnlikePopupOpen[index] && (
                          <>
                            <LikeResponse
                              onClose={() => closeUnlikedPopup(index)}
                              setFeedBack={(newFeedback) =>
                                setFeedback(newFeedback)
                              }
                              feedback={feedback}
                              handleFunction={handleDisLikeFeedBack}
                              image={dislikeFrame}
                              placeholder="What was the issue with the response?How could it be improved?"
                            />
                            <div
                              className="fixed inset-0 bg-black opacity-50 z-10"
                              onClick={() => closeUnlikedPopup(index)}
                            ></div>
                          </>
                        )}
                      </div> */}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          {loading ? (
            <div className="flex gap-[10px] my-[10px] w-full">
              <div className="w-[20px] h-[20px] border border-[var(--other)] bg-[var(--other)] rounded-full"></div>
              <div className="py-[1.5%] px-[2%] bg-[var(--other)] rounded-[10px] md:w-full">
                <p className="text-[var(--primaryTextColor)] text-[80%] text-left">
                  {temporaryText}
                </p>
              </div>
            </div>
          ) : null}
          {loading ? (
            <div className="flex gap-[10px] my-[10px]">
              <img src={sendIcon} alt="copilot" className="w-[20px] h-[20px]" />
              <div className="py-[1.5%] px-[2%] rounded-[10px] md:max-w-[70%] flex justify-start items-start">
                <img src={GeneratingGif} alt="Your GIF" className="w-[40px]" />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="w-full overflow-x-auto horizantal-scrollbar">
        <div className="flex gap-[10px]">
          {chatSuggestions?.map((data: string, index: number) => (
            <div
              key={index}
              className="bg-[var(--teritary)] rounded-[13px] px-[10px] py-[10px] cursor-pointer flex-shrink-0 min-w-[200px]"
              onClick={() => {
                setText(data);
                setTemporaryText(data);
              }}
            >
              <p className="text-[12px] text-[var(--primaryTextColor)]">
                {data}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div>
        <MessageInput
          textInMessageBox={text}
          setTextInMessageBox={setText}
          onSend={onSend}
        />
      </div>
    </div>
    // </div>
  );
};
