import React, { useState } from "react";
import { searchIcon } from "../../../pages/assets";
import { Accordion } from "../../atoms/Accordian/Accordian";
import { AccordionData, useCaseContent } from "../../../utlits/Data";
import "../../../styles/ScrollBar.css";
import { useSelector } from "react-redux";
import { on } from "events";

interface UseCaseOptionsProps {
  setClicked: React.Dispatch<React.SetStateAction<boolean>>;
  clicked: boolean;
  onDivClick?: () => void;
}

interface UserTypeData {
  video: string;
  url: string;
  website: string;
  s3Bucket: string;
  gitRepo: string;
}

export const UseCaseOptions = ({
  setClicked,
  clicked,
  onDivClick,
}: UseCaseOptionsProps) => {
  // const webbot_id: any = useSelector((state: any) => state?.webbot?.id);
  const fade = false;
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const [searchItem, setSearchItem] = useState("");

  //-----------------------For Search Functionality-----------------------
  
  const filteredData = searchItem
    ? AccordionData.filter((item) =>
        item.title.toLowerCase().includes(searchItem.toLowerCase())
      )
    : AccordionData;

  const handleAccordionClick = (index: any) => {
    setOpenAccordionIndex(index === openAccordionIndex ? null : index);
  };

  return (
    <div className="flex flex-col justify-center items-center gap-5 bg-[var(--primarybackgroundWhite)] md:p-[1.5%] p-[10px] rounded-[5%] lg:w-[40%] md:w-[48%] mt-[1.5%] ">
      <div className="flex md:p-[3.5%] p-3 items-center gap-13 w-[100%] bg-[var(--primaryInputbackgroundWhite)] rounded-[16px]">
        <img src={searchIcon} alt="search" className="w-[4%]" />
        <input
          placeholder="Search..."
          className="w-[100%] ml-[2%] bg-[var(--primaryInputbackgroundWhite)] text-[#4F4F4F] focus:outline-none focus:border-blue-500 placeholder-[#4F4F4F] size-[1%]"
          onChange={(e) => setSearchItem(e.target.value)}
        />
      </div>
      <div className="w-[100%] flex flex-col gap-3 h-[61vh] overflow-y-auto custom-scrollbar">
        <div className="flex flex-col gap-3 lg:mr-[1%] mr-[10px] ">
          <div
            className={`p-[1%] flex justify-start items-center w-[100%]  ${
              clicked
                ? "text-[#fff] bg-[var(--primary)]"
                : "text-[var(--primaryTextColor)] bg-[var(--primaryInputbackgroundWhite)]"
            }   rounded-[10px] cursor-pointer`}
            onClick={() => setClicked(!clicked)}
          >
            <p className={`p-[10px] text-[15px] font-extrabold`}>Build your own copilot</p>
          </div>
          <div
            style={{
              opacity: fade ? 1 : 0.5,
            }}
            className="flex flex-col gap-3 lg:mr-[1%] mr-[10px]"
          >
            {filteredData?.length === 0 ? (
              <p className="text-[#848e96] text-[20px]">No Data Found</p>
            ) : null}
            {filteredData.map((e: any) => {
              return (
                <div
                  className="p-[1%] flex justify-start items-center gap-13 w-[100%] bg-[var(--primaryInputbackgroundWhite)] rounded-[10px] cursor-pointer"
                  key={e.id}
                  onClick={onDivClick}

                >
                  {/* <Accordion
                  title={e.title}
                  content={useCaseContent}
                  id={e.id}
                  isOpen={e.id === openAccordionIndex}
                  onClick={() => handleAccordionClick(e.id)}
                  setClicked={setClicked}
                  files={files}
                  setFiles={setFiles}
                  type="type"
                  setDataByUser={setDataByUser}
                  dataByUser={dataByUser}
                  progress={progress}
                  setProgress={setProgress}
                /> */}
                  <div className="flex items-center">
                {/* Lock Icon */}
                <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#9b59b6"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="cursor-pointer"
>
    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
</svg>


                {/* Title */}
                <p
                    className={`p-[10px] text-[15px] text-[var(--primaryTextColor)] font-extrabold`}
                >
                    {e.title}
                </p>
                </div>
                </div>
              );
            })} 
             
          </div>
        </div>
      </div>
      
    </div>
    
  );
};
