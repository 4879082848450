export const migrateLogo = require("../Assets/maigrateLogo.png");
export const splashBackground = require("../Assets/migrate_bg.png");
export const infoLogo = require("../Assets/info.png");
export const rocketLogo = require("../Assets/rocket.png");
export const searchIcon = require("../Assets/Search.png");
export const searchPurple = require("../Assets/searchPurple.png");
export const financeLogo = require("../Assets/financeLogo.png");
export const uploadLogo = require("../Assets/upload.png");
export const chevronLeft = require("../Assets/chevron-left.png");
export const upload = require("../Assets/upload1.png");
export const deleteLogo = require("../Assets/delete.png");
export const whiteBgLogo = require("../Assets/whitebg.png");
export const copyIcon = require("../Assets/copyIcon.png");
export const sendIcon = require("../Assets/sendIcon.png");
export const googleLogo = require("../Assets/Google.png");
export const facebookLogo = require("../Assets/Facebook.png");
export const editIcon = require("../Assets/edit.png");
export const SendLogo = require("../Assets/sentLogo.png");
export const CopilotLogo = require("../Assets/copilotLogo.png");
export const WhiteCopilotLogo = require("../Assets/whiteRocket.png");
export const cross = require("../Assets/x.png");
export const CopyLogo = require("../Assets/copy.png");
export const boldLogo = require("../Assets/bold.png");
export const italicLogo = require("../Assets/italic.png");
export const attachment = require("../Assets/attachment.png");
export const alignRightLogo = require("../Assets/alignRight.png");
export const alignJustifyLogo = require("../Assets/alignJustify.png");
export const alignCenter = require("../Assets/alignCenter.png");
export const showPasswordLogo = require("../Assets/showPass.png");
export const hidePasswordLogo = require("../Assets/hidePass.png");
export const send = require("../Assets/send.png");
export const sendGreyTransparent = require("../Assets/sendGreyTransparent.png");
export const addTab = require("../Assets/addTab.png");
export const LoadingGif = require("../Assets/Loading.gif");
export const GeneratingGif = require("../Assets/generating.gif");
export const AddedLogo = require("../Assets/addedCheck.png");
export const DeletedLogo = require("../Assets/deletedCheck.png");
export const TrashLogo = require("../Assets/trash.png");
export const EditLogo = require("../Assets/editCopilot.png");
export const UpdatedLogo = require("../Assets/updatedCheck.png");
export const ShareLogo = require("../Assets/share.png");
export const RefreshLogo = require("../Assets/refresh.png");
export const disLikeButton = require("../Assets/thumbs-down.png");
export const likeButton = require("../Assets/thumbs-up.png");
export const likeFrame = require("../Assets/likeFrame.png");
export const dislikeFrame = require("../Assets/disLikeFrame.png");
export const closeIcon = require("../Assets/close.png");
export const liked = require("../Assets/liked.png");
export const disliked = require("../Assets/disliked.png");

export const profileLogo = require("../Assets/profile.png");
export const logoutLogo = require("../Assets/logout.png");
export const getHelpLogo = require("../Assets/get_help.png");
export const FAQLogo = require("../Assets/FAQ.png");
export const Mail = require("../Assets/Mail.png");
