import React, { useState } from "react";
import { storeSkillId } from "../../../Redux/Reducers/SkillsData";
import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";

interface AccordionBodyProps {
  content: string;
  id: number;
}

export function AccordionBodyContent({ content, id }: AccordionBodyProps) {
  // const webbot_id: any = useSelector((state: any) => state?.webbot?.id);
  const dispatch = useDispatch();
  const [showFullContent, setShowFullContent] = useState(false);
  const maxLength = 210;
  const truncatedContent = content?.slice(0, maxLength);
  const shouldTruncate = content?.length > maxLength;

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  return (
    <div>
      <div
        className="flex justify-start cursor-pointer"
        onClick={() => {
          dispatch(storeSkillId(id));
        }}
      >
        <p className="text-[90%] font-normal text-[var(--secondaryTextColor)] text-left mt-[15px] ">
          {showFullContent || !shouldTruncate
            ? `${content}...`
            : `${truncatedContent}...`}
        </p>
      </div>
      {shouldTruncate && (
        <p
          className="text-[var(--primary)] cursor-pointer"
          onClick={toggleContent}
        >
          {showFullContent ? "Read Less" : "Read More"}
        </p>
      )}
    </div>
  );
}
