import React, { useState } from 'react';
import Markdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const ResponseComponent = ({ response, chatHistory = false, isAns = false, name, time }: { response: string, chatHistory? :boolean,  isAns? : boolean, name?: string, time? :string }) => {
 

  const copyCodeToClipboard = (codeSnippet: string,setCopied:(value:boolean)=>void) => {
    setCopied(true)
    navigator.clipboard.writeText(codeSnippet);
    setTimeout(()=>setCopied(false),1000)
  };

  const RenderCodeBlock = ({ language, value, ...props }: { language?: string, value: string }) => {
    const [copied,setCopied]=useState(false);
    return(
    <div 
    className="code-container relative text-[#707070] font-mono bg-gray-100 border border-gray-300 p-1 rounded-[10px] overflow-auto"
    style={{ maxWidth: '100%', fontFamily: 'Courier New, monospace' }}
  >
     <button
      className={`copy-button absolute top-8 right-4 flex items-end justify-end self-end border border-blue-gray-200 py-[3px] px-[7px] rounded-[5px] text-[12px] ${copied&&'copyAnimation'}`}
      onClick={() => copyCodeToClipboard(value,setCopied)}
    >
     {copied ?'Copied':'Copy'} 
    </button>
    <div className="py-[5px] border-blue-gray-100 text-[80%]">
      <SyntaxHighlighter
        language={language || 'jsx'}
        className="rounded-md bg-gray-900 p-2 max-h-[350px] overflow-y-auto text-sm"
        //showLineNumbers={true}
        style={docco}
        {...props}
      >
        {value}
      </SyntaxHighlighter>
    </div>
  </div>
  )
}

  const renderLink = ({ href, children, ...props }: { href: string, children: React.ReactNode }) => {
    const isAbsoluteURL = href.startsWith('http://') || href.startsWith('https://');
    const correctedHref = isAbsoluteURL ? href : `https://${href}`;
    return (
      <a
        href={correctedHref}
        style={{ color: 'blue', textDecoration: 'underline' }}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      >
        {children}
      </a>
    );
  }
  
  const markDownContent = <Markdown 
  className="prose  text-sm text-left"
  remarkPlugins={[]}
  components={{
    code: ({ node, className, children, ...props }) => {
      const match = /language-(\w+)/.exec(className || '');
      return RenderCodeBlock({ language: match ? match[1] : undefined, value: String(children).replace(/\n$/, '') });

    },
    a: ({ href, node, ...props }) => {
      const isAbsoluteURL = href ? (href.startsWith('http://') || href.startsWith('https://')) : false;
      const correctedHref = isAbsoluteURL ? href : `https://${href}`;
      return (
        <a
          href={correctedHref || '#'}
          style={{ color: 'blue', textDecoration: 'underline' }}
          {...props}
          target="_blank"
          rel="noopener noreferrer" />
      );
    },
  }}
  >
    {response}
  </Markdown>;
  if (chatHistory) {
    return ( <div className={`w-full flex min-h-[70px] ${isAns ? 'bg-[#F4F4F4]' : "bg-white"} border-b-[1px] py-2 px-4`}>
    <div className="w-max h-full">
        <div className={`w-[30px] h-[30px] ${isAns?"bg-[#5707C8]":'bg-[#4DAEDE]'}  text-white rounded-[100%] flex justify-center items-center`}>{name}</div>
    </div>
    <div className="pl-4">
        <p className="text-sm text-left">
            {markDownContent}
        </p>
        <p className="text-xs text-left py-2">{time}</p>
    </div>
</div> )
  }
  else{
    return (
    <div className="text-[#707070] font-extrabold text-left text-[80%] my-[10px]">
      {markDownContent}
    </div>
  );
    }
};

export default ResponseComponent;
