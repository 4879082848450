import React from "react";
import { UseCasesStep1 } from "../../components/organisms/UseCasesStep1";
import Navbar from "../../components/molecules/NavBar/Navbar";
// import { useSelector } from "react-redux";

export const UseCases = () => {
  // const webbot_id: any = useSelector((state: any) => state?.webbot?.id);
  return (
    <div className="bg-[var(--background-color)] ">
      <Navbar />
      <UseCasesStep1 />
    </div>
  );
};
