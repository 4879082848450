import React, { useState } from "react";
import { WhiteCopilotLogo } from "../../../pages/assets";
import { ChatBotModal } from "./ChatBotModal";
import { useSelector } from "react-redux";

export const ChatBot = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const webbot_id: any = useSelector((state: any) => state?.webbot?.id);

  // console.log(webbot_id);

  const openModal = () => {
    setIsModalOpen(true);
    // console.log("clicked modal");
  };

  // const closeModal = () => {
  //   setIsModalOpen(!isModalOpen);
  //   // console.log("closeModal", isModalOpen);
  // };
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };
  return (
    <>
      <div
        onClick={openModal}
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
        className="p-[5px] h-[50px] bg-[var(--primary)] rounded-[50px] flex items-center gap-2 cursor-pointer fixed bottom-4 right-9 justify-center  "
        style={{
          width: hover ? 100 : 50,
          transition: "ease-out 0.9s",
        }}
      >
        <img src={WhiteCopilotLogo} alt="Logo" className={`w-[25px] h-[25px] ${hover ? "block" : "hidden"}  ` }/>

<h5
  className={`text-[#fff] ${hover ? "block" : "hidden"}`}
  style={{
    transition: "ease-out 0.5s",
  }}
>
  copilot
</h5>
<h5
  className={`text-[#fff] ${hover ? "hidden" : "block"}`}
  style={{
    transition: "ease-out 0.5s",
  }}
>
  AI
</h5>
      </div>
      {isModalOpen ? (
        <ChatBotModal isOpen={isModalOpen} onClose={setIsModalOpen} />
      ) : null}
    </>
  );
};
