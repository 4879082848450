import React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { AccordionBodyContent } from "./AccordionBody";
import { InstructionsAccordianData1 } from "../../../utlits/Data";
import { useDispatch } from "react-redux";
import { setCreateSkill, storeSkillId } from "../../../Redux/Reducers/SkillsData";
import { resetCreateSkill, toggleCreateSkillName } from "../../../Redux/Reducers/SkillsData";
import { EditLogo } from "../../../pages/assets";
import { set } from "lodash";
// import { useSelector } from "react-redux";

interface AccordionCustomIconProps {
  InstructionsAccordianData: Array<Object>;
  handleInstructionData?: (id: any) => void;
  setActiveIndex?: React.Dispatch<React.SetStateAction<number>>;
  setPreview?: React.Dispatch<React.SetStateAction<string>>;
}
function Icon({ id, open }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="var(--primaryTextColor)"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
}

export const AccordionCustomIcon = ({
  InstructionsAccordianData,
  handleInstructionData,
  setActiveIndex,
  setPreview,
}: AccordionCustomIconProps) => {
  const [open, setOpen] = React.useState(0);
  const dispatch = useDispatch();

  const handleOpen = (value: number) => {
    setOpen(open === value ? 0 : value);
  };
  // const webbot_id: any = useSelector((state: any) => state?.webbot?.id);

  return (
    <>
      {InstructionsAccordianData?.map((data: any) => {
        let context;
        try {
          context = JSON.parse(data?.context);
        } catch (error) {
          // console.error(`Error parsing JSON at index ${index}:`, error);
          return null; // Skip rendering this item
        }
        return (
          <div key={data.id} className="pr-[10px] flex">
            <Accordion
              open={open === data.id}
              icon={<Icon id={data.id} open={open} />}
            >
              <AccordionHeader
                onClick={() => {
                  handleOpen(data.id);
                  dispatch(storeSkillId(data.id));
                  handleInstructionData!(data.id);
                  setActiveIndex!(data.id);
                  dispatch(resetCreateSkill())
                }}
                className={` md:text-[100%] font-sm ${
                  open === data.id
                    ? "bg-[var(--primary)] text-[#ffff] rounded-[10px] px-[10px] hover:text-[#fff]"
                    : "text-[var(--primaryTextColor)] hover:text-[var(--primaryTextColor)]"
                }`}
              >
                {/* {data?.title} */}
                {context && context?.skill_name}
              </AccordionHeader>
              <AccordionBody className="border-t border-gray-300 pt-[5px]">
                <AccordionBodyContent
                  content={context && context?.skills}
                  // content={data.description}
                  id={data.id}
                />
              </AccordionBody>
            </Accordion>
            {setPreview && (
                        <img
                          src={EditLogo}
                          alt="delete"
                          className="w-[30px] h-[30px] ml-1 mt-2"
                          onClick={() =>  {setPreview("Task");
                          handleInstructionData!(data.id)
                          dispatch(storeSkillId(data.id));
                          setActiveIndex!(data.id);
                          dispatch(resetCreateSkill())}}
                        />
            )}
          </div>
        );
      })}
    </>
  );
};
