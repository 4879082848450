import React, { useEffect, useState } from "react";
import { UseCaseEditor } from "../../molecules/UseCasesStep3/UseCaseEditor";
import "../../../styles/Animation.css";
import { useDispatch, useSelector } from "react-redux";
import { newSkillName } from "../../../Redux/Reducers/SkillsData";
import { Button } from "../Button/Button";

interface ControllerProps {
  setPreview?: React.Dispatch<React.SetStateAction<string>>;
}

export const DynamicTabs =  ({ setPreview }: ControllerProps) => {
  // const webbot_id: any = useSelector((state: any) => state?.webbot?.id);

  const dispatch = useDispatch();

  const skillName = useSelector((state: any) => state.data?.data?.skill_name);

  const createSkillName = useSelector(
    (state: any) => state?.data?.createSkillName
  );

  const [isExpanded, setIsExpanded] = useState(false);

  const [activeTab, setActiveTab] = useState<any>();

  const [tabs, setTabs] = useState<any>();

  //--------------------------DISPATCH THE SKILL NAME TO REDUX--------------------------------------

  const handleChange = (e: any) => {
    const newText = e.target.value;

    // Dispatch an action to update Redux whenever the input changes
    dispatch(newSkillName(newText));
  };

  //--------------------------FOR ANIMATION OF CREATE NEW COPILOT--------------------------------------

  const onLeave = () => {
    setIsExpanded(false);
  };

  //---------------------------TO SET ACTIVE TAB AS 1st INDEX AND TO ITERATE THE TABS-------------------------------------

  useEffect(() => {
    setActiveTab({
      id: 1,
      tabTitle: skillName,
      content: <UseCaseEditor />,
    });

    setTabs([
      {
        id: 1,
        tabTitle: createSkillName ? (
          <input
            placeholder="Task Name"
            className="text-[#fff] bg-transparent focus:outline-none"
            autoFocus
            onChange={handleChange}
          />
        ) : 
          <input
          placeholder="Task Name"
          className="text-[#fff] bg-transparent focus:outline-none"
          autoFocus
          key={skillName}
          defaultValue={skillName}
          onChange={handleChange}
        />
        ,
        content: <UseCaseEditor />,
      },
    ]);
  }, [skillName, createSkillName]);

  return (
    <div className="flex justify-center items-center ">
      <div className="w-full rounded-[5px] ">
        <div className="flex item-center overflow-x-auto max-h-[43px] pr-5 ">
          
          {tabs?.map((value: any, index: any) => (
            <div key={index} className="flex items-center">
              <div
                onClick={() => {
                  setActiveTab(value);
                }}
                className={`${
                  activeTab?.id === value?.id
                    ? "bg-[var(--primary)]"
                    : "bg-[var(--teritary)]"
                } ${
                  index === 0 ? "rounded-tl-[20px]" : ""
                } flex items-center justify-center px-4 py-2 min-w-[90px]`}
              >
                <p
                  className={`${
                    activeTab?.id === value?.id
                      ? "text-[#fff]"
                      : "text-[var(--primaryTextColor)]"
                  }`}
                >
                  {value?.tabTitle}
                </p>
              </div>
              {tabs.at(-1)?.id === value?.id && (
                <div
                  className={` ${
                    isExpanded ? "w-[100%]" : null
                  }inline-block relative overflow-hidden transition-width rounded-[10px] ml-[5px] `}
                  onMouseEnter={() => setIsExpanded(true)}
                  onMouseLeave={() => setIsExpanded(false)}
                  onClick={() => {
                    setTabs([
                      ...tabs,
                      {
                        id: value?.id + 1,
                        tabTitle:skillName ,
                        content: <UseCaseEditor />,
                      },
                    ]);
                    onLeave();
                  }}
                >
                  <div
                    className={`flex items-center  ${
                      isExpanded ? "bg-[var(--other)]" : "bg-transparent"
                    } text-white cursor-pointer`}
                  >
                    {/*
                    <div
                      className="icon bg-[var(--other)] p-[9px] px-[15px] rounded-[10px]"
                      onClick={() => {
                        setTabs([
                          ...tabs,
                          {
                            id: value?.id + 1,
                            tabTitle:
                              skillName === undefined ? "Default" : skillName,
                            content: <UseCaseEditor />,
                          },
                        ]);
                        onLeave();
                      }}
                    >
                      {/* <img src={addTab} alt="add" className="w-40px h-[40px]" /> */
                    /*}
                      <FontAwesomeIcon
                        icon={faPlus}
                        color="var(--primaryTextColor)"
                      />
                    </div>
                       */}

                    {/* <div
                      className={`transition-opacity transition-transform duration-1000 ${
                        isExpanded
                          ? "opacity-100 transform translate-x-0"
                          : "opacity-0 transform -translate-x-5 translate-y-0 "
                      } pr-[10px] text-[var(--primaryTextColor)]`}
                    >
                      Create New Copilot
                    </div> */}
                  </div>
                </div>
              )}
            </div>
          ))}
          
          { setPreview && (
            <div className="w-full flex justify-end py-2 ">
            <Button
              size="xsmall"
              bgColor="primary"
              className="py-1 rounded-lg px-2.5 text-sm"
              onClick={() => setPreview("")}
              >
                X
              </Button>
          </div>
          
          
          )}
          
        </div>

        <div style={{ padding: 1 }}>{activeTab?.content}</div>
      </div>
    </div>
  );
};
