import React, { useEffect, useState } from "react";
import { DataSourceList } from "../molecules/UseCasesStep2/DataSourceList";
import { UploadedDataSource } from "../molecules/UseCasesStep2/UploadedDataSource";
import { StepIndicator } from "../atoms/Stepper/HorizantalStepper";
import { DeletedFrame } from "../atoms/Frames/DeletedFrame";
import { AddedFrame } from "../atoms/Frames/AddedFrame";
import { useDispatch, useSelector } from "react-redux";
import { copilotSetup } from "../../API/copilotSetup";
import { setWebbotId } from "../../Redux/Reducers/webbotSlice";

export const UseCasesStep2 = () => {
  const webbot_id: any = useSelector((state: any) => state?.webbot?.id);
  const userData: any = useSelector(
    (state: any) => state?.loginReducer?.userData
  );

  const [clicked, setClicked] = useState<boolean>(false);

  const [files, setFiles] = useState<File[]>([]);

  const [allFiles, setAllFiles] = useState<Object[]>([]);

  const [deleteRes, setDeleteRes] = useState<any>();

  const [deletedSuccesfully, setDeletedSuccesfully] = useState<boolean>(false);

  const [addedSuccesfully, setAddedSuccesfully] = useState<boolean>(false);

  const [postRes, setPostRes] = useState<boolean>(false);

  const [getRes, setGetRes] = useState<boolean>(true);

  const [progress, setProgress] = useState<number>(2);

  const [urlResponse, setUrlResponse] = useState<any>();

  const [isUploading,setUploading]=useState(false);

  const dispatch = useDispatch();
  const URLaddedSuccesfully = useSelector(
    (state: any) => state?.data?.addedSuccesfully
  );
  useEffect(() => {
    if (webbot_id) {
      getAllFiles();
    } else if (userData) {
      dispatch(setWebbotId(userData.maigrateuserInfo.webbots[0].id));
    }
  }, [webbot_id, deleteRes, postRes, urlResponse]);

  //--------------------------GET ALL FILES--------------------------------------

  const getAllFiles = () => {
    copilotSetup
      .getAllFiles(webbot_id, setAllFiles)
      .then((response) => {
        setGetRes(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //-----------------------------------------TO UPLOAD FILES-------------------------------------------------------

  async function uploadFiles() {
    let responses = [];
    setUploading(true);
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
  
      const uploadedFiles = await copilotSetup
        .uploadFilesToDb(file, webbot_id)
        .catch((error) => {
          console.error(error);
        });
      for (const uploadedFile of uploadedFiles) {
        const response = await ProcessFile(uploadedFile);
        responses.push(response);
      }
    }
    if(webbot_id){
      getAllFiles() //fetching files once all uploaded
    }
    setUploading(false);
    return responses;
  }

  // Process each uploaded file

  async function ProcessFile(file: any): Promise<any> {
    return await copilotSetup
      .processSingleFile(file)
      .then((response: any) => {
        setPostRes(prev=>!prev);
        setFiles([]);
        setProgress(0);
        setAddedSuccesfully(true);
        setTimeout(() => {
          setAddedSuccesfully(false);
        }, 2000);
      })
      .catch((error: any) => {
        console.error(error);
        return error;
      });
  }

  //--------------------------FILES UPLOADING TO API--------------------------------------
  return (
    <div className="lg:w-[80%] m-auto lg:px-[1%] 2xl:w-[80%] p-[10px] ">
      {deletedSuccesfully ? (
        <div
          className={`${
            deletedSuccesfully ? "opacity-100" : "opacity-0"
          } transition-transform duration-1000 ease-in-out `}
        >
          <DeletedFrame type={"File"} />
        </div>
      ) : null}
      {addedSuccesfully || URLaddedSuccesfully ? (
        <div
          className={`${
            addedSuccesfully ? "opacity-100" : "opacity-0"
          } transition-transform duration-1000 ease-in-out`}
        >
          <AddedFrame />
        </div>
      ) : null}
      {/* <div className="w-full ">
        <StepIndicator />
      </div> */}
      <div>
        <p className="text-[var(--primaryTextColor)] leading-trim-[both] text-cap font-poppins font-semibold md:text-3xl text-2xl ">
          Upload Knowledge
        </p>
      </div>
      <div className="md:flex justify-between ">
        <DataSourceList
          setClicked={setClicked}
          setFiles={setFiles}
          files={files}
          progress={progress}
          setProgress={setProgress}
          setUrlResponse={setUrlResponse}
        />
        <UploadedDataSource
          setClicked={setClicked}
          isUploading={isUploading}
          clicked={clicked}
          files={files}
          setFiles={setFiles}
          allFiles={allFiles}
          setDeleteRes={setDeleteRes}
          setDeletedSuccesfully={setDeletedSuccesfully}
          uploadFiles={uploadFiles}
          progress={progress}
          getRes={getRes}
          setUrlResponse={setUrlResponse}
        />
      </div>
    </div>
  );
};
