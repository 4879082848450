import { useLocation, useNavigate } from "react-router-dom";
import ChatListItem from "../../atoms/ChatItems/ChatListIItem";
import { useEffect, useState } from "react";
import { copilotProcess } from "../../../API/copilotProcess";
import { useSelector } from "react-redux";
import chatFilterImg from '../../../Assets/chat-filter.png';
import chatReportImg from '../../../Assets/chat-report.png';
import ChatFilterPopup from "../../atoms/ChatFilterPopup/ChatFilterPopup";
import ChatInfoPopup from "../../atoms/ChatInfoPopup/ChatInfoPopup";

interface Props{
    setConversationId: (id:number|null) => void;
    conversationId:number|null,
  }



const CopilotHistoryList = ({setConversationId,conversationId}:Props) => {
    const [data,setData]=useState([]);
    const [loading,setLoading]=useState(false);
    const [showFilter,setShowFilter]=useState(false);
    const [showInfo,setShowInfo]=useState(false);
    const user=useSelector((state:any)=>state.loginReducer.userData);
    const {state}=useLocation();
    const navigateTo=useNavigate();
    useEffect(()=>{
        if(state?.webbot_id){
            setLoading(true)
            copilotProcess.getConversationByWebBotID(state.webbot_id)
            .then(res=>{setData(res.data?.reverse())})
            .catch(err=>setData([]))
            .finally(()=>setLoading(false))
        }else{
            alert('no webbot id')
            navigateTo('/dashboard')
            
        }
    },[state?.webbot_id])

    const toggleFilter=()=>{
        setShowFilter(prev=>!prev)
    }

    const toggleInfo=()=>{
        setShowInfo(prev=>!prev)
    }


    return (
        <>
        {showFilter&&<ChatFilterPopup onClose={toggleFilter}/>}
        {showInfo&&<ChatInfoPopup onClose={toggleInfo}/>}
        <div className="max-w-[400px] w-max border-r-[2px]  h-[80vh] min-w-[320px]">
            <div className="h-[50px] pl-3 pr-0  bg-[#F5EFFF] border-b-[#CBC4E7] border-b-[1px] flex items-center justify-between" >
                <p className="text-lg font-bold text-left">Chats ({data.filter((obj:any)=>obj.questionanswers.length!==0).length})</p>
                <div className="flex">
                <button onClick={toggleFilter} className="btn underline px-1 py-2 mx-2 text-xs text-[#5308CF] flex items-center">Filter <img className="h-[15px] ml-1" src={chatFilterImg}/> </button>
                <button onClick={toggleInfo} className="btn underline px-1 py-2 mx-2 text-xs text-[#5308CF] flex items-center">Report <img className="h-[15px] ml-1" src={chatReportImg}/></button>
                </div>
            </div>
            <div className="h-[calc(100%_-_50px)] overflow-y-auto">
               {(!loading && !data.length && user)&& <p className="text-xs mt-2 mx-2 text-left">There is no chat in {user?.email} Copilot yet. </p>}
               {data.sort((a: any, b: any) => 
    new Date(b.created_date).getTime() - new Date(a.created_date).getTime())
  .filter((obj: any) => obj.questionanswers.length !== 0)
  .map((data: any) => 
    <ChatListItem 
      title={
        <>
          <span className="text-[14px] font-bold">
            {new Date(data.created_date).toLocaleDateString()} -
          </span>  
          <span className="text-[14px] overflow-hidden max-w-[180px] whitespace-nowrap overflow-ellipsis inline-block">
            {data.name === '' ? user?.email : data.name}
          </span>
        </>
      }
      subTitle={
        <>
          {data.questionanswers.length} Messages, {data.rating}/10 Satisfaction score
        </>
      }
      id={data.id} 
      showAction={true}
      active={data.id === conversationId} 
      setID={setConversationId}
    />
)}      
            </div>
        </div>
        </>

    );
};

export default CopilotHistoryList;