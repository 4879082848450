import React, { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { CopyLogo, WhiteCopilotLogo } from "../../../../pages/assets";
import { InstructionToOpenCopilot } from "../../../atoms/Instructions/InstructionsToOpenCopilot";
import { useSelector } from "react-redux";
import { InstructionForCopilotExport } from "../../../atoms/Instructions/InstructionsForCopilotExport";
import { InstructionsToEmbedBot } from "../../../atoms/Instructions/InstructionsToEmbedBot";

interface PreviewCopilotProps {
  setPreview: React.Dispatch<React.SetStateAction<string>>;
}

export const PreviewCopilot = ({
  setPreview,
}: PreviewCopilotProps) => {
  const webbot_id: any = useSelector((state: any) => state?.webbot?.id);
  const skillId: any = useSelector((state: any) => state?.data?.skillId);
  const [copied, setCopied] = useState(false);
  const [type,setType]=useState('export')
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  const onSelect=(e:any)=>{
    setType(e.target.value)
  }

  const isProduction = window.location.origin === "https://app.maigrate.com";
  const iframeCode = useMemo(() => `
  <iframe
    title="Maigrate Chatbot"
    src="${window.location.origin}/CustomChat/${webbot_id}/${skillId}"
    sandbox="allow-same-origin allow-scripts"
    style="height: 600px; border: none; width: 450px; position: fixed; bottom: 0; right: 0; z-index: 999999;"
  />
`, [webbot_id, skillId]);

const scriptCode = useMemo(() => `
  <script src="${window.location.origin}/initChatbot.js"></script>
  <script>
      initMaigrateChatbot({
          webbot_id: ${webbot_id},
          instruction_id: ${skillId},
          ${isProduction ? '' : `url: '${window.location.origin}'`}
      });
  </script>
`, [webbot_id, skillId, isProduction]);

const exportLinkCode = useMemo(() => `
  <a href='${window.location.origin}/CopilotExport/${webbot_id}/skip' target='_blank'>
    Open Copilot
  </a>
`, [webbot_id]);

  const instructionComponent = useMemo(() => {
    switch (type) {
      case 'export':
        return <InstructionForCopilotExport />;
      case 'bot':
        return <InstructionToOpenCopilot />;
      case 'botUsingScript':
        return <InstructionsToEmbedBot />; // Assuming you import this component
      default:
        return null;
    }
  }, [type]);

  const htmlCodeToBeCopied = useMemo(() => {
    switch (type) {
      case 'export':
        return exportLinkCode;
      case 'bot':
        return iframeCode;
      case 'botUsingScript':
        return scriptCode;
      default:
        return '';
    }
  }, [type, exportLinkCode, iframeCode, scriptCode]);

  //------------------------TO COPY CODE TO CLIPBOARD----------------------------------------

  const copyHtmlCode = () => {
    navigator.clipboard.writeText(htmlCodeToBeCopied);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <div className="w-full px-[20px] pt-[15px] items-center md:h-[83vh] overflow-auto custom-scrollbar">
      <div className="w-full flex justify-between pb-[15px] relative">
        <div
          className="flex gap-[15px] items-center cursor-pointer"
          onClick={() => setPreview("")}
        >
          <div className=" bg-[#ebebeb] p-[10px] rounded-[50px] flex items-center justify-center">
            <FontAwesomeIcon
              icon={faChevronLeft}
              size="xs"
              className="text-[#fff] w-[15px] h-[15px]"
            />
          </div>
          <p className="text-[var(--primaryTextColor)] text-[16px]">Back</p>
        </div>
        <div
          className="relative cursor-pointer"
          onClick={() => setPreview("")}
        >
          <div className="relative items-end justify-end">
            <div
              onMouseEnter={onHover}
              onMouseLeave={onLeave}
              className="p-[5px] h-[40px] bg-[var(--primary)] rounded-[50px] flex items-center cursor-pointer relative justify-center"
              style={{
                width: hover ? 100 : 40,
                transition: "ease-out 0.9s"
              }}
            >
              <img
                src={WhiteCopilotLogo}
                alt="Logo"
                className="w-[20px] h-[20px] "
              />

              <h5
                className={`text-[#fff] ${hover ? "block" : "hidden"}`}
                style={{
                  transition: "ease-out 0.5s",
                }}
              >
                copilot
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-[]">
        <div className="w-full flex items-center justify-between">
          <div className="flex flex-col items-start">
            <p
              className="text-[#6c6c6c] lg:text-[120%] text-[14px]"
              style={{ fontWeight: "700" }}
            >
              Instructions to Integrate Copilot to your website
            </p>
            <p className="text-[#6c6c6c] lg:text-[100%] my-[10px] text-[12px]">
              Copy and Paste the Following Code in any of your html pages
            </p>
          </div>
          {/* <div>
            <div
              className="bg-[#ebebeb] text-[#fff] py-[10px] px-[17px] rounded-[10px] flex justify-around items-center gap-[10px] cursor-pointer group transition hover:bg-[var(--primary)] hover:text-[#fff]"
              onClick={copyHtmlCode}
            >
              <img
                src={CopyLogo}
                alt="copy"
                className="w-[15px] h-[15px] hover:text-[#fff] "
              />
              <p className="text-[var(--primaryTextColor)] text-[12px] hover:text-[#fff]">
                {copied ? "copied" : "copy"}
              </p>
            </div>
          </div> */}
        </div>
        <div className="text-left py-2">
            <select className="mr-auto px-5 py-2 rounded-lg shadow-sm" value={type} onChange={onSelect}>
              <option value='export'>Copilot Export</option>
              <option value='botUsingScript'>Bot using Script</option>
              <option value='bot'>Bot - Deprecated</option>
            </select>
        </div>
        <div className="items-start justify-start text-left min-h-[780px] max-h-[750px] overflow-y-auto custom-scrollbar pb-[20px] mt-[20px]">
        <pre className="text-[var(--primaryTextColor)] rounded whitespace-pre-wrap text-[10px] md:text-[13px] lg:text-[14px] mt-[10px] border border-gray-800 flex justify-evenly py-[20px]">
            <code>
              <code>{htmlCodeToBeCopied}
              </code>
            </code>
            <div>
              <div
                className="bg-[#ebebeb] text-[#fff] py-[10px] px-[17px] rounded-[10px] flex justify-around items-center gap-[10px] cursor-pointer group transition hover:text-[#fff]  top-0 right-2"
                onClick={copyHtmlCode}
              >
                <img src={CopyLogo} alt="copy" className="w-[15px] h-[15px] " />
                <p className="text-[var(--primaryTextColor)] text-[12px]">
                  {copied ? "copied" : "copy"}
                </p>
              </div>
            </div>
          </pre>
          {instructionComponent}
          
        </div>
      </div>
    </div>
  );
};
