import React, { ChangeEvent, useState } from "react";
import { Button } from "../../components/atoms/Button/Button";
import { useDispatch } from "react-redux";
import { CustomFailureFrame } from "../../components/atoms/Frames/CustomFailureFrame";
import { AuthNav } from "../../components/molecules/NavBar/AuthNav";
import Typography from "../../components/atoms/Typography/Typography";
import { Input } from "../../components/atoms/Input/Input";
import {
  setForgotPasswordEmail,
  setlogin,
} from "../../Redux/Reducers/loginSlice";
import { useNavigate } from "react-router-dom";
import { authentication } from "../../API/authentication";

export const ForgottenPassword = () => {
  const [email, setEmail] = useState<string>("");

  const navigateTo = useNavigate();

  const [frame, setFrame] = useState<boolean>(false);

  const [validEmail, setValidEmail] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const sendOtp = () => {
    authentication
      .resendOtp(email)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  };
  const navigateToLoginPassword = () => {
    dispatch(setForgotPasswordEmail(email));
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;
    if (email !== "" && emailRegex.test(email)) {
      sendOtp();
      sessionStorage.setItem("email", email);
      navigateTo("/security");
    } else {
      setFrame(true);
      setValidEmail(false);
      setTimeout(() => {
        setFrame(false);
      }, 2000);
    }
  };
  return (
    <div>
      {frame && <CustomFailureFrame message={"Please Enter Valid Email"} />}
      <AuthNav />
      <div className="w-[fit-content] m-auto mt-[25vh] border border-transparent">
        <Typography
          variant="h3"
          className="text-[var(--primaryHeadingColor)] text-left"
        >
          Enter your Email
        </Typography>
        <Typography
          className="tracking-[0.020em] text-[var(--primarySideHeadingColor)]"
          variant="p"
        >
          Maigrate - Infinite Co-Pilots, Limitless Possibilities!
        </Typography>
        <div className="mt-5">
          <p className="text-left text-[12px] text-[var(--primaryLabelColor)] font-bold">
            What's your Email
          </p>
          <Input
            type="email"
            placeholder="example@gmail.com"
            onChange={handleOnChange}
          />
        </div>
        <Button
          size="small"
          bgColor="primary"
          className="p-[5px] mt-4 w-[30%] m-auto"
          onClick={navigateToLoginPassword}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
