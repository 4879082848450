import { useSelector } from "react-redux";
import Navbar from "../../components/molecules/NavBar/Navbar";
import CopilotHistoryWindow from "../../components/organisms/ChatHistoryWindow";
import { useLocation } from "react-router-dom";
export const CopilotHistory = () => {
    const userData=useSelector((state:any)=>state?.loginReducer?.userData)
    const {state}=useLocation();
    return (
        <>
        <Navbar />
        <div className="w-[98%] md:w-[80%] mx-auto px-[1%]">
           
            <div className=" w-full m-auto mt-[3vh]">
                <p  className="text-xl text-left py-2 font-semibold">Copilot History: {state?.name || userData?.email} Copilot </p>
            </div>
            <div className="w-max  md:w-full m-auto mt-[1vh] border-[2px] min-h-[500px]">
                <CopilotHistoryWindow />
            </div>
        </div>
        </>
    );
};
