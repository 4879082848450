import React, { useEffect, useRef, useState } from "react";
import { MessageInput } from "../../atoms/MessageInput/MessageInput";
import ResponseComponent from "../../atoms/ResponseComponents/ResponseComponent";
import { GeneratingGif, sendIcon } from "../../../pages/assets";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RatingComponent from "../../atoms/RatingComponent/RatingComponent";
import { set } from "lodash";
import { copilotProcess } from "../../../API/copilotProcess";

interface CopilotProps {

  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  textInMessageBox: string;
  setTextInMessageBox: React.Dispatch<React.SetStateAction<string>>;
  temporaryText: string;
  setTemporaryText: React.Dispatch<React.SetStateAction<string>>;
  questionAndAnswers: string[];
  chatSuggestions?: string[];
  onSend: () => void;
  height: string;
}

export const Copilot = ({
  questionAndAnswers,
  chatSuggestions,
  textInMessageBox,
  setTextInMessageBox,
  onSend,
  loading,
  setLoading,
  temporaryText,
  setTemporaryText,
  height,
}: CopilotProps) => {
  // const { id } = useParams();
  // const webbot_id = id;
  const webbot_id: any = useSelector((state: any) => state?.webbot?.id);
  const dispatch = useDispatch();
  const divRef = useRef<HTMLDivElement>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [unlikePopup, setUnlikePopup] = useState(false);
  const location = useLocation();
  const [initialMessage, setInitialMessage] = useState("How can I assist you ?");

  useEffect(() => {
    // Scroll to the bottom of the div
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [questionAndAnswers]);

  useEffect(() => {
     //copilotProcess.getChatSuggestions(webbot_id, setChatOptions);
     copilotProcess.getInitialMessage(webbot_id, setInitialMessage);
  }, []);
  

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedback("");
  };

  const closeUnlikePopup = () => {
    setUnlikePopup(false);
    setFeedback("");
  };

  const handleLikeFeedBack = () => {
    console.debug("Liked the Response", feedback);
    setFeedback("");
    setIsPopupOpen(false);
  };
  const handleDisLikeFeedBack = () => {
    console.debug("Not Liked the Response", feedback);
    setFeedback("");
    setUnlikePopup(false);
  };

  const [isLikePopupOpen, setIsLikePopupOpen] = useState<any>([]); // State to track the open/close status of LikeResponse popups
  const [isUnlikePopupOpen, setIsUnlikePopupOpen] = useState<any>([]); // State to track the open/close status of LikeResponse popups

  const openLikePopup = (index: any) => {
    const updatedState = [...isLikePopupOpen];
    updatedState[index] = true;
    setIsLikePopupOpen(updatedState);
  };

  // Function to close the LikeResponse popup at a specific index
  const closeLikePopup = (index: any) => {
    const updatedState = [...isLikePopupOpen];
    updatedState[index] = false;
    setIsLikePopupOpen(updatedState);
  };

  const openUnlikePopup = (index: any) => {
    const updatedState = [...isLikePopupOpen];
    updatedState[index] = true;
    setIsUnlikePopupOpen(updatedState);
  };

  // Function to close the LikeResponse popup at a specific index
  const closeUnlikedPopup = (index: any) => {
    const updatedState = [...isLikePopupOpen];
    updatedState[index] = false;
    setIsUnlikePopupOpen(updatedState);
  };

  return (
    // ${
    // location.pathname === "/UseCaseData" ? "h-[75vh]" : "h-[79vh]"} removed here

    <div
      className={`flex flex-col  md: w-full ${
        location.pathname === "/UseCaseData" ? "h-[74vh]" : "h-[79vh]"
      } `}
    >
      {/* hight added here  */}
      <div
        className={`flex-grow w-[full] overflow-y-auto custom-scrollbar `}
        ref={divRef}
      >
        <div>
          {questionAndAnswers?.length >= 0 ? (
            <div className="flex gap-[10px] my-[10px] pr-[8px] ">
              <img src={sendIcon} alt="copilot" className="w-[20px] h-[20px]" />
              <div className="py-[1.5%] px-[2%] bg-[#fff] rounded-[10px] w-full flex justify-between">
                <p className="text-[#707070] font-extrabold text-[80%] text-left w-[91%]">
                  {initialMessage}
                </p>
              </div>
            </div>
          ) : null}
          {questionAndAnswers?.map((data: any, index: any) => {
            // console.log(data?.rating);
            return (
              <div key={data.id}>
                {data.answer && (
                  <div className="flex gap-[10px] my-[10px]">
                    <div className="w-[20px] h-[20px] border border-[var(--other)] bg-[var(--other)] rounded-full"></div>
                    <div className="py-[1.5%] px-[2%] bg-[var(--other)] rounded-[10px] md:w-full mr-2">
                      <p className="text-[var(--primaryTextColor)] text-[80%] text-left">
                        {data?.question}
                      </p>
                    </div>
                  </div>
                )}
                {data.question && (
                  <div className="flex gap-[10px] my-[10px]" key={data.id}>
                    <img
                      src={sendIcon}
                      alt="copilot"
                      className="w-[20px] h-[20px] mt-[2px]"
                    />
                    <div
                      className="flex justify-between py-[1.5%] px-[2%] bg-[#fff] rounded-[10px] md:w-[95%] mr-2"
                      key={data.id}
                    >
                      <div className="w-[90%]" key={data.id}>
                        <ResponseComponent response={data?.answer} />
                      </div>
                      <RatingComponent data={data} />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          {loading ? (
            <div className="flex gap-[10px] my-[10px]">
              <div className="w-[20px] h-[20px] border border-[var(--other)] bg-[var(--other)] rounded-full"></div>
              <div className="py-[1.5%] px-[2%] bg-[var(--other)] rounded-[10px] md:w-full">
                <p className="text-[var(--primaryTextColor)] text-[80%] text-left">
                  {temporaryText}
                </p>
              </div>
            </div>
          ) : null}
          {loading ? (
            <div className="flex gap-[10px] my-[10px]">
              <img src={sendIcon} alt="copilot" className="w-[20px] h-[20px]" />
              <div className="py-[1.5%] px-[2%] rounded-[10px] md:max-w-[70%] flex justify-start items-start">
                <img src={GeneratingGif} alt="Your GIF" className="w-[40px]" />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="w-full overflow-x-auto horizantal-scrollbar pb-[12px]">
        <div className="flex gap-[10px]">
          {chatSuggestions?.map((data: string, index: number) => (
            <div
              key={index}
              className="bg-[var(--teritary)] rounded-[13px] px-[14px] py-[12px] cursor-pointer flex-shrink-0 min-w-[20vh]"
              onClick={() => {
                setTextInMessageBox(data);
                setTemporaryText(data);
              }}
            >
              <p className="text-[13px] text-[var(--primaryTextColor)]">
                {data}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-[10px]">
        <MessageInput
          textInMessageBox={textInMessageBox}
          setTextInMessageBox={setTextInMessageBox}
          onSend={onSend}
        />
      </div>
    </div>
  );
};
