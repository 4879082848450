import React, { useState } from "react";
import { Input } from "../../components/atoms/Input/Input";
import { Button } from "../../components/atoms/Button/Button";
// import { facebookLogo } from "../assets";
import { AuthNav } from "../../components/molecules/NavBar/AuthNav";
import Typography from "../../components/atoms/Typography/Typography";
import { GoogleLogin } from "@react-oauth/google";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CustomFailureFrame } from "../../components/atoms/Frames/CustomFailureFrame";
import { authentication } from "../../API/authentication";
import { setItem } from "../../utlits/localStorage";
import { setWebbotId } from "../../Redux/Reducers/webbotSlice";
import { setUserData } from "../../Redux/Reducers/loginSlice";
import { moveNext } from "../../utlits/nextNavigate";

export const Signup = () => {
  const location = useLocation();

  const routeName = location.pathname;

  const navigateTo = useNavigate();

  const dispatch = useDispatch();

  const [email, setEmail] = useState<string>("");

  const [credential, setCredential] = useState<any>("");

  const [error, setError] = useState<string>("");

  const ifRoute =
    routeName === "/login" ||
    routeName === "/forgot_password" ||
    routeName === "/login_password";

  const handleOnChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleGoogleSignUp = (res: any) => {
    setCredential(res?.credential);
  };

  const handleSignupWithEmail = () => {
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;
    if (email !== "" && emailRegex.test(email)) {
      const payload = { email };
      authentication
        .signUpwithEmail(payload)
        .then((res) => {
          if (res.data) {
            setError(res.data.message);
            setTimeout(() => {
              setError("");
              navigateTo("/security");
            }, 2000);
          }
          sessionStorage.setItem("email", email);
          // dispatch(setSignup(email)); // dispatch the email to the store for getting back to that email in the otp security screen/page to send that as payload
        })
        .catch((err: any) => {
          setError(err?.response?.data?.message);
          setTimeout(() => {
            setError("");
          }, 2000);
        });
    } else {
      setError("Please enter valid email");
      setTimeout(() => {
        setError("");
      }, 2000);
    }
  };

  React.useEffect(() => {
    if (credential) {
      authentication
        .loginWithGoogle(credential)
        .then((res: any) => {
          setItem("token", res?.data?.data?.token);
          setItem("signedUser", "user");
          dispatch(setUserData(res?.data?.data?.user))
          let next=moveNext()
          if(next){
            navigateTo(next)
          }else{
            if (res?.data?.data?.user?.maigrateInitialize) {
              navigateTo("/dashboard");
            } else {
              navigateTo("/intro");
            }
          }
          
        })
        .catch((err) => {
          return err;
        });
    }
  }, [credential]);

  return (
    <div>
      {error && <CustomFailureFrame message={error} />}
      <AuthNav />
      <div className="w-[fit-content] m-auto mt-[13vh] md:mt-[20vh] lg:mt-[13vh]">
        <Typography
          className="text-[var(----primaryHeadingColor)] text-[24px] text-left"
          variant="h3"
        >
          Create your account!
        </Typography>
        <Typography
          variant="p"
          className="text-[var(--primarySideHeadingColor)]  tracking-[0.020em] text-left md:text-[13px] text-[10px]"
        >
          Maigrate - Infinite Co-Pilots, Limitless Possibilities!
        </Typography>
        <div className="mt-5">
          <p className="text-left text-[12px] text-[var(--primaryLabelColor)] font-semibold">
            What's your Email
          </p>
          <Input
            type="email"
            placeholder="example@gmail.com"
            onChange={handleOnChange}
          />
        </div>
        <Button
          size="small"
          bgColor="primary"
          className="p-[5px] mt-4 w-[30%] m-auto"
          onClick={handleSignupWithEmail}
        >
          Continue
        </Button>
        <div className="flex w-full items-center justify-between m-auto mt-5">
          <div className="border-b w-[100px] border-[var(--primaryBorderColor)]"></div>
          <p className="text-xs text-[var(--primaryBorderColor)] font-bold">
            or
          </p>
          <div className="border-b-[0.5px] border-[var(--primaryBorderColor)] w-[100px]"></div>
        </div>
        <div className="w-[fit-content] m-auto mt-2">
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              handleGoogleSignUp(credentialResponse);
            }}
            onError={() => {
              return;
            }}
            useOneTap={false}
          ></GoogleLogin>

          {/* customizable google button */}
          {/* <Button
            className="mt-2 w-full p-2 border border-[var(--primary)]"
            size="xsmall"
          >
            <div className="flex items-center w-[70%] gap-2">
              <img src={facebookLogo} alt="" className="w-4" />
              <div className="text-[#020202BF] font-bold">
                Continue with Facebook
              </div>
            </div>
          </Button> */}
        </div>
      </div>
      <p className="md:hidden block md:text-[20px] lg:text-[16px] text-[15px] mt-[5vh]">
        {ifRoute ? "Don't have an account ?" : "Do you have an account?"}{" "}
        {ifRoute ? (
          <span
            className="cursor-pointer font-bold "
            onClick={() => navigateTo("signup")}
          >
            Signup
          </span>
        ) : (
          <span
            className="cursor-pointer font-bold"
            onClick={() => navigateTo("login")}
          >
            Login
          </span>
        )}
      </p>
    </div>
  );
};
