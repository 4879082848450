// localStorageMiddleware.js

const localStorageMiddleware = (store: any) => (next: any) => (action: any) => {
  const result = next(action);

  if (action.type === "webbot/setWebbotId") {
    const { id } = store.getState().webbot;

    // const encryptedData = encryptFunction(id);

    // localStorage.setItem("webBot_id", id);
  }

  return result;
};

export default localStorageMiddleware;
