// import React, { useState } from "react";
import { AuthNav } from "../../components/molecules/NavBar/AuthNav";
import Typography from "../../components/atoms/Typography/Typography";
import { introData } from "../../utlits/Data";
import { Paragraph } from "./Paragraph";
import { Button } from "../../components/atoms/Button/Button";
import { UseNavigation } from "../../utlits/CustomHooks/Navigation";
import Navbar from "../../components/molecules/NavBar/Navbar";

export const Introduction = () => {
  const navigateTo = UseNavigation();

  return (
    <div>
      {/* <AuthNav /> */}
      <Navbar />
      <div className="mt-[9vh] ">
        <Typography variant="h3" className="text-[var(--primary)] font-black">
          Create your copilot in 3 steps
        </Typography>
        <div className="w-[37%] h-[fit-content] m-auto mt-2 flex flex-col gap-3">
          {introData?.map((ele) => (
            <div
              className="border-2 border-[var(--primary)] rounded-lg p-2"
              key={ele.id}
            >
              <div className="flex ">
                <Typography variant="h5" className="mr-[10px] w-[20px]">
                  {ele.id}
                </Typography>
                <Typography
                  className="text-[var(--secondaryTextColor)]"
                  variant="h5"
                >
                  {ele.title}
                </Typography>
              </div>
              <div className="ml-[30px]">
                {/* <Typography className={`text-[10px] text-left h-[46px]  `}>
                {ele.description}
              </Typography> */}
                <Paragraph content={ele.description} />
                {/* <div
                className="text-[10px] text-left font-[900] text-[var(--primary)]"
                style={{ fontFamily: "PoppinsBold" }}
                onClick={() => handleOverflow(ele.id)}
              >
                Read More...
              </div> */}
              </div>
            </div>
          ))}
        </div>
        <Button
          onClick={() => navigateTo("UseCases")}
          bgColor="primary"
          className="p-1 w-[70px] m-auto mt-3"
          size="small"
        >
          Next
        </Button>
      </div>
    </div>
  );
};
