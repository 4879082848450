// import React from "react";
import { editIcon } from "../../../pages/assets";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { copilotName } from "../../../Redux/Reducers/SkillsData";
import { authentication } from "../../../API/authentication";
import { copilotSetup } from "../../../API/copilotSetup";
import Tooltip from "../../atoms/Tooltip/ToolTip";
// import { Button } from "../Button/Button";
// import { useParams } from "react-router-dom";

interface EditName {
  canEdit?: boolean;
}

export const EditName = ({canEdit = true}: EditName) => {
  // const { id } = useParams();
  // const webbot_id = id;
  // const name: any = useSelector((state: any) => state?.data?.copilotName);
  // const [email, setEmail] = useState("");
  // const [title, setTitle] = useState("");
  // const [editedTitle, setEditedTitle] = useState("");

  const dispatch = useDispatch();
  const webbot_id: any = useSelector((state: any) => state?.webbot?.id);
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [putRes, setPutRes] = useState<any>();

  useEffect(() => {
    getCopilotName();
  }, [putRes]);

  const getCopilotName = () => {
    copilotSetup
      .getWebbotDetails(webbot_id)
      .then((response) => {
        if (response?.data?.name?.length === 0) {
          authentication.getUser().then((response) => {
            setNewTitle(response?.email);
            dispatch(copilotName(response?.email));
          });
        } else {
          setNewTitle(response?.data?.name);
          dispatch(copilotName(response?.data?.name));
        }
      })
      .catch((error) => {
        // console.log(error);
        return error;
      });
  };

  const editCopilotName = () => {
    const payload = {
      name: newTitle,
    };
    copilotSetup
      .updateWebbot(webbot_id, payload)
      .then((response) => {
        setPutRes(response);
      })
      .catch((error) => {
        return error;
      });
  };

  const handleEditClick = () => {
    // if (isEditing) {
    //   setTitle(newTitle);
    // }
    setIsEditing(!isEditing);
  };

  const handleChange = (event: any) => {
    setNewTitle(event.target.value);
  };

  return (
    <div className="flex justify-start gap-[12px] items-center bg-[var(--primarybackgroundWhite)]  rounded-[14px] h-full">
      <h1 className="text-[var(--primaryTextColor)] ">
        {isEditing ? (
          <div>
            <input
              type="text"
              value={newTitle}
              maxLength={30}
              onChange={handleChange}
              placeholder="Edit Copilot"
              className="bg-transparent text-[var(--primaryTextColor)] focus:outline-none placeholder-[var(--primaryTextColor)] text-[14px] w-[90%] border border-[var(--primary)] rounded-md p-1"
            />
          </div>
        ) : (
          <p className="text-[14px]">{`${newTitle}'s Copilot`}</p>
        )}
      </h1>
      { canEdit ? (
      <button onClick={handleEditClick} className="py-2 text-white rounded">
        {isEditing ? (
          <div
            className="flex w-full justify-end items-end"
            onClick={editCopilotName}
          >
            <FontAwesomeIcon
              icon={faCheck}
              style={{ color: "var(--primary)" }}
            />
          </div>
        ) : (
          <Tooltip text="Edit your Copilot Name">
          <img src={editIcon} alt="Edit" className="w-[17px] h-[17px]" />
          </Tooltip>
        )}
      </button>):null}
    </div>
  );
};
