import React, { useState, useEffect } from "react";
import { Button } from "../../components/atoms/Button/Button";
import { AuthNav } from "../../components/molecules/NavBar/AuthNav";
import OTPInput from "../../components/molecules/OtpInput/OtpInput";
import { UseNavigation } from "../../utlits/CustomHooks/Navigation";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CustomFailureFrame } from "../../components/atoms/Frames/CustomFailureFrame";
import { setWebbotId } from "../../Redux/Reducers/webbotSlice";
import { authentication } from "../../API/authentication";
import { setItem } from "../../utlits/localStorage";
export const SecurityCheck = () => {
  const navigateTo = UseNavigation();

  const dispatch = useDispatch();
  const location = useLocation();

  const forgotEmail = location?.state; // get the email from the navigation

  const email = sessionStorage.getItem("email") || "";

  // get te email from the store which is stored in the store while we are restting the password
  const forgotPasswordEmail = useSelector(
    (store: any) => store?.loginReducer.forgotPasswordEmail
  );

  const [otpStatus, setOtpStatus] = useState(""); //for seting the status of otp is correct or not for rendering the colors

  const [timeLeft, setTimeLeft] = useState(60);

  const [otpVal, setOtpVal] = useState("");

  const [message, setMessage] = useState("");

  // otp verification
  const otpVerification = () => {
    // const email = forgotEmail ? forgotPasswordEmail : signupEmail; // checking the user entering the otp while signing up or forgot password
    const payload = { email, otp: otpVal };
    authentication
      .verifyOtp(payload)
      .then((response: any) => {
        setItem("token", response?.data?.data?.token);
        dispatch(
          setWebbotId(
            response?.data?.data?.user?.maigrateuserInfo?.webbots[0].id
          )
        );
        if (response?.data?.status) {
          setOtpStatus("right");
        }
        setMessage("OTP Verified");
        setTimeout(() => {
          setMessage("");
        }, 2000);
      })
      .catch((error: any) => {
        setOtpStatus("wrong");
        setMessage(error?.response?.data?.message);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      });
  };

  // resend otp
  const resendOtp = () => {
    // const email = forgotEmail ? forgotPasswordEmail : signupEmail; // checking the user entering the otp while signing up or forgot password
    authentication
      .resendOtp(email)
      .then((response: any) => {
        setMessage(response?.data?.message);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      })
      .catch((error: any) => {
        setMessage(error?.response?.data?.message);
      });
  };

  // setting styles regarding the input status
  const inputColorClass =
    otpStatus === "wrong"
      ? "bg-[#FF00001F] text-[#FF0000] border-[#FF0000] outline-none"
      : otpStatus === "right"
      ? "bg-[#03FF4A1F] text-[#00FF38] border-[#00FF38] outline-none"
      : "bg-[#FFFFFF1F] border-[var(--primaryBorderColor)]";

  const handleTimerComplete = () => {
    // Handle timer completion logic here
  };

  // to set the timer to again 60seconds to reset the timer
  const handleResend = () => {
    resendOtp();
    setTimeLeft(60);
  };

  // to format the time
  const formatTime = (time: any) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Effect is for timer
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      handleTimerComplete();
    }
  }, [timeLeft]);

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ //
  // requirements for otp

  const handleOtpChange = (val: any) => {
    setOtpVal(val);
  };

  const renderInput = (inputProps: any, index: number) => {
    return <input {...inputProps} />;
  };

  // to navigate confirm password
  const handleNavigate = () => {
    if (otpStatus === "right") {
      navigateTo("create_password");
    } else if (otpVal.length < 6) {
      setMessage("Enter Valid OTP");
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  };

  useEffect(() => {
    if (otpVal.length > 5) {
      otpVerification();
    }
  }, [otpVal]);

  return (
    <div>
      {message && <CustomFailureFrame message={message} />}
      <AuthNav />
      <div className="w-[75%] lg:w-[20%] md:w-[47%] text-left m-auto mt-[20vh] ">
        <div className="text-[var(--primaryHeadingColor)] text-[28px] lg:text-[30px] md:text-[32px] font-bold">
          {otpStatus === "wrong" ? (
            <p>
              Uh-oh!!{" "}
              <span className="text-[22px] lg:text-[18px] md:text-[28px]">
                {String.fromCodePoint(0x1f636)}
              </span>
            </p>
          ) : (
            <p>Security Check!</p>
          )}
        </div>
        <div className="w-[97%] lg:w-[89%] md:w-[95%] text-[13px] lg:text-[11px] md:text-[15px] text-[var(--primarySideHeadingColor)] mb-5 font-semibold">
          {otpStatus === "wrong" ? (
            <p className="text-[#FF0000]">
              The security code missed the mark. Let's give it another shot to
              keep things secure
            </p>
          ) : (
            <p>
              Verification code sent! Check{" "}
              <span className="text-[var(--primary)]">{email}</span> and gear up
              for the mission!"
            </p>
          )}
        </div>
        <div className="text-[15px] md:text-[17px] lg:text-[12px] font-bold  flex justify-between">
          <p className="font-bold">Enter Code</p>{" "}
          <p className="font-bold">{formatTime(timeLeft)}</p>
        </div>
        <OTPInput
          value={otpVal}
          numInputs={6}
          onChange={handleOtpChange}
          renderInput={renderInput}
          containerStyle={{ width: "100%", gap: "8px" }}
          inputStyle={` ${inputColorClass} w-[40px] md:w-[53px] lg:w-[44px] h-[40px] md:h-[50px] lg:h-[44px] text-center border rounded appearance-none`}
        />
        <div className="flex justify-end mt-[2px]">
          <Button
            className="bg-transparent border-b rounded-none font-bold text-[13px] md:text-[17px] lg:text-[12px]"
            size="small"
            color="default"
            onClick={handleResend}
            disabled={timeLeft > 0}
          >
            Resend code
          </Button>
        </div>
        <Button
          size="small"
          bgColor="primary"
          className=" mt-4 mx-auto px-4 py-1.5"
          onClick={handleNavigate}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
