import React, { useEffect, useState } from "react";
import { UseCasesStep2 } from "../../components/organisms/UseCasesStep2";
import { useLocation } from "react-router-dom";
import Navbar from "../../components/molecules/NavBar/Navbar";
import { Loading } from "../../components/atoms/Loader/Loading";
import { MainCopilot } from "../../components/organisms/MainCopilot";
import { StepIndicator } from "../../components/atoms/Stepper/HorizantalStepper";
import { ChatBot } from "../../components/molecules/ChatBot/ChatBot";

export const MainCopilotPage = () => {
  const location = useLocation();
  // const webbot_id: any = useSelector((state: any) => state?.webbot?.id);
  const [loading, setLoading] = useState(true);

  //---------------------------FOR LOADING GIF-------------------------------------
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [location.pathname]);

  return (
    <div className=" bg-[var(--background-color)] ">
      {loading ? (
        <Loading />
      ) : (
        <>
          <Navbar />
          <div className="lg:w-[80%] m-auto lg:px-[1%] 2xl:w-[80%] mt-[10px]  ">
          <div>
        <p className="text-[var(--primaryTextColor)] leading-trim-[both] text-cap font-poppins font-semibold md:text-3xl text-2xl ">
        </p>
      </div>
          <MainCopilot />
          <ChatBot />
          </div>
        </>
        
      )}
    </div>
  );
};
