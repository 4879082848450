import React, { useState } from "react";
import { AddedLogo } from "../../../pages/assets";
import { motion } from "framer-motion";
// import { useSelector } from "react-redux";

export const AddedFrame = () => {
  const [x, setX] = useState(-100);
  // const addedSuccesfully = useSelector(
  //   (state: any) => state?.data?.addedSuccesfully
  // );

  setTimeout(() => {
    setX(200);
  }, 1900);

  return (
    <motion.div
      className="w-[25%] py-[15px] rounded-[20px] flex gap-[10px] items-center shadow-md bg-[#fff] px-[20px] right-0 absolute top-10 box"
      animate={{ x }}
      transition={{ type: "spring" }}
    >
      <img src={AddedLogo} alt="Added" className="w-[30px] h-[30px]" />{" "}
      Succesfully Created
    </motion.div>
  );
};
