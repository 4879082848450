import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

// interface Data{
//     id: number,
//     name:string,
//     rating: number,
//     webbot_id:number,
//     labels: object,
//     created_date: string,
//     status: string,
//     source: string,
//     extra_info: object,
//     active: boolean,
//     public: boolean,
//     questionanswers: [any],
// }

interface Props{
  setID: (id:number) => void;
  id:number,
  active?:boolean,
  title:any,
  subTitle?:any,
  showAction?:boolean,
  setSkillName?: (name:string) => void;
  skillName?:string,
  onClick?:()=>void
}
const ChatListItem = ({setID,id,active=false,title,subTitle,showAction=false, onClick, setSkillName, skillName}:Props) => {
  
  const handleClick=()=>{
    setID(id)
    if(setSkillName)setSkillName(skillName||title)
    onClick?.()
  } 
  return (
        <button onClick={handleClick} className={`btn  w-full  min-h-[60px] ${active?'bg-[#ebe2f9]':'bg-[#F9F9FF]'}  border-b-[#E0E0E0] border-b-[1px] flex items-center relative hover:bg-[#f4f0fa]`}>
          <div className="w-max text-left ml-1 box-border px-2 pt-[2px]">
            <p className="flex items-center ">{title}</p>
            <p className="text-[10px] my-1">{subTitle}</p>
          </div>
          {showAction&&
           <button className="btn right-[20px] absolute">
           <FontAwesomeIcon icon={faEllipsisV}/>
         </button>
          }
        </button>

    );
};

export default ChatListItem;