import { useEffect, useState } from "react";
import SkillList from "../components/organisms/SkillList";
import ChatWindow from "../components/organisms/ChatWindow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { setCanSkipLogin } from "../Redux/Reducers/loginSlice";
import { useNavigate, useParams } from "react-router-dom";
import { copilotSetup } from "../API/copilotSetup";

interface Props{
  enableSkip?:boolean
}

const CopilotExport = ({enableSkip=false}:Props) => {
  const [skillID, setSkillID] = useState<number | null>(null);
  const [showSkill,setShowSkill]=useState(false);
  const [skillName, setSkillName] = useState('');
  const dispatch=useDispatch()
  const loginState=useSelector((state:any)=>state?.loginReducer)
  const navigateTo=useNavigate();
  const {webbot_id}=useParams();
  const [webBotName,setWebbotName]=useState('');

  useEffect(()=>{
    if (localStorage.getItem('nextMove')) {
      localStorage.removeItem('nextMove')
    }
    copilotSetup.getWebbotDetails(webbot_id)
    .then(res=>setWebbotName(res.data.name))
    .catch(err=>console.log(err))
  },[])

  useEffect(()=>{
    if(enableSkip)return;
    if(loginState.isGuest)return;
    let token=localStorage.getItem('token');

    if(token){
      if(!loginState.userLoaded)return;
      if(loginState?.userData)return;
    }
    dispatch(setCanSkipLogin(true))
    localStorage.setItem('nextMove',`/CopilotExport/${webbot_id}`)
    navigateTo('/')
    
  },[loginState])

  const toggleSkill=()=>{
    setShowSkill(prev=>!prev);
  }

  return (
    <>
      <div style={{flexFlow:'column'}} className="flex w-full h-[100vh] lg:h-[99vh] lg:w-[80%] mx-auto lg:px-[1%]">
        <div className="w-full m-auto mt-2 flex justify-between  px-1 md:px-2">
          <div className="mb-4">
            <p className="text-lg md:text-xl text-left py-1 font-semibold">{webBotName} </p>
            <p className="text-xs text-left lg:hidden ">{skillName}</p> 
          </div>
          <button onClick={toggleSkill} className="bg-[#F5EFFF] w-[40px] h-[40px] aspect-square grid place-content-center rounded-full mr-2 lg:hidden">
            <FontAwesomeIcon className="text-[#a16ef3] text-lg" icon={faBars}/>
          </button>
        </div>
        <div  className="w-max flex-1 h-full md:w-full m-auto lg:mt-[1vh] border-[2px] min-h-[500px]">
          <div className=" flex h-full w-full bg-black">
            <div className={`fixed top-0 bg-white z-[999] ${showSkill?'left-0':'left-[-100vw]'}  w-[100vw] h-[99vh] lg:h-full lg:w-max lg:static skill-slider`}>
              <SkillList closePanel={toggleSkill} id={skillID} setId={setSkillID} setSkillName={setSkillName} />
            </div>
            
            <div className={` h-full z-[99]  ${skillID ? 'block' : ''} bg-white w-[100vw] md:static  md:w-full md:flex overflow-hidden`}>
              <ChatWindow skillID={skillID||0} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CopilotExport;