import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UseCasesStep3 } from "../../components/organisms/UseCasesStep3";
import Navbar from "../../components/molecules/NavBar/Navbar";
import { Loading } from "../../components/atoms/Loader/Loading";
import { ChatBot } from "../../components/molecules/ChatBot/ChatBot";
import { useSelector } from "react-redux";

export const UseCaseData = () => {
  const location = useLocation();
  const webbot_id: any = useSelector((state: any) => state?.webbot?.id);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [location.pathname]);
  return (
    <div className="bg-[var(--background-color)]">
      {loading ? (
        <Loading />
      ) : (
        <>
          <Navbar />
          <UseCasesStep3 />
          <ChatBot />
        </>
      )}
    </div>
  );
};
