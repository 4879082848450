import React, { useEffect, useState } from "react";
import { CopilotForBot } from "../../molecules/UseCasesStep3/CopilotForBot";
import { migrateLogo } from "../../../pages/assets";
import { useDispatch, useSelector } from "react-redux";
import { toggleMessageResponse } from "../../../Redux/Reducers/SkillsData";
import { copilotProcess } from "../../../API/copilotProcess";
import { PostChatQuestionAndAnswer } from "../../../API/postChatQuestionAndAnswer";
import { set } from "lodash";

var callfunction = 0;

export const CopilotIframe = () => {
  const dispatch = useDispatch();
  const webbot_id: any = useSelector((state: any) => state?.webbot?.id);

  const skillId = useSelector((state: any) => state?.data?.skillId);
  const likeResponse: any = useSelector(
    (state: any) => state?.data?.likesResponse
  );
  const [preview, setPreview] = useState(false);
  const [questionAndAnswers, setQuestionAndAnswers] = useState([]);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [temporaryText, setTemporaryText] = useState("");
  const [conversationId, setConversationId] = useState("");
  const [chatSuggestions, setChatSuggestions] = useState<string[]>([]);

  useEffect(() => {
    if (callfunction === 0) {
      callfunction = 1;
      getConversationId();
    }
  }, []);

  const getConversationId = () => {
    copilotProcess.getChatSuggestions(webbot_id, setChatSuggestions);
    copilotProcess
      .createConversationId(webbot_id)
      .then((id: any) => {
        setConversationId(id);
        copilotProcess.getQuestionAndAnswerBasedOnConversationId(conversationId, setQuestionAndAnswers);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    copilotProcess.getQuestionAndAnswerBasedOnConversationId(conversationId, setQuestionAndAnswers);
  }, [likeResponse]);

  const handlePostChat = () => {
    PostChatQuestionAndAnswer(
      webbot_id,
      text,
      setQuestionAndAnswers,
      setTemporaryText,
      setLoading,
      conversationId,
      skillId,
      setText,
      dispatch,
      setChatSuggestions
    );
  };
  return (
    <div className=" w-full p-[10px] h-[69vh] bg-[#f5f5f5] ">
      <CopilotForBot
        preview={preview}
        setPreview={setPreview}
        questionAndAnswers={questionAndAnswers}
        text={text}
        setText={setText}
        temporaryText={temporaryText}
        setTemporaryText={setTemporaryText}
        onSend={handlePostChat}
        loading={loading}
        setLoading={setLoading}
        chatSuggestions={chatSuggestions}
        setChatSuggestions={setChatSuggestions}
        webbot_id={webbot_id}
      />
      <div className="flex items-center justify-center pt-[10px] gap-2">
        <p className="text-[12px] text-[var(--primaryTextColor)] font-extrabold">
          Powered by{" "}
        </p>
        <span>
          <img src={migrateLogo} alt="Maigrate" className="w-[80px]" />
        </span>
      </div>
    </div>
  );
};
