// webbotSlice.js
import { createSlice } from "@reduxjs/toolkit";

const webbotSlice = createSlice({
  name: "webbot",
  initialState: { id: null },
  reducers: {
    setWebbotId: (state, action) => {
      state.id = action.payload;
    },
  },
});

export const { setWebbotId } = webbotSlice.actions;
export default webbotSlice.reducer;
