import React, { useEffect, useState } from "react";
// import { CopilotData } from "../../../utlits/Data";
import { Button } from "../../atoms/Button/Button";
import Popup from "../../atoms/PopupCreateSkill/Popup";
import { resetCreateSkill, setCreateSkill, toggleCreateSkillName } from "../../../Redux/Reducers/SkillsData";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { copilotProcess } from "../../../API/copilotProcess";
import { AccordionCustomIcon } from "../../atoms/Accordian/DefaultAccordion";

interface CopilotInstructionsProps {
  setCreateCopilot: React.Dispatch<React.SetStateAction<boolean>>;
  handleInstructionData: (id: any) => void;
  createCopilot: boolean;
  setPreview?: React.Dispatch<React.SetStateAction<string>>;
}

export const CopilotInstructions = ({
  handleInstructionData,
  setCreateCopilot,
  createCopilot,
  setPreview,
}: CopilotInstructionsProps) => {
  const webbot_id: any = useSelector((state: any) => state?.webbot?.id);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [CopilotData, setCopilotData] = useState<any>();
  const [activeIndex, setActiveIndex] = useState(0);
  const [copilotInstructionData, setCopilotInstructionsData] = useState([]);
  const dispatch = useDispatch();

  const createSkillName = useSelector(
    (state: any) => state?.data?.createSkillName
  );
  const updatedSuccesfully = useSelector(
    (state: any) => state?.data?.updatedSuccesfully
  );

  useEffect(()=>{
    if(CopilotData && CopilotData?.length==0){
      handleToggle();
    }
  },[CopilotData])


  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  useEffect(() => {
    if(webbot_id){
      getInstructionsData();
    }
  }, [createSkillName,webbot_id,updatedSuccesfully]);

  //--------------------------TO GET ALL INSTRUCTIONS--------------------------------------

  const getInstructionsData = () => {
    copilotProcess
      .getInstructions(webbot_id)
      .then((response) => {
        if(response?.data?.length > 0){
        setCopilotInstructionsData(response?.data?.reverse());
        setCopilotData(response?.data.reverse());
        //handleInstructionData(response?.data[0]?.id);
        }else{
          console.debug("No Tasks present in response")
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //--------------------------TO TOOGLE CREATE NAME BUTTON--------------------------------------

  const handleToggle = () => {
    setPreview && setPreview("Task");
    dispatch(setCreateSkill()); // Dispatch the toggle action
  };

  return (
    <div className="flex flex-col justify-start items-start gap-1 bg-[var(--primarybackgroundWhite)] p-[4%] py-[4%] rounded-[20px] w-full mt-[20px] ">
      <div className="flex w-full justify-between">
        <p className="text-[var(--primaryTextColor)] text-[120%] ">Tasks</p>
        <div>
          <Button
            size="xsmall"
            bgColor="primary"
            className="py-[4%] rounded-[10px] px-[10px] text-[80%]"
            onClick={handleToggle}
          >
            Create Task
          </Button>

          {isPopupOpen && <Popup onClose={closePopup} />}
          {/* Add the backdrop element */}
          {isPopupOpen && (
            <div
              className="fixed inset-0 bg-black opacity-50 z-10"
              onClick={closePopup}
            ></div>
          )}
        </div>
      </div>
      <div className="w-full flex flex-col gap-2 h-[22vh] overflow-y-auto custom-scrollbar">
        <div className="">
          <AccordionCustomIcon
            InstructionsAccordianData={copilotInstructionData}
            handleInstructionData={handleInstructionData}
            setActiveIndex={setActiveIndex}
            setPreview={setPreview}
          />
        </div>
      </div>
     {/*  <div className="w-full flex-col justify-start h-[20vh] overflow-y-auto custom-scrollbar ">
        <div className="pr-[10px]">
          {CopilotData?.length > 0 ? (
            <>
              {CopilotData?.map((data: any, index: any) => {
                const isActive = index === activeIndex;
                let context;
                try {
                  context = JSON.parse(data?.context);
                } catch (error) {
                  return null; // Skip rendering this item
                }
                return (
                  <div
                    onClick={() => {
                      //setPreview && setPreview("Task");
                      handleInstructionData(data.id);
                      dispatch(resetCreateSkill())}}
                    key={data?.id}
                  >
                    <div
                      className={`${
                        isActive
                          ? "bg-[var(--primary)] px-[5%] py-[2%] rounded-[8px]"
                          : "px-[] py-[3%] border-b border-[#c4c4c4]"
                      } ${
                        index === 0 && "mt-[20px]"
                      } flex justify-start cursor-pointer w-full`}
                      onClick={() => setActiveIndex(index)}
                    >
                      <p
                        className={`${
                          isActive
                            ? "text-[#fff]"
                            : "text-[var(--otherTextColor)]"
                        }`}
                      >
                        {context && context?.skill_name}
                      </p>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <></>
            // <div>
            //   <div
            //     className={`bg-[var(--primary)] px-[5%] py-[2%] rounded-[8px] flex justify-start cursor-pointer w-full`}
            //     onClick={() => console.debug("Clicked")}
            //   >
            //     <p className={`text-[#fff]`}></p>
            //   </div>
            // </div>
          )}
        </div>
      </div> */}
    </div>
  );
};
