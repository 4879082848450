import { useState } from "react";
import CopilotHistoryList from "../molecules/CopilotChatHistory/CopilotHistoryList";
import CopilotHistoryChats from "../molecules/CopilotChatHistory/CopilotHistoryChats";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const CopilotHistoryWindow = () => {
    const [conversationId,setConversationId]=useState<number|null>(null);
   
    return (
     <div className="h-[80vh] flex ">
     <CopilotHistoryList conversationId={conversationId} setConversationId={setConversationId}/>
     <div className={`
     fixed top-0 left-0 h-[100vh] ${conversationId?'block':'hidden'} bg-white w-[100vw] 
     md:static md:h-full md:w-full md:flex
     `}>
      <button onClick={()=>setConversationId(null)} className="text-[grey] text-[25px] px-5 py-1 rounded-sm mt-2 md:hidden absolute top-[-10px] right-[-2px]">
        <FontAwesomeIcon icon={faTimes}/>
      </button>
     <CopilotHistoryChats conversationId={conversationId}/>
     </div>
   
     </div>
    );
  };
  
export default CopilotHistoryWindow;