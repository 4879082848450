import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../../atoms/Button/Button";
import { useSelector } from "react-redux";
import { PluginData } from "../../../../utlits/types";
import PremiumFeatureModal from "../../../atoms/PremiumFeature/PremiumFeature";
import Tooltip from "../../../atoms/Tooltip/ToolTip";
import { copilotSetup } from "../../../../API/copilotSetup";

interface PluginSettingsProps {
  setPreview: React.Dispatch<React.SetStateAction<string>>;
}

export const PluginSettings = ({ setPreview }: PluginSettingsProps) => {
  // State for the form fields
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [logoURL, setLogoURL] = useState("");
  const [url, setUrl] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const webbot_id: any = useSelector((state: any) => state?.webbot?.id);

  const copyCodeToClipboard = (codeSnippet: string) => {
    navigator.clipboard.writeText(codeSnippet);
  };

  // useEffect hook to fetch data from the API when the component mounts
  useEffect(() => {
    copilotSetup.getPluginDetails(webbot_id).then((data: PluginData) => {
      console.debug("Successfully fetched plugin settings:", data);
      setName(data.name ?? "");
      setDescription(data.description ?? "");
      setLogoURL(data.logoURL ?? "");
      setUrl(data.url ?? "");
    });
  }, []); // Empty dependency array means this useEffect runs once when component mounts

  // Handler for the update button
  const handleUpdate = () => {
    const newPluginData: PluginData = {
      name: name,
      description: description,
      logoURL: logoURL,
    };
    copilotSetup
      .updatePluginDetails(webbot_id, newPluginData)
      .then((response) => {
        setModalText(`Plugin settings updated`);
        setModalOpen(true);
        console.debug("Successfully updated plugin settings:", response);
      })
      .catch((error) => {
        setModalText(error.message);
        setModalOpen(true);
        console.error("Error updating plugin settings:", error);
      });
  };

  return (
    <div className="w-full px-[20px] pt-[15px] flex flex-col items-start text-left md:h-[83vh] overflow-auto custom-scrollbar rounded-[20px] shadow-lg">
      {/* Back button */}
      <div className="w-full flex justify-between pb-[15px]">
        <div
          className="flex gap-[15px] items-center cursor-pointer"
          onClick={() => setPreview("")}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            size="xs"
            className="text-[#555] w-[15px] h-[15px]"
          />
          <p className="text-[#555] text-[16px]">Back</p>
        </div>
        {/* ... (other elements) */}
      </div>

      <div className="w-full mt-4">
        <p className="mb-4">This is the URL for your plugin:</p>
        <p className="mb-4">
          {url}
          <Tooltip text={showTooltip ? "Copied!" : "Copy URL"}>
            <button
              className="copy-button items-end justify-end self-end border ml-4 border-blue-gray-200 py-[3px] px-[7px] rounded-[5px] text-[12px]"
              onClick={() => {
                copyCodeToClipboard(url);
                setShowTooltip(true);
                setTimeout(() => setShowTooltip(false), 1000);
              }}
            >
              Copy
            </button>
          </Tooltip>
        </p>

        <label className="block mb-4">
          Please enter the Name for your plugin:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mt-2 px-3 py-2 w-full border rounded-md"
          />
        </label>

        <label className="block mb-4">
          Please enter the Description for your plugin:
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="mt-2 px-3 py-2 w-full border rounded-md"
          />
        </label>

        <label className="block mb-4">
          Please enter the Logo Image URL for your plugin:
          <input
            type="text"
            value={logoURL}
            onChange={(e) => setLogoURL(e.target.value)}
            className="mt-2 px-3 py-2 w-full border rounded-md"
          />
        </label>
        <Button
          size="xsmall"
          bgColor="primary"
          className="py-[10px] rounded-[10px] px-[10px] text-[80%]"
          onClick={handleUpdate}
        >
          Update Plugin
        </Button>
      </div>
      <PremiumFeatureModal
        text={modalText}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      />
    </div>
  );
};

export default PluginSettings;
