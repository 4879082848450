import { useEffect, useState } from "react";
import { WhiteCopilotLogo, cross, migrateLogo } from "../../../pages/assets";
import { useDispatch, useSelector } from "react-redux";
import { CustomCopilotBot } from "../UseCasesStep3/CustomCopilotBot";
import { UseNavigation } from "../../../utlits/CustomHooks/Navigation";
import { copilotProcess } from "../../../API/copilotProcess";
import { PostChatQuestionAndAnswer } from "../../../API/postChatQuestionAndAnswer";

var callfunction = 0;

export const ChatBotModal = ({ isOpen, onClose }: any) => {
  const skillId = useSelector((state: any) => state?.data?.skillId);
  const webbot_id: any = useSelector((state: any) => state?.webbot?.id);
  const dispatch = useDispatch();
  const likeResponse: any = useSelector(
    (state: any) => state?.data?.likesResponse
  );
  const navigateTo = UseNavigation();
  const [preview, setPreview] = useState(false);
  const [questionAndAnswers, setQuestionAndAnswers] = useState<string[]>([]);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [temporaryText, setTemporaryText] = useState("");
  const [conversationId, setConversationId] = useState<string>();
  const [chatSuggestions, setChatSuggestions] = useState<string[]>([]);

  useEffect(() => {
    if (callfunction === 0) {
      callfunction = 1;
      getConversationId();
    }
  }, []);

  const getConversationId = () => {
    copilotProcess
      .createConversationId(webbot_id)
      .then((id: any) => {
        callfunction = 0;
        setConversationId(id);
        copilotProcess.getQuestionAndAnswerBasedOnConversationId(id, setQuestionAndAnswers);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {

    if(conversationId){
      copilotProcess.getQuestionAndAnswerBasedOnConversationId(conversationId, setQuestionAndAnswers);
    }
  }, [likeResponse,conversationId]);

  const handlePostChat = () => {
    PostChatQuestionAndAnswer(
      webbot_id,
      text,
      setQuestionAndAnswers,
      setTemporaryText,
      setLoading,
      conversationId!,
      skillId,
      setText,
      dispatch,
    );
  };



  return (
    <div className="fixed bottom-[70px] right-8 rounded-[20px]">
      <div className="shadow-xl bg-[#F5F5F5] h-[82vh] w-[28vw] rounded-[20px]">
        <div className="flex justify-between bg-[var(--primary)] rounded-tl-[20px] rounded-tr-[20px] p-[13px] items-center ">
          <div className="flex gap-1 items-center">
            <img
              src={WhiteCopilotLogo}
              alt="Logo"
              className="w-[20px] h-[20px] "
            />
            <p className="text-[20px] text-[#ffff] font-extrabold ">AI Copilot</p>
          </div>
          <div
            className=" hover:cursor-pointer text-[var(--primary)] "
            onClick={() => onClose(false)}
          >
            <img
              src={cross}
              alt="Logo"
              className="w-[35px] p-1 bg-[#6f2bd7] rounded-[50%]"
            />
          </div>
        </div>
        <div className="p-[10px]">
          <CustomCopilotBot
            onSend={handlePostChat}
            questionAndAnswers={questionAndAnswers}
            preview={preview}
            setPreview={setPreview}
            text={text}
            setText={setText}
            temporaryText={temporaryText}
            setTemporaryText={setTemporaryText}
            loading={loading}
            setLoading={setLoading}
            chatSuggestions={chatSuggestions}
            setChatSuggestions={setChatSuggestions}
          />
          <div className="flex items-center justify-center pt-[5px] gap-2">
            <p className="text-[12px] text-[var(--primaryTextColor)] font-extrabold">
              Powered by{" "}
            </p>
            <span>
              <img src={migrateLogo} alt="Maigrate" className="w-[80px]" />
            </span>
          </div>
        </div>
        {/* <IframeForChat /> */}
      </div>
    </div>
  );
};
