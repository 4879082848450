import React, { useState } from 'react';
import {
    disLikeButton,
 //   dislikeFrame,
    disliked,
    likeButton,
 //   likeFrame,
    liked,
  } from "../../../pages/assets";
import { useDispatch,} from "react-redux";
import { copilotProcess } from '../../../API/copilotProcess';

interface Props {
    data: any;
}

const RatingComponent: React.FC<Props> = (props) => {
  const {
    data,
  } = props;
  const [rating,setRating]=useState(data.rating);

  const dispatch = useDispatch();

  return (
      <div className="flex gap-[10px] justify-start items-start ">
                        {rating === 3 || rating === 1 ? (
                          <img
                            src={likeButton}
                            alt="like"
                            className="w-[20px] h-[20px] cursor-pointer"
                            onClick={() =>{
                              copilotProcess.handleResponseRating(data.id, "like", dispatch)
                              setRating(5)
                            }
                            }
                            // onClick={() => openLikePopup(index)}
                          />
                        ) : null}
                        {rating === 5 && (
                          <img
                            src={liked}
                            alt="like"
                            className="w-[20px] h-[20px] cursor-pointer"
/*                             onClick={() =>
                              copilotProcess.handleResponseRating(data.id, "like", dispatch)
                            } */
                            // onClick={() => openLikePopup(index)}
                          />
                        )}
{/* 
                        {isLikePopupOpen[index] && (
                          <>
                            <LikeResponse
                              onClose={() => closeLikePopup(index)}
                              setFeedBack={(newFeedback) =>
                                setFeedback(newFeedback)
                              }
                              feedback={feedback}
                              handleFunction={handleLikeFeedBack}
                              image={likeFrame}
                              placeholder="What do you like about the response?"
                            />
                            <div
                              className="fixed inset-0 bg-black opacity-50 z-10"
                              onClick={() => closeLikePopup(index)}
                            ></div>
                          </>
                        )} */}

                        {rating === 3 || rating === 5 ? (
                          <img
                            src={disLikeButton}
                            alt="dislike"
                            className="w-[20px] h-[20px] cursor-pointer"
                            onClick={() =>{
                              copilotProcess.handleResponseRating(data.id, "dislike", dispatch)
                              setRating(1)
                            }
                            }
                            // onClick={() => openUnlikePopup(index)}
                          />
                        ) : null}
                        {rating === 1 && (
                          <img
                            src={disliked}
                            alt="disliked"
                            className="w-[20px] h-[20px] cursor-pointer"
                            /* onClick={() =>
                              copilotProcess.handleResponseRating(data.id, "dislike", dispatch)
                            } */
                            // onClick={() => openUnlikePopup(index)}
                          />
                        )}

{/*                         {isUnlikePopupOpen[index] && (
                          <>
                            <LikeResponse
                              onClose={() => closeUnlikedPopup(index)}
                              setFeedBack={(newFeedback) =>
                                setFeedback(newFeedback)
                              }
                              feedback={feedback}
                              handleFunction={handleDisLikeFeedBack}
                              image={dislikeFrame}
                              placeholder="What was the issue with the response?How could it be improved?"
                            />
                            <div
                              className="fixed inset-0 bg-black opacity-50 z-10"
                              onClick={() => closeUnlikedPopup(index)}
                            ></div>
                          </>
                        )} */}
                      </div>
  );
};

export default RatingComponent;
