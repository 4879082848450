// import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
// import SkillsData from "./Reducers/SkillsData";
// import progressReducer from "./Reducers/ProgressSlice";
// import webbotReducer from "./Reducers/webbotSlice";
// import localStorageMiddleware from "./localStorageMiddleware";

// export const store = configureStore({
//   reducer: {
//     data: SkillsData,
//     progress: progressReducer, // You can have multiple slices
//     webbot: webbotReducer,
//   },
//   middleware: [...getDefaultMiddleware(), localStorageMiddleware],
// });

// const initialWebbotId = localStorage.getItem("webBot_id");
// if (initialWebbotId) {
//   store.dispatch(setWebbotId(initialWebbotId));
// }

// // Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>;
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch;

// store.js

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import SkillsData from "./Reducers/SkillsData";
import progressReducer from "./Reducers/ProgressSlice";
import webbotReducer, { setWebbotId } from "./Reducers/webbotSlice";
import localStorageMiddleware from "./localStorageMiddleware";
import { encryptTransform } from "redux-persist-transform-encrypt";
import { setupListeners } from "@reduxjs/toolkit/query";
import storage from "redux-persist/lib/storage";
import loginReducer from "./Reducers/loginSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const encryptor = encryptTransform({
  secretKey: "qwertyuiooiuytrewertyuiopy",
  onError: function (error) {
    console.error("error", error);
  },
});

const persistConfig = {
  key: "webBot_id",
  storage,
  transforms: [encryptor],
};

const persistedReducer = persistReducer(persistConfig, webbotReducer);

export const store = configureStore({
  reducer: {
    data: SkillsData,
    progress: progressReducer, // You can have multiple slices
    webbot: persistedReducer,
    loginReducer: loginReducer,
  },
  // middleware: [...getDefaultMiddleware(), localStorageMiddleware],
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    localStorageMiddleware,
  ],
});

// Initialize the webbot id from local storage
const initialWebbotId = localStorage.getItem("webBot_id");
if (initialWebbotId) {
  store.dispatch(setWebbotId(initialWebbotId));
}

setupListeners(store.dispatch);

export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
