import React, { useState, ChangeEvent } from "react";
import { Input } from "../../components/atoms/Input/Input";
import { Button } from "../../components/atoms/Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthNav } from "../../components/molecules/NavBar/AuthNav";
import Typography from "../../components/atoms/Typography/Typography";
import { useDispatch } from "react-redux";
import { setUserData, setlogin } from "../../Redux/Reducers/loginSlice";
import { CustomFailureFrame } from "../../components/atoms/Frames/CustomFailureFrame";
import { GoogleLogin } from "@react-oauth/google";
import { authentication } from "../../API/authentication";
import { setItem } from "../../utlits/localStorage";
import { moveNext } from "../../utlits/nextNavigate";
export const Login = () => {
  const location = useLocation();

  const routeName = location.pathname;

  const navigateTo = useNavigate();

  const dispatch = useDispatch();

  const [email, setEmail] = useState<string>(""); // set the email for login

  const [credential, setCredential] = useState<any>(""); // set the credential for login while logging with google

  const [frame, setFrame] = useState<boolean>(false); // this is for showing the alert dialog

  const ifRoute =
    routeName === "/login" ||
    routeName === "/forgot_password" ||
    routeName === "/login_password";

  // set the email to state while on changing input
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  // set the credential value which is getting from the google login
  const handleGoogleSignUp = (res: any) => {
    setCredential(res?.credential);
  };

  // check the valid email address and navigating to the enter password screen/page
  const navigateToLoginPassword = () => {
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;
    if (email !== "" && emailRegex.test(email)) {
      sessionStorage.setItem("email", email);
      // dispatch(setlogin(email)); // dispatching the email to the store for carry forward this mail to enter password screen/page
      navigateTo("/login_password");
    } else {
      setFrame(true);
      // setting the time for 2 sec to show the error message alert frame
      setTimeout(() => {
        setFrame(false);
      }, 2000);
    }
  };

  React.useEffect(() => {
    if (credential) {
      authentication
        .loginWithGoogle(credential)
        .then((res: any) => {
           let next=moveNext()
          if(next){
            navigateTo(next)
          }else{
            if (res?.data?.data?.user?.maigrateInitialize) {
              navigateTo("/dashboard");
            } else {
              navigateTo("/intro");
            }
          }
          dispatch(setUserData(res.data?.data?.user))
          setItem("token", res?.data?.data?.token); // set the token to the localStorage for further authentication user
          setItem("signedUser", "user"); // set the user to the localStorage for showing the alert message for the first time
         
        
        })
        .catch((err) => {
          return err;
        });
    }
  }, [credential]);

  return (
    <div>
      {frame && <CustomFailureFrame message={"Please Enter Valid Email"} />}
      <AuthNav />
      <div className="md:w-[fit-content] w-[90%] m-auto mt-[23vh] ">
        <Typography
          variant="h3"
          className="text-[var(--primaryHeadingColor)] md:text-left text-center"
        >
          Welcome Back!
        </Typography>
        <Typography
          className="tracking-[0.020em] text-[var(--primarySideHeadingColor)] md:text-[13px] text-[12px]"
          // variant="p"
        >
          Maigrate - Infinite Co-Pilots, Limitless Possibilities!
        </Typography>
        <div className="mt-5 ">
          <p className="text-left text-[12px] text-[var(--primaryLabelColor)] font-bold">
            What's your Email
          </p>
          <Input
            type="email"
            placeholder="example@gmail.com"
            onChange={handleOnChange}
          />
        </div>
        <Button
          size="small"
          bgColor="primary"
          className="p-[6px] mt-4 w-[30%] m-auto"
          onClick={navigateToLoginPassword}
        >
          Continue
        </Button>
        <div className="w-[fit-content] m-auto mt-3 ">
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              handleGoogleSignUp(credentialResponse);
            }}
            onError={() => {
              return;
            }}
            useOneTap={false}
          ></GoogleLogin>
        </div>
      </div>
      <p className="md:hidden block md:text-[20px] lg:text-[16px] text-[15px] mt-[5vh]">
        {ifRoute ? "Don't have an account ?" : "Do you have an account?"}{" "}
        {ifRoute ? (
          <span
            className="cursor-pointer font-bold "
            onClick={() => navigateTo("signup")}
          >
            Signup
          </span>
        ) : (
          <span
            className="cursor-pointer font-bold"
            onClick={() => navigateTo("login")}
          >
            Login
          </span>
        )}
      </p>
    </div>
  );
};
