import React, { useEffect } from "react";
import { Button } from "../../components/atoms/Button/Button";
import "../../App.css";
import { motion } from "framer-motion";
import { AuthNav } from "../../components/molecules/NavBar/AuthNav";
import { UseNavigation } from "../../utlits/CustomHooks/Navigation";
import Typography from "../../components/atoms/Typography/Typography";
import { useDispatch, useSelector } from "react-redux";
import { setUserGuest } from "../../Redux/Reducers/loginSlice";
import { moveNext } from "../../utlits/nextNavigate";
import { useNavigate } from "react-router-dom";

export const SplashScreen = () => {
  // used Custom hook for navigation
  const dispatch=useDispatch()
  const loginState=useSelector((state:any)=>state?.loginReducer)
  const navigateTo = UseNavigation();
  const navigate=useNavigate()
  useEffect(()=>{
    let token=localStorage.getItem("token");
    if(token){
      navigateTo('dashboard')
    }
  },[])

  const Skip=()=>{
    dispatch(setUserGuest(true));
    let next=moveNext()
    if(next){
      navigate(next)
    }
  }
  return (
    <div>
      <AuthNav />
      <div className="w-full border border-transparent">
        <div className="w-[fit-content] m-auto my-[27vh] overflow-hidden ">
          {/* <UpDownTextAnimation /> */}
          <motion.div
            initial={{ opacity: 1, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.2 }}
            className="text-xl font-bold"
          >
            <Typography
              className="text-[var(----secondaryTextColor)] font-bold lg:text-[30px] md:text-[45px] text-[25px]"
              style={{ fontFamily: "Poppins" }}
            >
              Welcome to
            </Typography>
          </motion.div>
          <div className="flex overflow-hidden items-center gap-1 lg:mt-3 md:mt-6 mt-2 ">
            <div className="md:w-[50px] lg:w-[29px] md:h-[50px]  lg:h-[26px] h-[25px] overflow-hidden mr-1 ">
              <motion.div
                initial={{ opacity: 1, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  duration: 1,
                  delay: 0.2,
                  // ease: [0.16, 1, 0.3, 1]
                }}
                className="text-black lg:text-[24px] md:text-[43px] text-[24px] overflow-hidden"
                style={{ fontFamily: "Angkor" }}
              >
                M
              </motion.div>
            </div>
            <div className="md:w-[50px] lg:w-[29px] md:h-[50px] lg:h-[26px] h-[25px] overflow-hidden md:ml-1 mr-1 md:mr-0 ">
              <motion.div
                initial={{ opacity: 1, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 1,
                  delay: 0.2,
                  // ease: [0.16, 1, 0.3, 1]
                }}
                className="text-black md:text-[43px] lg:text-[24px] text-[24px] overflow-hidden"
                style={{ fontFamily: "Angkor" }}
              >
                A
              </motion.div>
            </div>
            <div className=" md:w-[35px] lg:w-[29px] md:h-[50px] lg:h-[26px] h-[25px] overflow-hidden mr-1 lg:mr-0 ">
              <motion.div
                initial={{
                  opacity: 1,
                  width: 0, // Start with no width
                  x: 10, // Move the letter to the right
                }}
                animate={{
                  opacity: 1,
                  width: "fit-content", // Animate to the original width
                  x: 0, // Animate back to the original position
                }}
                transition={{
                  duration: 1,
                  delay: 0.2,
                  // ease: [0.16, 1, 0.3, 1]
                }}
                className="text-black md:text-[43px] lg:text-[24px] text-[24px] overflow-hidden m-auto  "
                style={{ fontFamily: "Angkor" }}
              >
                I
              </motion.div>
            </div>
            <div className=" md:w-[50px] lg:w-[29px] md:h-[50px]  lg:h-[26px] h-[25px] overflow-hidden mr-1 ">
              <motion.div
                initial={{
                  opacity: 1,
                  height: 0, // Start with no height
                  y: 20, // Move the letter down
                }}
                animate={{
                  opacity: 1,
                  height: "fit-content", // Animate to a taller height
                  y: 0,
                }}
                transition={{
                  duration: 1,
                  delay: 0.2,
                  // ease: [0.16, 1, 0.3, 1]
                }}
                className="text-black md:text-[43px] lg:text-[24px] text-[24px] overflow-hidden m-auto"
                style={{ fontFamily: "Angkor" }}
              >
                G
              </motion.div>
            </div>
            <div className=" md:w-[50px] lg:w-[29px] md:h-[50px]  lg:h-[26px] h-[25px] overflow-hidden mr-1 ">
              <motion.div
                initial={{
                  opacity: 1,
                  width: 0, // Start with no width
                  x: -5, // Move the letter to the left
                }}
                animate={{
                  opacity: 1,
                  width: "fit-content", // Animate to a width of "16px"
                  x: 0,
                }}
                transition={{
                  duration: 0.8,
                  delay: 0.5,
                  // ease: [0.16, 1, 0.3, 1]
                }}
                className="text-black md:text-[43px] lg:text-[24px] text-[24px] overflow-hidden m-auto"
                style={{ fontFamily: "Angkor" }}
              >
                R
              </motion.div>
            </div>
            <div className=" md:w-[50px] lg:w-[29px] md:h-[50px]  lg:h-[26px] h-[25px] overflow-hidden mr-1  ">
              <motion.div
                initial={{
                  opacity: 1,
                  height: 0, // Start with no height
                  y: 10, // Move the letter down
                }}
                animate={{
                  opacity: 1,
                  height: "fit-content", // Animate to a taller height
                  y: 0,
                }}
                transition={{
                  duration: 0.8,
                  delay: 0.5,
                  // ease: [0.16, 1, 0.3, 1]
                }}
                className="text-black md:text-[43px] lg:text-[24px] text-[24px] overflow-hidden m-auto"
                style={{ fontFamily: "Angkor" }}
              >
                A
              </motion.div>
            </div>
            <div className="md:w-[50px] lg:w-[29px] md:h-[50px]  lg:h-[26px] h-[25px] overflow-hidden mr-1 ">
              <motion.div
                initial={{ opacity: 1, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 1,
                  delay: 0.2,
                  // ease: [0.16, 1, 0.3, 1]
                }}
                className="text-black md:text-[43px] lg:text-[24px] text-[24px] overflow-hidden m-auto"
                style={{ fontFamily: "Angkor" }}
              >
                T
              </motion.div>
            </div>
            <div className="md:w-[50px] lg:w-[29px] md:h-[50px]  lg:h-[26px] h-[25px] overflow-hidden mr-1 ">
              <motion.div
                initial={{
                  opacity: 1,
                  width: 0, // Start with no width
                  x: 10, // Move the letter to the right
                }}
                animate={{
                  opacity: 1,
                  width: "fit-content", // Animate to the original width
                  x: 0, // Animate back to the original position
                }}
                transition={{
                  duration: 1,
                  delay: 0.2,
                  // ease: [0.16, 1, 0.3, 1]
                }}
                className="text-black md:text-[43px] lg:text-[24px] text-[24px] overflow-hidden m-auto"
                style={{ fontFamily: "Angkor" }}
              >
                E
              </motion.div>
            </div>
          </div>
          <div className="flex justify-between mt-[20px] lg:mt-[15px] md:mt-[22px] w-[99%]">
            <motion.div
              initial={{ x: -50 }}
              animate={{ x: 0 }}
              transition={{ duration: 1, delay: 0.2 }}
              className="w-[47%]"
            >
              <Button
                className="w-full md:p-[15px] lg:p-[7px] p-[7px] md:text-[20px] lg:text-sm text-sm"
                size="small"
                bgColor="primary"
                onClick={() => navigateTo("login")}
              >
                Login
              </Button>
            </motion.div>
            <motion.div
              initial={{ x: 50 }}
              animate={{ x: 0 }}
              transition={{ duration: 1, delay: 0.2 }}
              className="w-[47%]"
            >
              <Button
                className="w-full md:p-[15px] lg:p-[7px] p-[7px] md:text-[20px] lg:text-sm text-sm"
                size="small"
                bgColor="primary"
                onClick={() => navigateTo("signup")}
              >
                Signup
              </Button>
            </motion.div>
          </div>
         {loginState.canSkipLogin&&<div className="mt-12">
            <button onClick={Skip} className="btn underline text-gray-500 ">Countinue as Guest</button>
          </div>}
        </div>
      </div>
    </div>
  );
};
