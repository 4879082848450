import React, { useState } from "react";
import { EditLogo, TrashLogo, sendIcon } from "../../pages/assets";
import { UseNavigation } from "../../utlits/CustomHooks/Navigation";
import { setWebbotId } from "../../Redux/Reducers/webbotSlice";
import { useDispatch, useSelector } from "react-redux";
import DeletePopup from "../atoms/DeletePopup/DeletePopup";
import { Link } from "react-router-dom";

interface ApiResponse {
  [key: string]: any;
}
interface DashboardTableProps {
  copilotsData: ApiResponse[];
  deleteCopilot: (id: number) => void;
}

export const DashboardTable = ({
  copilotsData,
  deleteCopilot,
}: DashboardTableProps) => {
  const navigateTo = UseNavigation();
  const dispatch = useDispatch();
  const userData=useSelector((state:any)=>state?.loginReducer?.userData)
  const [deleteId,setDeleteId]=useState(null);

  //----------------------------To dispatch webbotid to redux------------------------------------

  const selectCopilot = (id: any) => {
    dispatch(setWebbotId(id));

    navigateTo(`Copilot`);
  };

  return (
    <>
     {deleteId &&<DeletePopup title="Are you sure you want to delete this copilot ?" onClose={()=>{setDeleteId(null)}} onConfirm={()=>{deleteCopilot(deleteId);setDeleteId(null)}}/>}
      <table className="w-[100%] border-collapse mt-5">
        <thead className="bg-[#fcfcfd]">
          <tr className=" text-[var(--primaryTextColor)] text-[18px]">
            <th className="py-2 px-4 border-b border-gray-400 text-left lg:text-[13px] text-[14px]">
              {/* Copilots */}
            </th>
            <th className="py-2 px-4 border-b border-gray-400"></th>
          </tr>
        </thead>
        {copilotsData.length === 0 ? (
  <tbody>
    <tr>
      <td colSpan={2} className="p-3 w-[fit-content] m-auto font-bold">
        No Data Found
      </td>
    </tr>
  </tbody>
) : null}
        <tbody>
          {copilotsData?.map((data: any) => {
            const timestampString = `${data?.created_date}`;
            const timestamp = new Date(timestampString);

            const options: Intl.DateTimeFormatOptions = {
              year: "numeric",
              month: "short",
              day: "numeric",
            };
            const formattedDate = timestamp.toLocaleDateString(
              undefined,
              options
            );
            return (
              <>
                {data?.active && (
                  <tr key={data?.id} className="border-b border-gray-400">
                    <td
                      className="py-4 px-4 text-left text-[#606060] lg:text-[13px] text-[14px]"
                      onClick={() => selectCopilot(data?.id)}
                    >
                      <div className="flex gap-2 items-center cursor-pointer">
                        <div className=" bg-[#dfeafe] rounded-[50%] p-1 ">
                          <img src={sendIcon} alt="" width="16px" />
                        </div>
                        <div>
                          <p className="text-[14px] font-bold">
                            {data?.name || userData?.email} Copilot
                          </p>
                          <div className="text-[11px]">{formattedDate}</div>
                        </div>
                      </div>
                    </td>
                    <td className="py-4 px-4 justify-end cursor-pointer  text-right ">
                      <div className="flex items-center justify-end">
                        <Link state={{webbot_id:data.id,name:data?.name}} to={{pathname:'/copilot-history'}}  className="btn text-xs bg-gray-100 px-5 py-1.5 mt-1 mx-3 rounded-xl">
                          View History
                        </Link>
                        <img
                          src={TrashLogo}
                          alt="delete"
                          className="w-[20px] h-[20px] lg:w-[20px] lg:h-[20px] md:w-[20px] md:h-[20px] lg:my-[0px] md:my-[14px] my-[12px]"
                          onClick={() => setDeleteId(data?.id)}
                        />
                        {/* <Link state={{webbot_id:data.id,name:data?.name}} to={{pathname:'/Configure'}}>
                        <img
                          src={EditLogo}
                          alt="delete"
                          className="w-[20px] h-[20px] lg:w-[20px] lg:h-[20px] md:w-[20px] md:h-[20px] lg:my-[0px] md:my-[14px] my-[12px] ml-3"
                          onClick={() => selectCopilot(data?.id)}
                        />
                        </Link> */}
                        
                      </div>
                    </td>
                  </tr>
                )}
              </>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
