import React from "react";
import "./App.css";
import { RoutesPage } from "./pages/Routes";
import "./utlits/Colors.css";
import "../src/styles/Slide.css";
import "../src/styles/ScrollBar.css";
function App() {
  return (
    <div className="App">
      <RoutesPage />
    </div>
  );
}

export default App;
