import axios from "axios";
import { NETWORK_URL, chat_options_number } from "../utlits/Network";
import { useDispatch, useSelector } from "react-redux";
import { toggleLikeResponse } from "../Redux/Reducers/SkillsData";
import { initial } from "lodash";

export const copilotProcess = {
  getConversationByWebBotID:(webbot_id: any)=>{
    if (!webbot_id) throw new Error("Webbot ID is missing");
    return axios.get(`${NETWORK_URL}/conversation?webbot_id=${webbot_id}`);
  },
  /**
   *  Returns just a conversationID
   * Response will be a number
   */
  createConversationId: async (webbot_id: any) => {
    if (!webbot_id) throw new Error("Webbot ID is missing");
    const response = await axios.post(`${NETWORK_URL}/conversation`, {
      webbot_id: webbot_id,
    });
    console.debug("Conversation ID: ", response.data!.id);
    return response.data!.id;
  },
  /**
   * @param conversationId
   * @param setChatHistory (optional, send if you want to set the chat history directly)
   * @returns list of all the questionAnswers for a conversationID
   */
  getQuestionAndAnswerBasedOnConversationId: async (conversationId: any, setChatHistory?: Function) => {
    if (!conversationId) throw new Error("Conversation ID is missing");
    const response = await axios.get(`${NETWORK_URL}/conversation/${conversationId}`);
    const questionAnswers = response?.data?.questionanswers.reverse();
    if(setChatHistory){
    setChatHistory(questionAnswers);
    }
    return questionAnswers;
  },
  getChatSuggestions: async (webbot_id: any, setChatOptions?: Function, question?: string) => {
    if (!webbot_id) throw new Error("Webbot ID is missing");
    const payload: { webbot_id: any; num_suggestions: number; question?: string } = {
      webbot_id: webbot_id,
      num_suggestions: chat_options_number,
    };
    if(question){
    payload["question"] = question;
    }
    return axios.post(`${NETWORK_URL}/questions/suggestions`, payload)
      .then((response) => {
        if (setChatOptions){
        setChatOptions(response?.data);
        } 
        return response?.data;
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  },
  setConfig: async (webbot_id: any,  initial_message: string, suggestions: string[],) => {
    if (!webbot_id) throw new Error("Webbot ID is missing");
    if (!suggestions) throw new Error("Suggestions are missing");
    if (!initial_message) throw new Error("Initial message is missing");
    const payload = {
      config: {
        initial_message: initial_message,
        suggested_questions: suggestions,
      },
    };
    return axios.put(`${NETWORK_URL}/webbots/${webbot_id}`, payload)
      .then((response) => {
        return response?.data.config?.suggestions;
      })
      .catch((error) => {
        console.error("Error: ", error);
        throw new Error("Error: ", error);
      });
  },
  getInitialMessage: async (webbot_id: any, setInitialMessage? : Function) => {
    if (!webbot_id) throw new Error("Webbot ID is missing");
    return axios.get(`${NETWORK_URL}/webbots/${webbot_id}`)
      .then((response) => {
        const initial_message =  response?.data.config?.initial_message ? response?.data.config?.initial_message : "How can I assist you?"
        if(setInitialMessage){
          setInitialMessage(initial_message);
        }
        return initial_message;
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  },
  createTask: async (webbot_id: number, skillName: string, skillContent : string) => {
    if (!webbot_id) throw new Error("Webbot ID is missing");
    if (!skillName) throw new Error("Skill Name is missing");
    if (!skillContent) throw new Error("Skill Content is missing");
    if (skillContent.length === 0) throw new Error("Skill Content is empty");
    const context_json = { skill_name: skillName, skills: skillContent};
    const payload = {
      webbot_id: webbot_id,
      context:JSON.stringify(context_json),
      context_type: "INSTRUCTION",
      source: "SKILL",
    };
    try {
      const response = await axios.post(`${NETWORK_URL}/context`, payload);
      return response;
    } catch (error: any) {
      return error?.response?.data;
    }
  },
  UpdateTask: async (webbot_id: number,skillId: number,skillName: string, skillContent : string) => {
    if (!webbot_id) throw new Error("Webbot ID is missing");
    if (!skillId) throw new Error("Skill ID is missing");
    const context_json = { skill_name: skillName, skills: skillContent};
    const payload = {
      webbot_id: webbot_id,
      context:JSON.stringify(context_json),
      context_type: "INSTRUCTION",
      source: "SKILL",
    };
    try {
      const response = await  axios.put(`${NETWORK_URL}/context/${skillId}`, payload);
      return response;
    } catch (error: any) {
      return error?.response?.data;
    }
  },
  postQuestion: (payload: object) => {
    if (!payload) throw new Error("Payload is missing");
    return axios.post(`${NETWORK_URL}/questionanswer`, payload);
  },
  getInstructions: (webbot_id: any) => {
    if (!webbot_id) throw new Error("Webbot ID is missing");
    return axios.get(
      `${NETWORK_URL}/context?webbot_id=${webbot_id}&context_type=INSTRUCTION`
    );
  },
  getInstructionDescription: (id: any) => {
    if (!id) throw new Error("ID is missing");
    return axios.get(`${NETWORK_URL}/context/${id}`);
  },
  updateCopilotInstructionDescription: (skillId: any, payload: object) => {
    if (!skillId) throw new Error("Skill ID is missing");
    if (!payload) throw new Error("Payload is missing");
    return axios.put(`${NETWORK_URL}/context/${skillId}`, payload);
  },

  handleResponseRating: async (id: number, type: any, dispatch:any) => {
    axios.get(`${NETWORK_URL}/questionanswer/${id}`)
      .then((response) => {
        const payload = response?.data;
        payload.rating = type === "like" ? 5 : 1;
        axios.put(`${NETWORK_URL}/questionanswer/${id}`, payload)
          .then((response) => {
            console.debug("Response", response?.data);
            dispatch(toggleLikeResponse());
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

 
