import React, { useState } from "react";
import "../../../styles/ScrollBar.css";
import { LoadingGif, deleteLogo } from "../../../pages/assets";
import { Button } from "../../atoms/Button/Button";
import { UseNavigation } from "../../../utlits/CustomHooks/Navigation";
import { copilotSetup } from "../../../API/copilotSetup";
import DeletePopup from "../../atoms/DeletePopup/DeletePopup";
interface UploadedDataSourceProps {
  setClicked: React.Dispatch<React.SetStateAction<boolean>>;
  clicked: boolean;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  allFiles: object[];
  setDeleteRes: React.Dispatch<React.SetStateAction<any>>;
  setDeletedSuccesfully: React.Dispatch<React.SetStateAction<boolean>>;
  uploadFiles: () => void;
  progress: number;
  getRes: boolean;
  setUrlResponse: any;
  isUploading:boolean;
}

export const UploadedDataSource = ({
  setClicked,
  clicked,
  files,
  setFiles,
  allFiles,
  setDeleteRes,
  setDeletedSuccesfully,
  uploadFiles,
  progress,
  getRes,
  isUploading,
}: UploadedDataSourceProps) => {
  const navigateTo = UseNavigation();
  const [deleteId,setDeleteId]=useState(null)
  // const webbot_id: any = useSelector((state: any) => state?.webbot?.id);

  //-------------------------TO REMOVE SELECTED FILES TO UPLOAD---------------------------------------

  const handleDeleteFile = (index: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const deleteConfirm=(file_id:any)=>{
    copilotSetup.handleDeleteFromAllFiles(file_id, setDeleteRes, setDeletedSuccesfully).catch((error: any) => { console.error(error) })
  }

  return (
    <div className="flex flex-col items-center gap-5 bg-[var(--primaryInputbackgroundWhite)]  lg:p-[1%] rounded-[20px] lg:w-[58%] md:w-[50%] h-[77vh] mt-[1.5%] p-[10px]">
      {deleteId && <DeletePopup title="Are you sure you want to delete this data?" 
      onClose={()=>setDeleteId(null)}
      onConfirm={()=>{deleteConfirm(deleteId);setDeleteId(null)}}
      />}
      {getRes ? (
        <div className="bg-[var(--primaryBackgroundColor)] w-full flex flex-col items-center justify-center min-h-screen mt-[-50px]">
          <img src={LoadingGif} alt="Your GIF" />
        </div>
      ) : (
        <>
          {/* uploading text until files get uploaded to server */}
          {files?.length >= 0 && allFiles ? (
            <>
              <div className="flex justify-start text-[#4f4f4f] lg:text-[130%] pt-[10px] ">
                <p className="">Uploaded Knowledge</p>
              </div>
              <div className=" flex flex-col gap-3 overflow-y-auto custom-scrollbar px-[1.5%] w-full">
                <div className="w-full p-[15px] rounded-[20px] bg-[#e2e2e2] ">
                  <table className="w-[100%] border-collapse">
                    <thead>
                      <tr className=" text-[var(--primaryTextColor)] text-[18px]">
                        <th className="py-2 px-4 border-b border-gray-400 lg:w-1/3 text-left lg:text-[15px] text-[14px]">
                          Data Type
                        </th>
                        <th className="py-2 px-4 border-b border-gray-400 text-left lg:text-[15px] text-[14px]">
                          File Name
                        </th>
                        <th className="py-2 px-4 border-b border-gray-400 "></th>
                      </tr>
                    </thead>
                    {progress !== 100 && files.length !== 0 ? (
                      <tbody>
                        <tr>
                          <td>
                            <p className="text-[#4f4f4f] text-[16px]">Uploading Documents...</p>
                          </td>
                        </tr>
                      </tbody>
                    ) : null}

                    {/* if no files are selected to display the file s from get api */}

                    {files.length === 0 && (
                      <tbody>
                        {allFiles?.map((file: any, index: number) => {
                          return (
                            <>
                              {file?.active && (
                                <tr
                                  className="border-b border-gray-400"
                                  key={file?.id}
                                >
                                  <td className="py-4 px-4 text-left text-[#606060] lg:text-[15px] text-[14px]">
                                    {file.file_type === ""
                                      ? "unkown"
                                      : file.file_type}
                                  </td>
                                  <td className="py-4 px-4 text-left text-[#606060] lg:text-[15px] text-[14px]">
                                    {file.name.length > 20
                                      ? file.name.substring(0, 20)
                                      : file.name}
                                    ...
                                  </td>
                                  <td className="py-4 px-4 items-end justify-end cursor-pointer text-right flex ">
                                    <img
                                      src={deleteLogo}
                                      alt="delete"
                                      className="lg:w-[24px] lg:h-[24px] md:w-[50px] md:h-[20px] lg:my-[0px] md:my-[14px] my-[12px]"
                                      onClick={() =>setDeleteId(file?.file_id)}
                                    />
                                  </td>
                                </tr>
                              )}
                            </>
                          );
                        })}
                      </tbody>
                    )}
                    {/* To Display files after progress get 100% */}
                    {progress === 100 ? (
                      <tbody>
                        {files?.map((file, index) => {
                          return (
                            <tr
                              className="border-b border-gray-400"
                              key={index}
                            >
                              <td className="py-4 px-4 text-left text-[#606060] lg:text-[15px] text-[14px]">
                                {file.type === "" ? "unkown" : file.type}
                              </td>
                              <td className="py-4 px-4 text-left text-[#606060] lg:text-[15px] text-[14px]">
                                {file.name.length > 20
                                  ? file.name.substring(0, 20)
                                  : file.name}
                                ...
                              </td>
                              <td
                                className="py-4 px-4 items-end justify-end cursor-pointer text-right flex "
                                onClick={() => handleDeleteFile(index)}
                              >
                                <img
                                  src={deleteLogo}
                                  alt="delete"
                                  className="lg:w-[24px] lg:h-[24px] md:w-[50px] md:h-[20px] lg:my-[0px] md:my-[14px] my-[12px]"
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : null}
                  </table>
                </div>
              </div>
              {/* rendering buttons according to the conditions */}
              <div className=" flex w-full p-[5px] justify-end items-end">
                {files?.length > 0 && progress === 100 ? (
                  <Button
                    size="xsmall"
                    bgColor="primary"
                    disabled={isUploading}
                    className={`lg:py-[1.5%] rounded-[12px] px-[4%] lg:text-[15px] py-[5px] ${isUploading?'opacity-75':'opacity-100'}`}
                    // onClick={() => navigateTo("UseCaseData")}
                    onClick={() => uploadFiles()}
                  >
                    Upload Files
                  </Button>
                ) : null}
                {files?.length === 0 && (
                  <Button
                    size="xsmall"
                    bgColor="primary"
                    className="lg:py-[1.5%] rounded-[12px] px-[4%] lg:text-[15px] py-[5px] "
                    onClick={() => navigateTo("Copilot")}
                  // onClick={() => uploadFiles()}
                  >
                    Next
                  </Button>
                )}
              </div>
            </>
          ) : (
            <div className=" justify-center items-center m-auto">
              <p className="text-[#848e96] lg:text-[18px] text-[20px]  ">
                Please Select Knowledge to upload
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};
