import React from "react";

export function InstructionsToEmbedBot() {
  return (
    <div className="flex flex-col justify-start text-left text-[12px]">
      <p className="text-[var(--primaryTextColor)] text-[16px] font-extrabold">
        <u>Instructions for Embedding Bot Using Script:</u>
      </p>
      <ol>
        <li>
          <p className="text-[14px] font-extrabold">Step 1: Copy the Script Code</p>
          <p>This is the code that is present above the instructions</p>
        </li>
        <li>
          <p className="text-[14px] font-extrabold">Step 2: Open Your Web Page's Code</p>
          <p>
            Open the HTML file of the web page where you want to embed the bot. You can do this using a text editor or through your website's content management system.
          </p>
        </li>
        <li>
          <p className="text-[14px] font-extrabold">Step 3: Paste the Script</p>
          <p>
            Paste the copied script into the HTML file. It's best to place the script tag just before the closing <code>&lt;/body&gt;</code> tag to ensure it doesn't block loading of other elements on your page.
          </p>
        </li>
        <li>
          <p className="text-[14px] font-extrabold">Step 4: Save and Upload (if necessary)</p>
          <p>
            Save your changes. If your website is hosted, upload the modified file to your server.
          </p>
        </li>
        <li>
          <p className="text-[14px] font-extrabold">Step 5: Test the Bot</p>
          <p>
            Open your web page in a browser to test the bot. Make sure it loads correctly and is functional.
          </p>
        </li>
        <li>
          <p className="text-[14px] font-extrabold">Step 6: Troubleshoot if Necessary</p>
          <p>
            If the bot doesn't appear or work as expected, check for any errors in the console of your browser's developer tools. Ensure that the script was pasted correctly and that there are no conflicts with other scripts on your page.
          </p>
        </li>
      </ol>
    </div>
  );
}
