export const useCaseContent = [
  {
    id: 1,
    contentTitle: "Investment",
    contentDescription: "Lorem Ipsum dolor sit amet, consectetur adip...",
  },
  {
    id: 2,
    contentTitle: "Investment",
    contentDescription: "Lorem Ipsum dolor sit amet, consectetur adip...",
  },
  {
    id: 3,
    contentTitle: "Investment",
    contentDescription: "Lorem Ipsum dolor sit amet, consectetur adip...",
  },
  {
    id: 4,
    contentTitle: "Investment",
    contentDescription: "Lorem Ipsum dolor sit amet, consectetur adip...",
  },
  {
    id: 5,
    contentTitle: "Investment",
    contentDescription: "Lorem Ipsum dolor sit amet, consectetur adip...",
  },
  {
    id: 6,
    contentTitle: "Investment",
    contentDescription: "Lorem Ipsum dolor sit amet, consectetur adip...",
  },
  {
    id: 7,
    contentTitle: "Investment",
    contentDescription: "Lorem Ipsum dolor sit amet, consectetur adip...",
  },
];

// Default
// Sales Enablement
// Customer Service
// Feedback & Reviews
// Analytics
// Due Diligence
// Patent Writing
// Content Generation
// Tutoring
// Business Plans
// Legal Contact
// Auditing
// Personal Finance
// Procurement
// QA
// Sales Outbound
// KYC
// Retention

export const AccordionData = [
  {
    id: 2,
    title: "Sales Enablement",
  },
  {
    id: 3,
    title: "Customer Service",
  },
  {
    id: 4,
    title: "Feedback & Reviews",
  },
  {
    id: 5,
    title: "Analytics",
  },
  {
    id: 6,
    title: "Due Diligence",
  },
  {
    id: 7,
    title: "Patent Writing",
  },
  {
    id: 8,
    title: "Content Generation",
  },
  {
    id: 9,
    title: "Tutoring",
  },
  {
    id: 10,
    title: "Business Plans",
  },
  {
    id: 11,
    title: "Legal Contact",
  },
  {
    id: 12,
    title: "Auditing",
  },
  {
    id: 13,
    title: "Personal Finance",
  },
  {
    id: 14,
    title: "Procurement",
  },
  {
    id: 15,
    title: "QA",
  },
  {
    id: 16,
    title: "Sales Outbound",
  },
  {
    id: 17,
    title: "KYC",
  },
  {
    id: 18,
    title: "Retention",
  },
];

export const useCaseContentDescription = [
  {
    id: 1,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 2,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 3,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 4,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 5,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 6,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 7,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 8,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 9,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 10,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 11,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 12,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 13,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 14,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 15,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 16,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 17,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 18,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 19,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 20,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 21,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 22,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
  {
    id: 23,
    description:
      "Lorem ipsum dolor sit amet consectetur. Donec dictum arcu eu pellentesque ipsum. Sit metus arcu semper quis netus. Velit facilisis eo bibendum enim sollicitudin auctor id convallis nibh. Mauris augue maecenas cursus at. Cursus curabitur sit ac fermentum ullamcorper. Eu mauris mauris enim magna semper. ullamcorper.",
  },
];

export const ScrollerImages = [
  { image: require("../Assets/1.png"), title: "Heading" },
  { image: require("../Assets/2.png"), title: "Heading" },
  { image: require("../Assets/3.png"), title: "Heading" },
  { image: require("../Assets/4.png"), title: "Heading" },
  { image: require("../Assets/5.png"), title: "Heading" },
  { image: require("../Assets/6.png"), title: "Heading" },
  { image: require("../Assets/7.png"), title: "Heading" },
  { image: require("../Assets/8.png"), title: "Heading" },
  { image: require("../Assets/1.png"), title: "Heading" },
  { image: require("../Assets/2.png"), title: "Heading" },
  { image: require("../Assets/3.png"), title: "Heading" },
  { image: require("../Assets/4.png"), title: "Heading" },
  { image: require("../Assets/5.png"), title: "Heading" },
  { image: require("../Assets/6.png"), title: "Heading" },
  { image: require("../Assets/7.png"), title: "Heading" },
  { image: require("../Assets/8.png"), title: "Heading" },
];

export const DataSourceAccordianData = [
  { id: 1, title: "File", type: "files" },
  { id: 4, title: "URL", type: "url" },
  { id: 3, title: "Website", type: "website" },
  { id: 2, title: "Video", type: "video" },
  { id: 6, title: "Audio", type: "audio" },
  { id: 5, title: "S3 Bucket", type: "s3Bucket" },
  { id: 7, title: "Add Knowledge with API", type: "api" },
];

export const InstructionsAccordianData1 = [
  {
    id: 1,
    title: "Intro",
    description:
      "Lorem ipsum dolor sit amet consectetur. Facilisis integer dignissim rhoncus risus sed tempor. Sagittis sit aliquet proin egestas vel adipiscing lacus dolor. Sed mauris volutpat turpis dis sed enim morbi tristique. Sit donec pulvinar nisi eget bibendum ultrices pellentesque. Dictumst accumsan nulla neque sit orci ullamcorper bibendum. Diam pharetra rhoncus eget quis ullamcorper vitae at sit aliquam. Mi cras aenean ipsum gravida at. Lobortis tempor risus pellentesque sem tristique id sit tempus. Egestas id elit cras id ullamcorper morbi purus morbi nisl. Varius venenatis tincidunt nisi aliquet arcu sit nisl id ut. Aliquam pellentesque sollicitudin consectetur sed turpis vivamus aliquet. Facilisi bibendum sit dolor in ut aliquam.",
  },
  {
    id: 2,
    title: "Heading",
    description:
      "Lorem ipsum dolor sit amet consectetur. Facilisis integer dignissim rhoncus risus sed tempor. Sagittis sit aliquet proin egestas vel adipiscing lacus dolor. Sed mauris volutpat turpis dis sed enim morbi tristique. Sit donec pulvinar nisi eget bibendum ultrices pellentesque. Dictumst accumsan nulla neque sit orci ullamcorper bibendum. Diam pharetra rhoncus eget quis ullamcorper vitae at sit aliquam. Mi cras aenean ipsum gravida at. Lobortis tempor risus pellentesque sem tristique id sit tempus. Egestas id elit cras id ullamcorper morbi purus morbi nisl. Varius venenatis tincidunt nisi aliquet arcu sit nisl id ut. Aliquam pellentesque sollicitudin consectetur sed turpis vivamus aliquet. Facilisi bibendum sit dolor in ut aliquam.",
  },
  {
    id: 3,
    title: "Heading",
    description:
      "Lorem ipsum dolor sit amet consectetur. Facilisis integer dignissim rhoncus risus sed tempor. Sagittis sit aliquet proin egestas vel adipiscing lacus dolor. Sed mauris volutpat turpis dis sed enim morbi tristique. Sit donec pulvinar nisi eget bibendum ultrices pellentesque. Dictumst accumsan nulla neque sit orci ullamcorper bibendum. Diam pharetra rhoncus eget quis ullamcorper vitae at sit aliquam. Mi cras aenean ipsum gravida at. Lobortis tempor risus pellentesque sem tristique id sit tempus. Egestas id elit cras id ullamcorper morbi purus morbi nisl. Varius venenatis tincidunt nisi aliquet arcu sit nisl id ut. Aliquam pellentesque sollicitudin consectetur sed turpis vivamus aliquet. Facilisi bibendum sit dolor in ut aliquam.",
  },
  {
    id: 4,
    title: "Heading",
    description:
      "Lorem ipsum dolor sit amet consectetur. Facilisis integer dignissim rhoncus risus sed tempor. Sagittis sit aliquet proin egestas vel adipiscing lacus dolor. Sed mauris volutpat turpis dis sed enim morbi tristique. Sit donec pulvinar nisi eget bibendum ultrices pellentesque. Dictumst accumsan nulla neque sit orci ullamcorper bibendum. Diam pharetra rhoncus eget quis ullamcorper vitae at sit aliquam. Mi cras aenean ipsum gravida at. Lobortis tempor risus pellentesque sem tristique id sit tempus. Egestas id elit cras id ullamcorper morbi purus morbi nisl. Varius venenatis tincidunt nisi aliquet arcu sit nisl id ut. Aliquam pellentesque sollicitudin consectetur sed turpis vivamus aliquet. Facilisi bibendum sit dolor in ut aliquam.",
  },
  {
    id: 5,
    title: "Heading",
    description:
      "Lorem ipsum dolor sit amet consectetur. Facilisis integer dignissim rhoncus risus sed tempor. Sagittis sit aliquet proin egestas vel adipiscing lacus dolor. Sed mauris volutpat turpis dis sed enim morbi tristique. Sit donec pulvinar nisi eget bibendum ultrices pellentesque. Dictumst accumsan nulla neque sit orci ullamcorper bibendum. Diam pharetra rhoncus eget quis ullamcorper vitae at sit aliquam. Mi cras aenean ipsum gravida at. Lobortis tempor risus pellentesque sem tristique id sit tempus. Egestas id elit cras id ullamcorper morbi purus morbi nisl. Varius venenatis tincidunt nisi aliquet arcu sit nisl id ut. Aliquam pellentesque sollicitudin consectetur sed turpis vivamus aliquet. Facilisi bibendum sit dolor in ut aliquam.",
  },
  // {
  //   id: 6,
  //   title: "Heading",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur. Facilisis integer dignissim rhoncus risus sed tempor. Sagittis sit aliquet proin egestas vel adipiscing lacus dolor. Sed mauris volutpat turpis dis sed enim morbi tristique. Sit donec pulvinar nisi eget bibendum ultrices pellentesque. Dictumst accumsan nulla neque sit orci ullamcorper bibendum. Diam pharetra rhoncus eget quis ullamcorper vitae at sit aliquam. Mi cras aenean ipsum gravida at. Lobortis tempor risus pellentesque sem tristique id sit tempus. Egestas id elit cras id ullamcorper morbi purus morbi nisl. Varius venenatis tincidunt nisi aliquet arcu sit nisl id ut. Aliquam pellentesque sollicitudin consectetur sed turpis vivamus aliquet. Facilisi bibendum sit dolor in ut aliquam.",
  // },
  // {
  //   id: 7,
  //   title: "Heading",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur. Facilisis integer dignissim rhoncus risus sed tempor. Sagittis sit aliquet proin egestas vel adipiscing lacus dolor. Sed mauris volutpat turpis dis sed enim morbi tristique. Sit donec pulvinar nisi eget bibendum ultrices pellentesque. Dictumst accumsan nulla neque sit orci ullamcorper bibendum. Diam pharetra rhoncus eget quis ullamcorper vitae at sit aliquam. Mi cras aenean ipsum gravida at. Lobortis tempor risus pellentesque sem tristique id sit tempus. Egestas id elit cras id ullamcorper morbi purus morbi nisl. Varius venenatis tincidunt nisi aliquet arcu sit nisl id ut. Aliquam pellentesque sollicitudin consectetur sed turpis vivamus aliquet. Facilisi bibendum sit dolor in ut aliquam.",
  // },
  // {
  //   id: 8,
  //   title: "Heading",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur. Facilisis integer dignissim rhoncus risus sed tempor. Sagittis sit aliquet proin egestas vel adipiscing lacus dolor. Sed mauris volutpat turpis dis sed enim morbi tristique. Sit donec pulvinar nisi eget bibendum ultrices pellentesque. Dictumst accumsan nulla neque sit orci ullamcorper bibendum. Diam pharetra rhoncus eget quis ullamcorper vitae at sit aliquam. Mi cras aenean ipsum gravida at. Lobortis tempor risus pellentesque sem tristique id sit tempus. Egestas id elit cras id ullamcorper morbi purus morbi nisl. Varius venenatis tincidunt nisi aliquet arcu sit nisl id ut. Aliquam pellentesque sollicitudin consectetur sed turpis vivamus aliquet. Facilisi bibendum sit dolor in ut aliquam.",
  // },
  // {
  //   id: 9,
  //   title: "Heading",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur. Facilisis integer dignissim rhoncus risus sed tempor. Sagittis sit aliquet proin egestas vel adipiscing lacus dolor. Sed mauris volutpat turpis dis sed enim morbi tristique. Sit donec pulvinar nisi eget bibendum ultrices pellentesque. Dictumst accumsan nulla neque sit orci ullamcorper bibendum. Diam pharetra rhoncus eget quis ullamcorper vitae at sit aliquam. Mi cras aenean ipsum gravida at. Lobortis tempor risus pellentesque sem tristique id sit tempus. Egestas id elit cras id ullamcorper morbi purus morbi nisl. Varius venenatis tincidunt nisi aliquet arcu sit nisl id ut. Aliquam pellentesque sollicitudin consectetur sed turpis vivamus aliquet. Facilisi bibendum sit dolor in ut aliquam.",
  // },
  // {
  //   id: 10,
  //   title: "Heading",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur. Facilisis integer dignissim rhoncus risus sed tempor. Sagittis sit aliquet proin egestas vel adipiscing lacus dolor. Sed mauris volutpat turpis dis sed enim morbi tristique. Sit donec pulvinar nisi eget bibendum ultrices pellentesque. Dictumst accumsan nulla neque sit orci ullamcorper bibendum. Diam pharetra rhoncus eget quis ullamcorper vitae at sit aliquam. Mi cras aenean ipsum gravida at. Lobortis tempor risus pellentesque sem tristique id sit tempus. Egestas id elit cras id ullamcorper morbi purus morbi nisl. Varius venenatis tincidunt nisi aliquet arcu sit nisl id ut. Aliquam pellentesque sollicitudin consectetur sed turpis vivamus aliquet. Facilisi bibendum sit dolor in ut aliquam.",
  // },
];

export const DataSourceForTable = [
  {
    id: 1,
    sourceType: "Docs",
    name: "Project_Q4_2023.Docs",
  },
  {
    id: 2,
    sourceType: "Docs",
    name: "Project_Q4_2023.Docs",
  },
  {
    id: 3,
    sourceType: "Docs",
    name: "Project_Q4_2023.Docs",
  },
  {
    id: 4,
    sourceType: "Docs",
    name: "Project_Q4_2023.Docs",
  },
  // {
  //   id: 5,
  //   sourceType: "Docs",
  //   name: "Project_Q4_2023.Docs",
  // },
  // {
  //   id: 6,
  //   sourceType: "Docs",
  //   name: "Project_Q4_2023.Docs",
  // },
  // {
  //   id: 7,
  //   sourceType: "Docs",
  //   name: "Project_Q4_2023.Docs",
  // },
  // {
  //   id: 8,
  //   sourceType: "Docs",
  //   name: "Project_Q4_2023.Docs",
  // },
  // {
  //   id: 9,
  //   sourceType: "Docs",
  //   name: "Project_Q4_2023.Docs",
  // },
  // {
  //   id: 10,
  //   sourceType: "Docs",
  //   name: "Project_Q4_2023.Docs",
  // },
];

export const integrateCopilotData = [
  { id: 4, title: "Website", type: "website", active: true },
  { id: 2, title: "ChatGPT" , type: "chatGPT", active: true },
  { id: 1, title: "Microsoft Copilot" , type: "copilot", active: false },
  { id: 3, title: "Shopify" , type: "shopify", active: false },
  { id: 5, title: "API" , type: "api", active: false },
  { id: 6, title: "Copilot Template", type: "copilotTemplate", active: false },
  { id: 7, title: "Python SDK", type: "pythonSDK", active: false },
  { id: 8, title: "Javascript SDK", type: "javascriptSDK", active: false },
  // { id: 4, title: "See the Integration" },
  // { id: 5, title: "Copilot Store" },
  // { id: 6, title: "Python" },
  // { id: 7, title: "Project" },
  // { id: 8, title: "Project" },
  // { id: 9, title: "Project" },
  // { id: 10, title: "Project" },
];

export const chatOptions = [
  { id: 1, title: "Lorem ipsum dolor sit amet consectetur." },
  { id: 2, title: "Lorem ipsum dolor sit amet consectetur." },
  { id: 3, title: "Lorem ipsum dolor sit amet consectetur." },
  { id: 4, title: "Lorem ipsum dolor sit amet consectetur." },
  { id: 5, title: "Lorem ipsum dolor sit amet consectetur." },
];

export const chatData = [
  {
    id: 1,
    type: "aiMessage",
    content: "Hi How can I help you today?",
  },
  {
    id: 2,
    type: "userMessage",
    content: "Hi How can I help you today?",
  },
  {
    id: 3,
    type: "userMessage",
    content: "Hi How can I help you today?",
  },
  {
    id: 4,
    type: "aiMessage",
    content: "Hi How can I help you today?",
  },
  {
    id: 5,
    type: "userMessage",
    content: "Hi How can I help you today?",
  },
  {
    id: 6,
    type: "userMessage",
    content: "Hi How can I help you today?",
  },
  {
    id: 7,
    type: "aiMessage",
    content: "Hi How can I help you today?",
  },
  {
    id: 8,
    type: "userMessage",
    content: "Hi How can I help you today?",
  },
  {
    id: 9,
    type: "userMessage",
    content: "Hi How can I help you today?",
  },
  {
    id: 10,
    type: "userMessage",
    content: "Hi How can I help you today?",
  },
  {
    id: 11,
    type: "aiMessage",
    content: "Hi How can I help you today?",
  },
  {
    id: 12,
    type: "userMessage",
    content: "Hi How can I help you today?",
  },
  // {
  //   id: 13,
  //   type: "aiMessage",
  //   content:"Hi How can I help you today?"
  // },
  // {
  //   id: 14,
  //   type: "userMessage",
  //   content:"Hi How can I help you today?"
  // },
  // {
  //   id: 15,
  //   type: "aiMessage",
  //   content:"Hi How can I help you today?"
  // },
  // {
  //   id: 16,
  //   type: "userMessage",
  //   content:"Hi How can I help you today?"
  // },
  // {
  //   id: 17,
  //   type: "aiMessage",
  //   content:"Hi How can I help you today?"
  // },
  // {
  //   id: 18,
  //   type: "userMessage",
  //   content:"Hi How can I help you today?"
  // },
  // {
  //   id: 19,
  //   type: "aiMessage",
  //   content:"Hi How can I help you today?"
  // },
];

export const HTMLCode = `    <iframe
      title="Embedded Component"
      src = \`${window.location.origin}/CustomChat/:id\`
      sandbox="allow-same-origin allow-scripts"
      style=" 
      height: 600px; 
      border:none; 
      width: 450px; 
      position: fixed; 
      bottom: 0; 
      right: 0; 
      z-index: 999999; "
    />
`;

// export const HTMLCode = `<div>
//     <link rel="stylesheet" href="https://drive.google.com/uc?export=view&id=1JQ-9d4WMaYoOpOyFnEW6d-OfKo4c9X76">
//     <div id="m-search-btn" class="m-active"></div>
//         <div id="m-popup" class="m-show">
//         <iframe src="https://prod.d3nbl0ydl3osud.amplifyapp.com/?webbot_id=76" width="400" height="800" frameborder="0" ></iframe>
//         </div>
// </div>

// <script>

// let button = document.getElementById('m-search-btn');
// let content = document.getElementById('m-popup');

// function togglePopup() {
//   if (content.className === "m-show") {
//     content.className = "";
//     button.className = "";
//   } else {
//     content.className = "m-show";
//     button.className = "m-active";
//   }
// }
// document.addEventListener('keydown', function(event) {
//   // Check if Cmd (Mac) or Ctrl (Windows) key is pressed along with the 'K' key
//   if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
//     togglePopup();
//   }
// });

// button.onclick = togglePopup;
// </script>`;

export const copilotTestChat = [
  {
    id: 1,
    type: "aiMessage",
    content: "Hi How can I help you today?",
  },
  {
    id: 2,
    type: "userMessage",
    content: "Hi How can I help you today?",
  },
  {
    id: 3,
    type: "userMessage",
    content: "Hi How can I help you today?",
  },
  {
    id: 4,
    type: "aiMessage",
    content: "Hi How can I help you today?",
  },
  {
    id: 5,
    type: "userMessage",
    content: "Hi How can I help you today?",
  },
  {
    id: 6,
    type: "userMessage",
    content: "Hi How can I help you today?",
  },
  {
    id: 7,
    type: "aiMessage",
    content: "Hi How can I help you today?",
  },
  {
    id: 8,
    type: "userMessage",
    content: "Hi How can I help you today?",
  },
  {
    id: 9,
    type: "userMessage",
    content: "Hi How can I help you today?",
  },
  {
    id: 10,
    type: "userMessage",
    content: "Hi How can I help you today?",
  },
];

export const CopilotData = [
  { id: 1, title: "Intro" },
  { id: 2, title: "Copilot" },
  { id: 3, title: "Python" },
  { id: 4, title: "Heading" },
  { id: 5, title: "Heading" },
  { id: 6, title: "Heading" },
];

// intro data

export const introData = [
  {
    id: "01",
    title: "Select template",
    description:
      "Choose from our curated collection of pre-designed templates, each tailor-made for specific use cases. Each template is armed with a suite of robust instructions meticulously crafted to suit your chosen application.Prefer to pioneer your own path? Utilize the master template as your canvas, allowing you to shape your copilot's instructions according to your preferences.",
  },
  {
    id: "02",
    title: "Customize with any Knowledge",
    description:
      "Infuse your distinct identity into your copilot or align it seamlessly with your organization’s priorities. Harness the ability to upload diverse data types – from files and links to documents and beyond. This empowers your GenAI copilot to learn from your unique data, adopting your tone and absorbing pertinent background information.",
  },
  {
    id: "03",
    title: "Refine and deploy",
    description:
      "Once your copilot takes shape, fine-tuning is a breeze. Run tests, enrich or modify data sources, and calibrate its instructions effortlessly. Ready for the world to experience your creation? Integration is seamless – whether you wish to embed it within your website (in chatbot, search, and more formats), transform it into a plugin, or directly operate it from your copilot console. Your AI copilot is primed to take flight!",
  },
];

export const dashboardData = [
  { name: "copilot 1", createdAt: "12-09-2021" },
  { name: "copilot 2", createdAt: "12-09-2022" },
  { name: "copilot 3", createdAt: "12-09-2023" },
  { name: "copilot 4", createdAt: "12-09-2024" },
];
