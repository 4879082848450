import React from "react";
import { CopilotInstructionsConfig } from "../../components/organisms/CopilotInstructionsConfig";
import Navbar from "../../components/molecules/NavBar/Navbar";
import { useSelector } from "react-redux";

export const CopilotInstructions = () => {

  return (
    <div className=" bg-[var(--background-color)]">
      <div className="bg-[var(--background-color)] ">
        <Navbar />
        <CopilotInstructionsConfig />
      </div>
    </div>
  );
};
