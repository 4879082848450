import axios from "axios";
import { NODE_NETWORK_URL } from "../utlits/Network";

export const authentication = {
  login: (payload: object) => {
    return axios.post(`${NODE_NETWORK_URL}/api/v1/auth/login`, payload);
  },
  loginWithGoogle: (credential: string) => {
    return axios.post(`${NODE_NETWORK_URL}/api/v1/auth/google-login`, {
      credential,
    });
  },
  signUpwithEmail: (payload: object) => {
    return axios.post(`${NODE_NETWORK_URL}/api/v1/auth/register`, payload);
  },
  verifyOtp: (payload: object) => {
    return axios.post(`${NODE_NETWORK_URL}/api/v1/auth/verify-otp`, payload);
  },
  resendOtp: (email: string) => {
    return axios.post(`${NODE_NETWORK_URL}/api/v1/auth/send-otp`, {
      email,
    });
  },
  createPassword: (password: any, token: any) => {
    const parsetoken = JSON.parse(token);
    return axios.post(
      `${NODE_NETWORK_URL}/api/v1/auth/reset-password`,
      { password },
      { headers: { token: parsetoken } }
    );
  },
  getUser: async () => {
    const token = localStorage.getItem("token");
    if (!token) return new Error("Token not found");
    try {
      const parseToken = JSON.parse(token);
      const response = await axios.get(`${NODE_NETWORK_URL}/api/v1/user`, {
        headers: { token: parseToken },
      });
      return response?.data?.data?.user;
    } catch (error) {
      console.error(error);
      localStorage.clear();
      window.location.href='/'
      return error;
    }
  },
  maigrateUserStatus: async () => {
    // Changes whether the user has been through onboarding flow, in the backend.
    // This is used to update whether to show the onboarding flow or not.
    const token = JSON.parse(localStorage.getItem("token") || "");

    try {
      const response = await axios.post(
        `${NODE_NETWORK_URL}/api/v1/user/maigrate-initialize`,
        {},
        { headers: { token } }
      );
      return response;
    } catch (error) {
      return error;
    }
  },
};
