// MainView.tsx

import React, { useState } from 'react';
import { MainCopilotPage } from '../../../pages/UseCases/Main';
import { DataSourceList } from './KnowledgeUpload';
import { Configuration } from './Configuration';
import { ChatUI } from './ChatUI';
import { DynamicTabs } from '../../atoms/Tabs/DynamicTabs';
import PluginSettings from './Integrations/IntegrateChatGPT';
import { PreviewCopilot } from './Integrations/PreviewCopilot';

/**
 * @description This contains the controller to show which configuration to show
 */

interface PreviewControllerProps {
    preview: string;
    setPreview: React.Dispatch<React.SetStateAction<string>>;
  }

  export const ConfigurationController = ({
    preview,
    setPreview,
  }: PreviewControllerProps) =>  {

switch (preview) {
    case "Task":
        console.log("Task");
        return <DynamicTabs setPreview={setPreview} />;
    case "Config":
        return <Configuration setPreview={setPreview} />;
    case "ChatGPT":
        return <PluginSettings setPreview={setPreview} />;
    case "Website":
        return <PreviewCopilot setPreview={setPreview} />;
    /* case "Knowledge":
        return <DataSourceList setPreview={setPreview} />; */
    default:
        return <ChatUI setPreview={setPreview} />;
    // Add more cases as needed when setup
}
};


