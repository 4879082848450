// PremiumFeatureModal.tsx
import React, { FC } from 'react';
import './PremiumFeature.css';

type PremiumFeatureModalProps = {
    text : string;
    isOpen: boolean;
    onClose: () => void;
};

const PremiumFeatureModal: FC<PremiumFeatureModalProps> = ({ text, isOpen, onClose }) => {
    const handleOuterClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };
    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}  onClick={handleOuterClick}>
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <div className="flex items-center">
                <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#9b59b6"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="cursor-pointer"
>
    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
</svg>
                <p>{text}</p>
                </div>
            </div>
        </div>
    );
};

export default PremiumFeatureModal;
