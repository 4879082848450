import React from "react";
import { Button } from "../../components/atoms/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
// import { Input } from "../../components/atoms/Input/Input";
import {
  FAQLogo,
  getHelpLogo,
  logoutLogo,
  migrateLogo,
} from "../assets";
import { DashboardTable } from "../../components/organisms/DashboardTable";
import { useDispatch } from "react-redux";
import { UseNavigation } from "../../utlits/CustomHooks/Navigation";
import { googleLogout } from "@react-oauth/google";
import { CustomSuccessFrame } from "../../components/atoms/Frames/CustomSuccessFrame";
import { setWebbotId } from "../../Redux/Reducers/webbotSlice";
import { DeletedFrame } from "../../components/atoms/Frames/DeletedFrame";

import { profileLogo } from "../assets";
import { UpgradeDate } from "../../components/organisms/UpgradeDate";
import { authentication } from "../../API/authentication";
import { copilotSetup } from "../../API/copilotSetup";
interface ApiResponse {
  [key: string]: any;
}

export const Dashboard = () => {

  const navigateTo = UseNavigation();
  const dispatch = useDispatch();

  const [email, setEmail] = React.useState("");

  const [profile, setProfile] = React.useState(""); // storing the profile picture link in this state

  const [profileState, setProfileState] = React.useState(false); // state is for showing profile options when clicking on down arrow

  const [copilotsData, setCopilotsData] = React.useState<ApiResponse[]>([]); // storing the copilots data
  const [copilotCount, setCopilotCount] = React.useState<number>(0);
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const [userId, setUserId] = React.useState();
  const [copilotResponse, setCopilotResponse] = React.useState("");

  const [deletedSuccesfully, setDeletedSuccesfully] = React.useState(false);

  const [finish, setFinish] = React.useState<boolean>(false);

  const closePopup = () => {
    setFinish(false);
  };
  const setUserInformationInDashboard = () => {
    authentication
      .getUser()
      .then((response) => {
        setEmail(response?.email);
        setProfile(response?.profilePicture);
        setUserId(response?.maigrateuserInfo?.id);
        getCopilots(response?.maigrateuserInfo?.id);
      })
      .catch((error) => {
        return error;
      });
  }

  // user able to logout when click yes in modal
  const userLogout = () => {
    localStorage.clear();
    googleLogout();
    //navigateTo("");
    window.location.href='/';
  };

  const getCopilots = (userId: any) => {
    copilotSetup.getCopilots(userId)
      .then((response: any) => {
        const sortedCopilots = response.copilotsData.sort((a:any, b:any) => new Date(b.created_date).getTime() - new Date(a.created_date).getTime());
        setCopilotsData(sortedCopilots);
        setCopilotCount(response.activeItemsCount);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  React.useEffect(() => {
    const user = localStorage.getItem("signedUser");
    if (user) {
      setAlertMessage("logged in successfully");
    }
    setTimeout(() => {
      setAlertMessage("");
      localStorage.removeItem("signedUser");
    }, 2000);
    setUserInformationInDashboard();
  }, [copilotResponse]);

  const createCopilot = () => {
    copilotSetup.createCopilot(userId)
      .then((response: any) => {
        setCopilotResponse(response);
        dispatch(setWebbotId(response?.id));
        setTimeout(() => {
          //navigateTo("UseCases");
          navigateTo("Copilot"); // To change the route of creating a new copilot to Configure directly
        }, 1000);
      })
      .catch((error) => {
        return error;
      });
  };

  const deleteCopilot = (id: number) => {
    copilotSetup.deleteCopilot(id).then((response: any) => {
      setDeletedSuccesfully(true);
      getCopilots(userId);
      setTimeout(() => {
        setDeletedSuccesfully(false);
      }, 2000);
    });
  };

  const truncateText = (text: any, maxLength: any) => {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.slice(0, maxLength) + "...";
    }
  };

  return (
    <div className="w-full ">
      {finish && (
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 z-20 flex flex-col w-[fit-content] rounded-[10px]">
          <p className="">{`${email} want to logout?`}</p>
          <div className="flex gap-[10px] my-[20px] items-center justify-center">
            <Button
              size="xsmall"
              bgColor="primary"
              className="md:py-[10px] rounded-[15px] md:px-[20px] md:text-[15px] py-[5px] px-[7px]"
              onClick={() => {
                setFinish(false);
                // navigateTo("Dashboard");
              }}
            >
              <p className="text-[#fff] text-[16px] font-semibold">No</p>
            </Button>
            <Button
              size="xsmall"
              bgColor="primary"
              className="md:py-[10px] rounded-[15px] md:px-[20px] md:text-[15px] py-[5px] px-[7px]"
              onClick={() => {
                userLogout();
              }}
            >
              <p className="text-[#fff] text-[16px] font-semibold">Yes</p>
            </Button>
          </div>
        </div>
      )}
      {/* Add the backdrop element */}
      {finish && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-10"
          onClick={closePopup}
        ></div>
      )}
      {alertMessage && <CustomSuccessFrame message={alertMessage} />}
      {deletedSuccesfully ? (
        <div
          className={`${deletedSuccesfully ? "opacity-100" : "opacity-0"
            } transition-transform duration-1000 ease-in-out`}
        >
          <DeletedFrame type={"WebBot"} />
        </div>
      ) : null}
      <div className="w-full bg-[#f8f8f8] p-3">
        <div className="w-[70%] h-10 flex items-center justify-between m-auto ">
          <div className="w-[35%] flex items-center justify-between">
            <div>
              <img src={migrateLogo} alt="" width="200px" />
            </div>
            {/* <div>
              <button
                className="bg-white font-bold px-[10px] py-[4px] text-[var(--primary)] text-[10px] rounded-xl"
                onClick={createCopilot}
              >
                Create Copilot{" "}
              </button>
            </div> */}
          </div>
          <div className=" flex items-center gap-1 ">
            <UpgradeDate />

            <div>
              <Button
                bgColor="primary"
                size="small"
                className="p-2 font-semibold tracking-background"
                onClick={() => navigateTo("intro")}
              >
                Help ?
              </Button>
            </div>

            <div className=" rounded-[50%] w-10 h-10 ">
              <img src={profile} alt="" className="rounded-[50%]" />
            </div>
            <div className="text-[14px]">{truncateText(email, 15)}</div>
            <div
              onClick={() => setProfileState(!profileState)}
              className="cursor-pointer"
            >
              <FontAwesomeIcon icon={faAngleDown} className="pt-2 ml-1" />
            </div>

            {profileState && (
              <div className="absolute w-[10%] top-[55px] right-[150px] flex flex-col gap-2 bg-white border p-2">
                <div className="flex h-5 text-[14px] gap-2 items-start ">
                  <img
                    src={profileLogo}
                    alt="profile"
                    width="18px"
                    height="5px"
                  />
                  <div>Profile</div>
                </div>
                <div className="flex h-5 text-[14px] gap-2 items-start ">
                  <img
                    src={getHelpLogo}
                    alt="profile"
                    width="18px"
                    height="5px"
                  />
                  <div>Get Help</div>
                </div>
                <div className="flex h-5 text-[14px] gap-2 items-start ">
                  <img src={FAQLogo} alt="profile" width="18px" height="5px" />
                  <div>FAQ</div>
                </div>
                <div
                  className="flex h-5 text-[14px] gap-2 items-start  cursor-pointer"
                  onClick={() => setFinish(true)}
                >
                  <img
                    src={logoutLogo}
                    alt="profile"
                    width="18px"
                    height="5px"
                  />
                  <div>Logout</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className=" w-[70%] m-auto mt-[5vh]">
        <div className=" h-20 w-30 w-[fit-content] leading-7">
          <div className="text-[18px] text-left ">Hello</div>
          <div
            className="text-[20px] "
            style={{ fontFamily: "PoppinsSemiBold" }}
          >
            {email}
          </div>
        </div>
        <div className="border-2">
          <div className="h-50 md:flex block justify-between p-2">
            <div>
              <div className="flex w-[fit-content] gap-3 items-center text-[13px] md:text-left text-center md:ml-0 m-auto">
                <div className="text-[15px] font-semibold">Your Copilots</div>
                <div className="text-[10px] text-[var(--primary)] font-bold">
                  {/* {copilotsData.length} copilots */}
                  {/* {copilotCount} copilots */}
                </div>
              </div>
              {/* <div className="text-[11px] text-[#667085] font-[500]">
                Select CopilotsX
              </div> */}
            </div>
            <div>
              <Button
                bgColor="primary"
                size="xsmall"
                className="p-2 font-semibold tracking-background"
                // onClick={() => navigateTo("UseCases")}
                onClick={createCopilot}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className="mr-1 text-[13px] font-bold"
                />
                Create New Copilot
              </Button>
            </div>
          </div>
          {/* <div className="flex justify-between items-center py-3 p-2">
            <div className="w-[33%]">
              <div className="flex items-center gap-2 rounded-md border border-[#4F4F4F] p-[5px]">
                <img
                  src={searchIcon}
                  alt="search"
                  className="w-[13px] h-[13px] ml-1"
                />
                <input
                  placeholder="Search..."
                  className="w-[100%] text-[#4F4F4F] text-[13px] focus:outline-none focus:border-blue-500 placeholder-[#4F4F4F]  rounded-md "
                />
              </div>
            </div>
            <div>
              <Button
                className="bg-[#f9fafb] py-[6px] px-8 font-semibold tracking-background border"
                size="small"
                rounded="medium"
                color="default"
              >
                View all
              </Button>
            </div>
          </div> */}
          <div>
            <DashboardTable
              copilotsData={copilotsData}
              deleteCopilot={deleteCopilot}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
