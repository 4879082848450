import clsx from "clsx";

export const getPasswordLenValidationColors = (password: string) => {
  return clsx({
    "text-[#1FAA51]": password?.length > 5,
    "text-[var(--primaryLabelColor)]": password === "",
    "text-red-700 font-semibold": !(password?.length > 5) && password !== "",
  });
};

export const getPasswordSpecialCharValidationColors = (password: string) => {
  return clsx({
    "text-[#1FAA51]": password?.match(/[!@#$%^&*?]+/) !== null,
    "text-[var(--primaryLabelColor)]": password === "",
    "text-red-700 font-semibold":
      password?.match(/[!@#$%^&*?]+/) === null && password !== "",
  });
};

export const getPasswordNumValidationColors = (password: string) => {
  return clsx({
    "text-[#1FAA51]": password?.match(/[0-9]/) !== null,
    "text-[var(--primaryLabelColor)]": password === "",
    "text-red-700 font-semibold":
      password?.match(/[0-9]/) === null && password !== "",
  });
};

export const getPasswordUpperCharValidationColors = (password: string) => {
  return clsx({
    "text-[#1FAA51]": password?.match(/[A-Z]/) !== null,
    "text-[var(--primaryLabelColor)]": password === "",
    "text-red-700 font-semibold":
      password?.match(/[A-Z]/) === null && password !== "",
  });
};
