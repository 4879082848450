import React from "react";
import { Button } from "../atoms/Button/Button";
import { authentication } from "../../API/authentication";
import { useDispatch } from "react-redux";
import { setUserData } from "../../Redux/Reducers/loginSlice";

export const UpgradeDate = () => {
  const [daysDifference, setDaysDifference] = React.useState<any>(null);
  const dispatch = useDispatch();
  const [trailStatus, setTrailStatus] = React.useState("");

  React.useEffect(() => {
    authentication.getUser().then((response) => {
      dateChanging(response?.maigrateuserInfo?.created_date);
      setTrailStatus(response?.maigrateuserInfo?.status);
    });
  }, []);
  const dateChanging = (createdDate: any) => {
    // Parse the given timestamp into a Date object and add 7 days
    const trialDuration = 7;
    const trialEndDate: any = new Date(createdDate);

    // Add 7 days to givenDate to identify when the trial ends

    trialEndDate.setDate(trialEndDate.getDate() + trialDuration);

    // Get the current date
    const currentDate: any = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = trialEndDate - currentDate;

    // Calculate the number of days
    const days = Math.floor(timeDifference / (1000 * 3600 * 24));

    // Update the state with the number of days plus 7
    setDaysDifference(days);
  };
  return (
    <>
      {trailStatus === "FREE_TRAIL" && (
        <div className="flex  gap-2 mr-2 items-center">
          <div className="text-sm text-[var(--primary)] font-bold">
            {daysDifference < 0 ? 0 : daysDifference} days left
          </div>
          <Button
            bgColor="primary"
            size="small"
            className="p-2 font-semibold tracking-background"
          >
            Upgrade
          </Button>
        </div>
      )}
    </>
  );
};
