import React, { useEffect, useState } from "react";
import { CopilotInstructions } from "../molecules/MainCopilot/CopilotInstructions";
import { DynamicTabs } from "../atoms/Tabs/DynamicTabs";
import { resetSkillData, setSkillData, storeSkillId } from "../../Redux/Reducers/SkillsData";
import { useDispatch, useSelector } from "react-redux";
import { AddedFrame } from "../atoms/Frames/AddedFrame";
import { UpdatedFrame } from "../atoms/Frames/UpdatedFrame";
import { copilotProcess } from "../../API/copilotProcess";

export const CopilotInstructionsConfig = () => {
  const dispatch = useDispatch();
  // const webbot_id: any = useSelector((state: any) => state?.webbot?.id);
  const addedSuccesfully = useSelector(
    (state: any) => state?.data?.addedSuccesfully
  );

  const updatedSuccesfully = useSelector(
    (state: any) => state?.data?.updatedSuccesfully
  );

  const [createCopilot, setCreateCopilot] = useState(false);

  useEffect(()=>{
    //clearing prev data
    dispatch(resetSkillData())
  },[])
  //----------------------------TO GET PARTICULAR INSTRUCTION DESCRIPTION------------------------------------

  const handleInstructionData = (id: any) => {
    copilotProcess
      .getInstructionDescription(id)
      .then((response) => {
        let context;
        context = JSON.parse(response?.data?.context);
        dispatch(setSkillData(context));
        dispatch(storeSkillId(id));
      })
      .catch((error) => {
        return error;
      });
  };

  return (
    <div className="lg:w-[80%] m-auto lg:px-[1%] 2xl:w-[80%] p-[10px]">
      {addedSuccesfully ? (
        <div>
          <AddedFrame />
        </div>
      ) : updatedSuccesfully ? (
        <UpdatedFrame />
      ) : null}
      {/* <div className="w-full"><StepIndicator /></div> */}
      <div className="md:flex justify-between w-full">
        <div className="md:w-[28%]">
          <CopilotInstructions
            handleInstructionData={handleInstructionData}
            setCreateCopilot={setCreateCopilot}
            createCopilot={createCopilot}
          />
        </div>
        <div className="md:w-[70%] bg-[var(--primarybackgroundWhite)] rounded-[20px] mt-[20px]">
          <div>
            <DynamicTabs />
          </div>
        </div>
      </div>
    </div>
  );
};
