import React, { useState } from "react";
import { Button } from "../Button/Button";
import { useSelector } from "react-redux";
// import { handleCreateContext } from "../../../API/service";
import { copilotProcess } from "../../../API/copilotProcess";

interface PopupProps {
  onClose: () => void;
}

const Popup: React.FC<PopupProps> = ({ onClose }) => {
  const webbot_id: any = useSelector((state: any) => state?.webbot?.id);

  const [text, setText] = useState("");

  return (
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 z-20 flex flex-col w-[30%] rounded-[10px]">
      {/* Your input boxes and content */}
      <label className="flex text-left pb-[10px] text-[14px] text-[var(--primaryTextColor)]">
        Task Name:
      </label>
      <input
        type="text"
        placeholder="Enter Task Name"
        className="mb-2 border border-gray-500 p-2 rounded-[10px]"
      />
      {/* Close button */}
      <div className="w-full flex justify-between">
        <button
          className="py-[10px] rounded-[10px] px-[10px] text-[80%] text-[red]"
          onClick={onClose}
        >
          Cancel
        </button>
        <Button
          size="xsmall"
          bgColor="primary"
          className="py-[10px] rounded-[10px] px-[10px] text-[80%]"
          onChange={(e: any) => setText(e.target.value)}
          onClick={() => copilotProcess.createTask(webbot_id, text, "Skills")}
        >
          Create
        </Button>
      </div>
    </div>
  );
};

export default Popup;
