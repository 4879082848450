import axios from "axios";
import { NETWORK_URL } from "../utlits/Network";
import { PluginData } from "../utlits/types";

export const copilotSetup = {
  createCopilot: async (user_id: any) => {
    if (!user_id) throw new Error("User ID is missing");
    try {
      const userIdInt = parseInt(user_id);
      if (isNaN(userIdInt)) {
        throw new Error("Invalid user ID");
      }
      const payload = {
        name: "",
        user_id: userIdInt,
        webbot_type: "INDIVIDUAL",
      };
      const response = await axios.post(`${NETWORK_URL}/webbots`, payload);
      return response.data;
    } catch (error) {
      console.error(error);
      throw new Error("Failed to create copilot");
    }
  },
  uploadFilesToDb: async (file: any, webbot_id: any) => {
    if (!file) throw new Error("File is missing");
    if (!webbot_id) throw new Error("Webbot ID is missing");
    const apiURL = `${NETWORK_URL}/files/upload/${webbot_id}`;
    const formData = new FormData();
    formData.append("files[]", file);

    const response = await fetch(apiURL, {
      method: "POST",
      body: formData,
    });

    const responseData = await response.json();
    return responseData.results;
  },
  processSingleFile: (file: any) => {
    if (!file) throw new Error("File is missing");
    return axios.post(`${NETWORK_URL}/context/create`, {
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(file),
    });
  },
  getAllFiles: async (webbot_id: any, setDataSourceForTable: Function) => {
    if(!webbot_id) throw new Error("Webbot ID is missing");
    axios.get(`${NETWORK_URL}/files?webbot_id=${webbot_id}`)
      .then((response) => {
        setDataSourceForTable(response?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  handleDeleteFromAllFiles: async (id: number, setDeleteRes: any, setDeletedSuccesfully: any) => {
    if (!id) throw new Error("File ID is missing");
    return axios.delete(`${NETWORK_URL}/files/${id}`)
      .then((response) => {
        setDeleteRes(response);
        setDeletedSuccesfully(true);
        setTimeout(() => setDeletedSuccesfully(false), 2000);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  getCopilots: async (userId: any) => {
    if (!userId) throw new Error("User ID is missing");
    try {
      const userIdInt = parseInt(userId); 
      // convert userId to integer
      if (isNaN(userIdInt)) {
        throw new Error("Invalid user ID");
      }
      const response = await axios.get(`${NETWORK_URL}/webbots?user_id=${userIdInt}`);
      const activeItemsCount = response?.data?.filter((item: any) => item.active).length;
      return { copilotsData: response?.data, copilotCount: activeItemsCount };
    } catch (error) {
      console.error(error);
      throw new Error("Failed to get copilots");
    }
  },
  deleteCopilot: (id: number) => {
    if (!id) throw new Error("Copilot ID is missing");
    return axios
      .delete(`${NETWORK_URL}/webbots/${id}`)
      .then((response: any) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw new Error("Failed to delete copilot");
      });
  },
  getPluginDetails: async (webbot_id: any): Promise<PluginData> => {
    if (!webbot_id) throw new Error("Webbot ID is missing");
    try {
      const response = await axios.get(`${NETWORK_URL}/webbots/${webbot_id}`);
      const transformedData: PluginData = {
        name: response.data.config.plugin_name,
        description: response.data.config.plugin_description,
        logoURL: response.data.config.logo_url,
        url: `${response.data.api_key}.aiplugin.webbot.chat`
      };
      return transformedData;
    } catch (error: any) {
      return error?.response?.data;
    }
  },
  updatePluginDetails: async (webbot_id: any, pluginData: PluginData) => {
    if (!webbot_id) throw new Error("Webbot ID is missing");
    const url = new URL(pluginData.logoURL!);
    if (url.protocol === "http:" || url.protocol === "https:") {
      if (pluginData.logoURL!.endsWith(".png")) {
        const response = await axios.get(`${NETWORK_URL}/webbots/${webbot_id}`);
        response.data.config.plugin_name = pluginData.name;
        response.data.config.plugin_description = pluginData.description;
        response.data.config.logo_url = pluginData.logoURL;
        await axios.put(`${NETWORK_URL}/webbots/${webbot_id}`, response.data);
      } else {
        throw new Error("Logo needs to be PNG");
      }
    } else {
      throw new Error("Invalid URL protocol");
    }
  },
  UploadWebsite: async (dataByUser: any, webbot_id: any) => {
    if (!dataByUser?.website) throw new Error("Website URL is missing");
    if (!webbot_id) throw new Error("Webbot ID is missing");
    const payload = {
      webbot_id: webbot_id,
      source_url: dataByUser?.website,
      name: dataByUser?.website.split("/").pop(),
      file_type: "website",
    };
    return await axios.post(`${NETWORK_URL}/files`, payload);
  },
  UploadUrl: async (webbot_id: any, dataByUser: any) => {
    if (!dataByUser?.url) throw new Error("URL is missing");
    if (!webbot_id) throw new Error("Webbot ID is missing");
    const payload = {
      webbot_id: webbot_id,
      source_url: dataByUser?.url,
      name: dataByUser?.url.split("/").pop(),
      file_type: "webpage",
    };
    return await axios.post(`${NETWORK_URL}/files`, payload);
  },
  getWebbotDetails: async (webbot_id: any) => {
    if (!webbot_id) throw new Error("Webbot ID is missing");
    try {
      const response = await axios.get(`${NETWORK_URL}/webbots/${webbot_id}`);
      return response;
    } catch (error: any) {
      return error?.response?.data;
    }
  },

  updateWebbot: async (webbot_id: any, payload: any) => {
    if (!webbot_id) throw new Error("Webbot ID is missing");
    try {
      const response = await axios.put(`${NETWORK_URL}/webbots/${webbot_id}`, payload);
      return response;
    } catch (error: any) {
      return error?.response?.data;
    }
  },
};
  
