import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface Props {
    onClose?: () => void,
}

const ChatInfoPopup = ({ onClose }: Props) => {
    return (
        <div className="h-[100vh] overflow-auto w-[100vw] fixed min-w-[300px] top-0 left-0 bg-[#0E0E0E73] z-[999] grid place-content-center">
            <div className="max-w-[550px] mb-3 w-[98%] md:w-max min-w-[300px] m-auto min-h-[100px]  bg-white py-6 px-6 relative">
                {/* <button onClick={onClose} className="btn absolute top-4 right-6">
                    <FontAwesomeIcon className="text-[grey] text-lg" icon={faTimes} />
                </button> */}
                <p className="text-xl text-left">Chat Information</p>
                <div className="grid grid-cols-4 grid-h gap-x-2 mt-5">
                    <>
                        <div className="col-span-1 md:col-span-1 py-2 pr-2 border-r-2 border-r-[#eeeeee] text-left">
                            Copilot Name
                        </div>
                        <div className="col-span-3 py-2 pl-2 text-left">
                            Jaikar Sakhamuri Copilot  
                            {/* TODO : Change name*/}
                        </div>

                    </>
                    <>
                        <div className="col-span-1 py-2 pr-2 border-r-2 border-r-[#eeeeee] text-left">
                            User
                        </div>
                        <div className="col-span-3 py-2 pl-2 text-left">
                            Jaikar Sakhamuri
                            {/* TODO : Change name*/}
                        </div>
                    </>
                    <>
                        <div className="col-span-1 py-2 pr-2 border-r-2 border-r-[#eeeeee] text-left">
                            Session Date
                        </div>
                        <div className="col-span-3 py-2 pl-2 text-left">
                            October 8th, 2023
                            {/* TODO : Change date*/}
                        </div>
                    </>
                    <>
                        <div className="col-span-1 py-2 pr-2 border-r-2 border-r-[#eeeeee] text-left break-words">
                            Interaction
                        </div>
                        <div className="col-span-3 py-2 pl-2 text-left">
                            35 messages / 25 minutes
                            {/* TODO : Change Information*/}
                        </div>
                    </>
                    <>
                        <div className="col-span-1 py-2 pr-2 border-r-2 border-r-[#eeeeee] text-left">
                            CSAT Score
                        </div>
                        <div className="col-span-3 py-2 pl-2 text-left">
                            6/10
                            {/* TODO : Change info*/}
                        </div>
                    </>
                    <>
                        <div className="col-span-1 py-2 pr-2 border-r-2 border-r-[#eeeeee] text-left">
                            API
                        </div>
                        <div className="col-span-3 py-2 pl-2 text-left">
                            API or any other information if needed
                        </div>
                    </>
                </div>
                <div className="mt-4">
                    <button onClick={onClose} className="ml-auto block text-white px-4 rounded-md py-2 btn bg-[#0071E3]">Close</button>
                </div>
            </div>
        </div>
    )
}

export default ChatInfoPopup;