import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "../../atoms/Button/Button";
import { copilotProcess } from "../../../API/copilotProcess";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ControllerProps {
    setPreview: React.Dispatch<React.SetStateAction<string>>;
  }

export const Configuration =  ({ setPreview }: ControllerProps) => {
  const webbot_id = useSelector((state :any) => state.webbot.id);
  const [initialMessage, setInitialMessage] = useState<string>('How can I assist you?');
  const [suggestionQuestions, setSuggestionQuestions] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const initialMessage = await copilotProcess.getInitialMessage(webbot_id);
        const chatSuggestions = await copilotProcess.getChatSuggestions(webbot_id);
        setInitialMessage(initialMessage);
        setSuggestionQuestions(chatSuggestions);
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchData();
  }, [webbot_id]);

  const handleGreetingChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setInitialMessage(event.target.value);

  };

  const handleQuestionChange = (index: number, value: string) => {
    const updatedQuestions = suggestionQuestions.map((question, i) =>
      i === index ? value : question
    );
    setSuggestionQuestions(updatedQuestions);

  };


  const updateInfo = async () => {
    try {
      await copilotProcess.setConfig(webbot_id, initialMessage, suggestionQuestions);
    } catch (error) {
      console.error('Error updating initial data:', error);
    }
  }

  return (
    <div className="flex justify-center items-center p-5">
    <div className="w-full rounded-[5px] ">
      <div className="flex item-center justify-between overflow-x-auto max-h-[43px]">
            <h2 className="text-xl font-semibold">Configuration</h2>
            <Button
              size="xsmall"
              bgColor="primary"
              className="py-1 rounded-lg px-2.5 text-sm"
              onClick={() => setPreview("")}
              >
                X
              </Button>
          </div>
          <div className="mt-4">
            <label htmlFor="initialMessage" className="block mb-2 text-sm font-medium">Initial Greeting Text</label>
            <input
              id="initialMessage"
              type="text"
              value={initialMessage}
              onChange={handleGreetingChange}
              className="border border-gray-300 rounded p-2 w-full mb-4"
            />
            <div>
              <label className="block mb-2 text-sm font-medium">Initial Suggestion Questions</label>
              {suggestionQuestions.map((question, index) => (
                <input
                  key={index}
                  type="text"
                  value={question}
                  onChange={(e) => handleQuestionChange(index, e.target.value)}
                  className="border border-gray-300 rounded p-2 w-full mb-2"
                />
              ))}
            </div>
            <div className="flex items-center justify-center pt-[1%]">
            <Button
              size="xsmall"
              bgColor="primary"
              className="mt-4 py-1 rounded-lg px-2.5 text-sm"
              onClick={updateInfo}
            >
              Update
            </Button>
            </div>
          </div>
        </div>
    </div>
  );
};
