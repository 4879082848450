import React from "react";
import { migrateLogo } from "../../../pages/assets";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { UseNavigation } from "../../../utlits/CustomHooks/Navigation";

export const AuthNav = () => {
  const webbot_id: any = useSelector((state: any) => state?.webbot?.id);
  const location = useLocation();
  const navigateTo = UseNavigation();
  const routeName = location.pathname;
  const ifRoute =
    routeName === "/login" ||
    routeName === "/forgot_password" ||
    routeName === "/login_password";
  return (
    <div className=" lg:w-[70%] md:w-[90%] w-[fit-content] m-auto py-8 ">
      <div className="flex justify-between items-center ">
        <img
          src={migrateLogo}
          alt="maigrate logo"
          width={"200px"}
          // className="m-auto"
        />
        <p className="md:block hidden md:text-[20px] lg:text-[16px] text-[15px]">
          {ifRoute ? "Don't have an account ?" : "Do you have an account?"}{" "}
          {ifRoute ? (
            <span
              className="cursor-pointer font-bold "
              onClick={() => navigateTo("signup")}
            >
              Signup
            </span>
          ) : (
            <span
              className="cursor-pointer font-bold"
              onClick={() => navigateTo("login")}
            >
              Login
            </span>
          )}
        </p>
      </div>
    </div>
  );
};
