import axios from "axios";
axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401 ) {
        localStorage.clear()
        if(window.location.pathname!=='/'){
            window.location.href='/'
        }
        
      }
      return Promise.reject(error);
    }
  );