import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { financeLogo, upload, uploadLogo } from "../../../pages/assets";
import { useLocation } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import { Button } from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { toogleAddedSuccesfully } from "../../../Redux/Reducers/SkillsData";
// import { UploadUrl, UploadWebsite } from "../../../API/service";
import { copilotSetup } from "../../../API/copilotSetup";

interface UserData {
  video: string;
  url: string;
  website: string;
  s3Bucket: string;
  gitRepo: string;
}

interface AccordianProps {
  title: string;
  content: Array<Object>;
  id: number;
  onClick: () => void;
  isOpen: boolean;
  setClicked: React.Dispatch<React.SetStateAction<boolean>>;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setDataByUser: React.Dispatch<React.SetStateAction<UserData>>;
  dataByUser: UserData;
  files: File[];
  type: string;
  progress: number;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  setUrlResponse: any;
  onDivClick?: () => void;
}

export const Accordion = ({
  title,
  content,
  id,
  onClick,
  isOpen,
  setClicked,
  setFiles,
  files,
  type,
  dataByUser,
  setDataByUser,
  progress,
  setProgress,
  setUrlResponse,
  onDivClick,
}: AccordianProps) => {
  const webbot_id: any = useSelector((state: any) => state?.webbot?.id);
  const dispatch = useDispatch();
  const location = useLocation();
  const routeName = location.pathname;
  const [contentId, setContentId] = useState(1);
  const [isValidUrl, setIsValidUrl] = useState(true); // Initially assume it's valid
  const [uploading, setUploading] = useState(false);
  const [uploadedCount,setUploadedCount]=useState(0);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  //------------------------FUNCTION TO UPLOAD WEBSITE URL----------------------------------------

  const handleUploadWebsite = () => {
    setUploading(true);
    copilotSetup
      .UploadWebsite(webbot_id, dataByUser)
      .then((response) => {
        setIsValidUrl(true);
        setUrlResponse(response);
        setUploading(false);
        dispatch(toogleAddedSuccesfully());
        setTimeout(() => {
          dispatch(toogleAddedSuccesfully());
        }, 2000);
      })
      .catch((error) => {
        setUploading(false);
      });
  };

  //------------------------FUNCTION TO UPLOAD URL----------------------------------------

  const handleUploadUrl = () => {
    setUploading(true);
    copilotSetup
      .UploadUrl(webbot_id, dataByUser)
      .then((response) => {
        setIsValidUrl(true);
        setUrlResponse(response);
        setUploading(false);
        dispatch(toogleAddedSuccesfully());
        setDataByUser(prev=>({...prev,url:''}))
        setTimeout(() => {
          dispatch(toogleAddedSuccesfully());
        }, 2000);
      })
      .catch((error) => {
        setUploading(false);
      });
  };

  //-----------------------------TO UPLOAD FILES-----------------------------------

  const handleFileUpload = (event: any) => {
    const Uploadedfiles: any = Array.from(event.target.files || []);
    setProgress(0)
    setFiles(prev=>[...prev,...Uploadedfiles]);
  };

  //-----------------------------TO DRAG FILES-----------------------------------

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  //-----------------------------TO DROP FILES-----------------------------------

  const handleDrop = (event: any) => {
    event.preventDefault();
    const newFiles = [...event.dataTransfer.files];
    setProgress(0)
    setFiles(prev=>[...prev,...newFiles]);
  };

  //-----------------------------TO SET DATA TO STATE -----------------------------------

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setDataByUser((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //-----------------------------TO DISPLAY PROGRESS BAR-----------------------------------

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress < 100 && isOpen && files.length > 0) {
        const randomIncrement = Math.floor(Math.random() * 10) + 2;
        const newProgress = progress + randomIncrement;

        const limitedProgress = newProgress <= 100 ? newProgress : 100;

        setProgress(limitedProgress);
      } else {
        clearInterval(interval);
        setUploadedCount(files.length);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [progress, isOpen, files]);

  const exceptFiles = title === "File" || title === "URL";

  return (
    <div
      className={`w-[100%] items-center ${
        exceptFiles ? "cursor-pointer" : "opacity-[0.5]"
      }`}
    >
      {uploading && (
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 z-20 flex flex-col w-[fit-content] rounded-[10px]">
          <div className="flex gap-[10px] my-[20px] items-center justify-center font-bold">
            It will require sometime to reflect after uploading
          </div>
        </div>
      )}
      {/* Add the backdrop element */}
      {uploading && (
        <div className="fixed inset-0 bg-black opacity-50 z-10"></div>
      )}
      <div
        className={`flex items-center justify-between w-full text-left font-sm text-[#ffff]  ${
          isOpen ? "bg-[var(--primary)] py-2 rounded-md" : ""
        }`}
        onClick={exceptFiles ? onClick : onDivClick}
      >
        <div className="flex items-center gap-[20px] p-[1%]">
          {routeName === "/UseCases" ? (
            <div className="bg-[var(--primary)] rounded-[50%] p-[7px]">
              <img
                src={financeLogo}
                alt="finance"
                className="w-[20px] h-[20px]"
              />
            </div>
          ) : null}

          <p
            className={` font-bold  flex items-center ${
              isOpen ? "text-white" : "text-[var(--primaryTextColor)]"
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#9b59b6"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="cursor-pointer"
              visibility={exceptFiles ? "hidden" : "visible"}
            >
              <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
              <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
            </svg>
            {title}
          </p>
        </div>

        <FontAwesomeIcon
          icon={faChevronUp}
          className={` mr-[1%] transform ${
            isOpen ? "rotate-0 text-white" : "rotate-180"
          } transition-transform duration-600 cursor-pointer text-[black]`}
          onClick={onClick}
        />
      </div>
      <div
        className={`transition-max-height h-[200px] transition duration-400 overflow-y-auto .horizantal-scrollbar ease-out ${
          isOpen && routeName === "/UsesCases" ? "max-h-full" : "max-h-0"
        } ${
          isOpen && routeName === "/UseCasesDataSource"
            ? "max-h-[150px]"
            : "max-h-0"
        } ${
          isOpen ? "opacity-100 h-auto transition-all 0.4s" : "opacity-0 h-0"
        }overflow-hidden`}
        style={{
          transition: "linear 0.4s",
          height: isOpen ? 200 : 40,
          borderRadius: "8px",
          overflow: "auto",
          margin: "2px",
        }}
      >
        {isOpen && routeName === "/UseCases" ? (
          content.map((e: any) => {
            return (
              <div
                key={e.id}
                onClick={() => {
                  setContentId(e.id);
                  setClicked(true);
                }}
                className="mt-[1%] justify-items-start text-left items-start cursor-pointer"
              >
                <div
                  className={`${
                    contentId === e.id
                      ? "bg-[var(--primary)] px-[5%] py-[1%] rounded-[10px]"
                      : "px-[5%] py-[1%]"
                  }`}
                >
                  <p
                    className={` ${
                      contentId === e.id
                        ? "text-[#fff]"
                        : "text-[var(--primaryTextColor)]"
                    }`}
                    style={{ fontFamily: "poppins" }}
                  >
                    {e.contentTitle}
                  </p>
                  <p
                    className={` ${
                      contentId === e.id
                        ? "text-[#fff]"
                        : "text-[var(--primaryTextColor)]"
                    } text-[80%]`}
                  >
                    {e.contentDescription}
                  </p>
                </div>
              </div>
            );
          })
        ) : isOpen && routeName === "/UseCasesDataSource" ? (
          <>
            {type === "files" && (
              <div className="w-full">
                <div
                  className="flex flex-col w-full border border-[#495a64] my-[1%] rounded-[10px] items-center justify-center p-[10px] py-[2%] cursor-[pointer]"
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  onClick={() => {
                    fileInputRef.current?.click();
                    setClicked(true);
                  }}
                >
                  <div className="bg-[lightGrey] rounded-[50%] items-center justify-center">
                    <img
                      src={uploadLogo}
                      alt="upload"
                      className="w-[35px] h-[30px] mb-[5px] "
                    />
                  </div>
                  <div className="justify-center">
                    <p className="text-[var(--primaryTextColor)] text-[75%] font-semibold">
                      Drag & Drop or{" "}
                      <span className="text-[var(--primary)]">Choose file</span>{" "}
                      to upload{" "}
                    </p>
                    <p className="text-[#495a64] text-[65%] font-normal self-center">
                      Limit 200MB per file
                    </p>
                    <input
                      type="file"
                      multiple
                      className="hidden"
                      ref={fileInputRef}
                      onChange={handleFileUpload}
                    />
                  </div>
                </div>
                {files.length > 0 && (
                  <div className="w-full px-[3%] pb-[4%] pt-[3%] bg-[#e2e2e2] rounded-[10px]  ">
                    <div className="w-full flex justify-between pb-[5px] ">
                      <p className="flex text-[var(--secondaryTextColor)] text-[70%] ">
                        {uploadedCount}/{files?.length}
                      </p>
                      <p className="flex text-[var(--secondaryTextColor)] text-[70%] ">
                        {progress}%
                      </p>
                    </div>
                   {progress?
                   <ProgressBar
                   completed={progress}
                   bgColor="#1daa51"
                   height="4px"
                   width="100%"
                   labelAlignment="center"
                   labelColor="#000000"
                   isLabelVisible={false}
                 />:null
                   } 
                  </div>
                )}
              </div>
            )}
            {type === "website" && (
              <div className="w-full pt-[20px] flex flex-col items-center">
                <input
                  type="text"
                  name="website"
                  value={dataByUser?.website}
                  className="w-full p-[10px] focus:outline-none bg-transparent border border-[#aba9a9] rounded-[10px] text-[var(--primaryTextColor)] placeholder-[var(--primaryLabelColor)] text-[14px]"
                  placeholder="Paste Website URL here"
                  onChange={(e: any) => handleInputChange(e)}
                />
                {!isValidUrl ? (
                  <p className="text-red-500 text-[10px]">
                    Invalid URL. Please enter a valid website URL.
                  </p>
                ) : null}

                <Button
                  size="xsmall"
                  bgColor="primary"
                  className="lg:py-[1.5%] rounded-[12px] px-[4%] lg:text-[15px] py-[5px] mt-[10px] gap-[10px]"
                  // onClick={() => navigateTo("UseCaseData")}
                  onClick={handleUploadWebsite}
                >
                  <img
                    src={upload}
                    alt="upload"
                    className="w-[20px] h-[20px]"
                  />
                  {uploading ? "uploading..." : "Upload"}
                </Button>
              </div>
            )}
            {type === "url" && (
              <div className="w-full pt-[20px] flex flex-col items-center">
                <input
                  type="text"
                  name="url"
                  value={dataByUser?.url}
                  className="w-full p-[10px] focus:outline-none bg-transparent border border-[#aba9a9] rounded-[10px] text-[var(--primaryTextColor)] placeholder-[var(--primaryLabelColor)] text-[14px]"
                  placeholder="Paste URL here"
                  onChange={(e: any) => handleInputChange(e)}
                />
                {!isValidUrl ? (
                  <p className="text-red-500 text-[10px]">
                    Invalid URL. Please enter a valid URL.
                  </p>
                ) : null}
                <Button
                  size="xsmall"
                  bgColor="primary"
                  className="lg:py-[1.5%] rounded-[12px] px-[4%] lg:text-[15px] py-[5px] mt-[10px] gap-[10px]"
                  onClick={handleUploadUrl}
                >
                  <img
                    src={upload}
                    alt="upload"
                    className="w-[20px] h-[20px]"
                  />
                  {uploading ? "uploading..." : "Upload"}
                </Button>
              </div>
            )}
            {type === "video" && (
              <div className="w-full pt-[20px] flex flex-col items-center relative">
                <input
                  type="text"
                  name="video"
                  value={dataByUser?.video}
                  className="w-full p-[10px] focus:outline-none bg-transparent border border-[#aba9a9] rounded-[10px] text-[var(--primaryTextColor)] placeholder-[var(--primaryLabelColor)] text-[14px]"
                  placeholder="Paste Video URL here"
                  onChange={(e: any) => handleInputChange(e)}
                />
                <Button
                  size="xsmall"
                  bgColor="primary"
                  className="lg:py-[1.5%] rounded-[12px] px-[4%] lg:text-[15px] py-[5px] mt-[10px] gap-[10px]"
                  // onClick={() => console.log("clicked")}
                >
                  <img
                    src={upload}
                    alt="upload"
                    className="w-[20px] h-[20px]"
                  />
                  Upload
                </Button>
              </div>
            )}
            {type === "s3Bucket" && <div>Coming Soon...</div>}
            {type === "gitRepo" && <div>Coming Soon...</div>}
          </>
        ) : null}
      </div>
    </div>
  );
};
