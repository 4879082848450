import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { UseNavigation } from '../../../utlits/CustomHooks/Navigation';
import styles from './StepIndicator.module.css'; // Assuming CSS modules
import { infoLogo } from '../../../pages/assets';

// interface StepIndicatorProps {}

const Step = ({ step, isActive, navigateTo }: { step: number, isActive : boolean, navigateTo: Function }) => {
  const stepCircleClass = isActive
    ? 'bg-[var(--primary)]'
    : 'bg-[var(--infoBackground)]';

  const stepTextClass = isActive
    ? 'text-[var(--primary)]'
    : 'text-gray-700';


  const navigateToLocation = (step: number) => {
    switch (step) {
      case 1:
        return 'UseCases';
      case 2:
        return 'UseCasesDataSource';
      case 3:
        return 'UseCaseData';
      default:
        return 'UseCaseData';
    }
  }

  return (
    <div className="flex justify-center items-center" onClick={() => navigateTo(navigateToLocation(step))}>
      <div className="w-full m-1 flex items-center justify-center">
        <div className={`rounded-full p-2 ${stepCircleClass}`}>
          <img src={infoLogo} alt="Info" className="md:w-6 md:h-6 w-5 h-5" />
        </div>
        <p className={`text-sm md:pl-4 md:font-bold ${stepTextClass}`}>
          Step {step}
        </p>
      </div>
    </div>
  );
};

export const StepIndicator = () => {
  const location = useLocation();
  const routeName = location.pathname;
  const navigateTo = UseNavigation();

  const isStepActive = (step: number) => {
    interface Routes {
      [key: number]: string[];
    }

    const routes: Routes = {
      1: ['/UseCases', '/UseCasesDataSource', '/UseCaseData'],
      2: ['/UseCasesDataSource', '/UseCaseData', ],
      3: ['/UseCaseData',]
    };
    return routes[step].includes(routeName);
  };

  const isBarActive = (step : number) => {
    if (step === 1) return isStepActive(2) || isStepActive(3); // Activate the first bar if on step 2 or 3
    if (step === 2) return isStepActive(3); // Activate the second bar only if on step 3
    return false;
  };

  return (
    <div className="w-full">
      <div className="flex justify-center items-center">
        {[1, 2, 3].map(step => (

<React.Fragment key={step}>
<Step 
  step={step} 
  isActive={isStepActive(step)}
  navigateTo={navigateTo} 
/>
{step < 3 && (
  <div className={`h-0.5 flex-grow ${isBarActive(step) ? 'bg-[var(--primary)]' : 'bg-[var(--primaryInputbackgroundWhite)]'}`} />
)}
</React.Fragment>
        ))}
      </div>
    </div>
  );
};
