import React, { useState } from "react";
import { Button } from "../Button/Button";

interface PopupProps {
  onClose: () => void;
  onConfirm:()=>void;
  title:string,
}

const DeletePopup: React.FC<PopupProps> = ({ onClose,onConfirm,title}) => {
  return (
    <div className="w-full h-[100vh] fixed top-0 left-0 z-50  bg-[#0000007b]">
        
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 z-20 flex flex-col w-[35%] rounded-[10px]">
      {/* Your input boxes and content */}
      <label className="flex  text-lg text-bold ml-2 mb-5 text-left pb-[10px] text-[14px] text-[var(--primaryTextColor)]">
        {title}
      </label>
      {/* Close button */}
      <div className="w-full flex justify-between">
        <button
          className="py-[10px] rounded-[10px] px-[10px] text-[80%] text-[red]"
          onClick={onClose}
        >
          Cancel
        </button>
        <Button
          size="xsmall"
          bgColor="primary"
          className="py-[10px] rounded-[10px] px-[10px] text-[80%]"
          onClick={onConfirm}
        >
          Delete
        </Button>
      </div>
    </div>
    </div>
  );
};

export default DeletePopup;
