import React, { useState } from "react";
// import { AddedLogo, DeletedLogo } from "../../../pages/assets";
import { AddedLogo, DeletedLogo } from "../../../pages/assets";
import { motion } from "framer-motion";

export const CustomFailureFrame = ({ message }: any) => {
  const [x, setX] = useState(-100);

  setTimeout(() => {
    setX(200);
  }, 1900);

  return (
    <motion.div
      className="w-[25%] py-[15px] rounded-[20px] flex gap-[10px] items-center shadow-md bg-[var(--primary)] px-[20px] right-0 absolute top-10 box text-white"
      animate={{ x }}
      transition={{ type: "spring" }}
    >
      {" "}
      <img src={DeletedLogo} alt="Added" className="w-[30px] h-[30px]" />
      {message}
    </motion.div>
  );
};
