import React, { memo } from "react";
import { useSelector } from "react-redux";

export type TooltipProps = {
  children: React.ReactNode;
  text: string;
};

const Tooltip: React.FC<TooltipProps> = memo((props) => {
  const webbot_id: any = useSelector((state: any) => state?.webbot?.id);

  //   <span className="relative group">
  //   {props.children}

  //   <span className="absolute left-0 whitespace-nowrap transform translate-y-2 -translate-x-1/2 bg-black text-white px-2 py-1 rounded opacity-0 transition group-hover:opacity-100 text-xs">
  //     {props.text}
  //     {/* <span className="absolute bottom-2 left-0 w-2 h-2 bg-black transform rotate-45 -translate-x-1/2 -translate-y-1/2"></span> */}
  //     <span className="absolute top-0 left-0 w-3 h-3 bg-black -translate-x-1/2 -translate-y-1/2 transform rotate-45"></span>
  //   </span>
  // </span>
  return (
    <span className="group relative">
      {props.children}
      <span className="pointer-events-none absolute -top-10 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-black px-2 py-1 text-white opacity-0 transition before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-black before:content-[''] group-hover:opacity-100">
        {props.text}
      </span>
    </span>
  );
});

// Tooltip.displayName = "Tooltip";

export default Tooltip;
