import React, { useState } from "react";
// import { AddedLogo, UpdatedLogo } from "../../../pages/assets";
import { AddedLogo } from "../../../pages/assets";
import { motion } from "framer-motion";

export const UpdatedFrame = () => {
  const [x, setX] = useState(-100);
  setTimeout(() => {
    setX(200);
  }, 1900);

  return (
    <motion.div
      className="w-[25%] py-[15px] rounded-[20px] flex gap-[10px] items-center shadow-md bg-[#fff] px-[20px] right-0 absolute top-10 box"
      animate={{ x }}
      transition={{ type: "spring" }}
    >
      <img src={AddedLogo} alt="Added" className="w-[30px] h-[30px]" />{" "}
      Succesfully Updated
    </motion.div>
  );
};
