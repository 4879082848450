import React, { useState } from "react";

import { ConfigurationController } from "../molecules/MainCopilot/Controller";
import { DeletedFrame } from "../atoms/Frames/DeletedFrame";
import { CopilotInstructions } from "../molecules/MainCopilot/CopilotInstructions";
import { DataSourceSidePanel } from "../molecules/MainCopilot/DataSourceSidePanel";
import { AddedFrame } from "../atoms/Frames/AddedFrame";
import { UpdatedFrame } from "../atoms/Frames/UpdatedFrame";
import { ConfigSidePanel } from "../molecules/MainCopilot/ConfigurationSidePanel";
import { useDispatch, useSelector } from "react-redux";
import { copilotProcess } from "../../API/copilotProcess";
import { setSkillData, storeSkillId } from "../../Redux/Reducers/SkillsData";
import { IntegrationSidePanel } from "../molecules/MainCopilot/Integrations/IntegrationSidePanel";


/**
 * This is where everything happens
 * Contains all the components in /molecules/Configure and /molecules/UseCasesStep3
 * @returns 
 */

export const MainCopilot = () => {
  const dispatch = useDispatch();

  const [preview, setPreview] = useState("");

  const [deletedSuccesfully, setDeletedSuccesfully] = useState(false);
  const addedSuccesfully = useSelector(
    (state: any) => state?.data?.addedSuccesfully
  );

  const updatedSuccesfully = useSelector(
    (state: any) => state?.data?.updatedSuccesfully
  );

  const handleInstructionData = (id: any) => {
    copilotProcess
      .getInstructionDescription(id)
      .then((response) => {
        let context;
        context = JSON.parse(response?.data?.context);
        dispatch(setSkillData(context));
        dispatch(storeSkillId(id));
      })
      .catch((error) => {
        return error;
      });
  };
  const [createCopilot, setCreateCopilot] = useState(false);
  

  const [layout, setLayout] = useState(false); // to change the layout of the copilot component


/*   const handlelayout = () => {
    setLayout(!layout);
  }; */

 

  return (
    <div className="lg:w-[100%] m-auto lg:px-[1%] 2xl:w-[100%] p-[10px]">
      {deletedSuccesfully ? (
        <div
          className={`${deletedSuccesfully ? "opacity-100" : "opacity-0"
            } transition-transform duration-1000 ease-in-out`}
        >
          <DeletedFrame type={"File"} />
        </div>
      ) : null}
      {addedSuccesfully ? (
        <div>
          <AddedFrame />
        </div>
      ) : updatedSuccesfully ? (
        <UpdatedFrame />
      ) : null}
      <div className="md:flex justify-between w-full">
        <div className="block flex-grow md:max-w-[40%]  max-h-[89vh] overflow-auto horizantal-scrollbar">
        <CopilotInstructions
            handleInstructionData={handleInstructionData}
            setCreateCopilot={setCreateCopilot}
            createCopilot={createCopilot}
            setPreview={setPreview}
          />
          <DataSourceSidePanel setDeletedSuccesfully={setDeletedSuccesfully} />
          <IntegrationSidePanel preview={preview} setPreview={setPreview} />

          
        </div>
        <div className="md:w-[64%]">
          <div className="md:w-[full] bg-[var(--primarybackgroundWhite)] rounded-[20px] mt-[20px] ">
              <ConfigurationController preview={preview} setPreview={setPreview} />
          </div>
        </div>
      </div>
    </div>
  );
};
