import React, { useEffect, useState } from 'react';
import { EditName } from "../../atoms/EditName/EditName";
import Tooltip from "../../atoms/Tooltip/ToolTip";
import { RefreshLogo, ShareLogo,  editIcon } from "../../../pages/assets";
import { useDispatch, useSelector } from 'react-redux';
import { UseNavigation } from '../../../utlits/CustomHooks/Navigation';
import { copilotProcess } from '../../../API/copilotProcess';
import { setItem,getItem } from "../../../utlits/localStorage";
import { PostChatQuestionAndAnswer } from "../../../API/postChatQuestionAndAnswer";
import { toggleMessageResponse } from '../../../Redux/Reducers/SkillsData';
import { Copilot } from '../UseCasesStep3/Copilot';
import { Button } from '../../atoms/Button/Button';
interface ControllerProps {
  setPreview: React.Dispatch<React.SetStateAction<string>>;
}

export const ChatUI =  ({ setPreview }: ControllerProps) => {

    const dispatch = useDispatch();
    const [textInMessageBox, setTextInMessageBox] = useState('');
    const [temporaryText, setTemporaryText] = useState('');
    const [loading, setLoading] = useState(false);
    const webbot_id: any = useSelector((state: any) => state?.webbot?.id);
    const skillId: any = useSelector((state: any) => state?.data?.skillId);
    const navigateTo = UseNavigation();
    const [chatSuggestions, setChatSuggestions] = useState([]);
    const [questionAndAnswers, setQuestionAndAnswers] = useState([]);
    const [showTooltip, setShowTooltip] = useState(false);
    const [conversationId,setConversationId] = useState(null)
    const messageResponse = useSelector(
        (state: any) => state?.data?.messageResponse
      );

    useEffect(() => {
        if (webbot_id&&conversationId===null) {
            copilotProcess.getChatSuggestions(webbot_id, setChatSuggestions);
            generateconversationIdAndFetchData()
        } 
      }, [webbot_id,conversationId]);
    
      const generateconversationIdAndFetchData = () => {
        copilotProcess
          .createConversationId(webbot_id)
          .then((response) => {
            setItem("conversationId", response);
            setConversationId(response)
            if(messageResponse){dispatch(toggleMessageResponse());}
            setQuestionAndAnswers([]);
          })
          .catch((error) => {
            return error;
          });
      };
    
      const handlePostChat = () => {
        PostChatQuestionAndAnswer(
          webbot_id,
          textInMessageBox,
          setQuestionAndAnswers,
          setTemporaryText,
          setLoading,
          conversationId!,
          skillId,
          setTextInMessageBox,
          dispatch,
          setChatSuggestions,
        );
      };
    
      const handleCopyUrl = () => {
        const url = `${window.location.origin}/CopilotExport/${webbot_id}`;
        navigator.clipboard.writeText(url); // Copy URL to clipboard
        setShowTooltip(true); // Show the tooltip
        setTimeout(() => setShowTooltip(false), 2000);
      };


    return (
        <div className="bg-[var(--primarybackgroundWhite)] md:px-[2%] rounded-[20px] w-full mt-[20px] p-[10px]">
            <div className="flex justify-between items-center h-9">
                <EditName />
                <div className="flex w-[25%] justify-between">
                    {/* <Tooltip text="Edit Settings" >
                    <div className="flex items-center cursor-pointer" onClick={() => setPreview("Config")}>
                      Settings
                      <img
                        src={editIcon}
                        alt="copy"
                        width={"30px"}
                        height={"30px"}
                        className="hover:cursor-pointer"
                        
                      />
                      </div>
                    </Tooltip> */}
                    <Button
            size="xsmall"
            bgColor="primary"
            className="py-[4%] rounded-[10px] px-[10px] text-[80%]"
            onClick={() => setPreview("Config")}
          >
            Settings
          </Button>
                      
                    {/* Add a textbox saying Settings */}

                    {/* <Tooltip text={showTooltip ? "Copied Link!" : "Share your Copilot"}>
                    <div className="flex items-center cursor-pointer" onClick={() => handleCopyUrl()}>
                    Share
                      <img
                        src={ShareLogo}
                        alt="copy"
                        width={"30px"}
                        height={"30px"}
                        className="hover:cursor-pointer"
                        
                      />
                      </div>
                    </Tooltip> */}
                    <Button
            size="xsmall"
            bgColor="primary"
            className="py-[4%] rounded-[10px] px-[10px] text-[80%]"
            onClick={() => handleCopyUrl()}
          >
            Share
          </Button>
                    <Tooltip text="Refresh Chat">
                      <img
                        src={RefreshLogo}
                        alt="copy"
                        width={"35px"}
                        height={"35px"}
                        className="hover:cursor-pointer"
                        onClick={() => generateconversationIdAndFetchData()}
                      />
                    </Tooltip>
                  </div>
            </div>
            <div className="md:w-[full] max-h-[90vh]">
                <Copilot
                    onSend={handlePostChat}
                    chatSuggestions={chatSuggestions}
                    questionAndAnswers={questionAndAnswers}
                    textInMessageBox={textInMessageBox}
                    setTextInMessageBox={setTextInMessageBox}
                    temporaryText={temporaryText}
                    setTemporaryText={setTemporaryText}
                    loading={loading}
                    setLoading={setLoading}
                    height="h-[735px]"
                />
            </div>
        </div>
    );
};
