import React from "react";
import { Input } from "../../components/atoms/Input/Input";
import { Button } from "../../components/atoms/Button/Button";
import { hidePasswordLogo, showPasswordLogo } from "../assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faStarOfLife,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { AuthNav } from "../../components/molecules/NavBar/AuthNav";
import { UseNavigation } from "../../utlits/CustomHooks/Navigation";
import {
  getPasswordLenValidationColors,
  getPasswordSpecialCharValidationColors,
  getPasswordNumValidationColors,
  getPasswordUpperCharValidationColors,
} from "../../utlits/PasswordValidationColors";
import { useDispatch } from "react-redux";
import { CustomSuccessFrame } from "../../components/atoms/Frames/CustomSuccessFrame";
import { setWebbotId } from "../../Redux/Reducers/webbotSlice";
import { authentication } from "../../API/authentication";
import { setUserData } from "../../Redux/Reducers/loginSlice";

interface passwordStateProps {
  password: string;
  createPassword: string;
}
export const CreatePassword: React.FC = () => {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigateTo = UseNavigation();

  const [createPassword, setCreatePassword] =
    React.useState<passwordStateProps>({
      password: "",
      createPassword: "",
    });

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const [showCreatePassword, setShowCreatePassword] =
    React.useState<boolean>(false);

  const [message, setMessage] = React.useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setCreatePassword({ ...createPassword, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleCreatePasswordVisibility = () => {
    setShowCreatePassword(!showCreatePassword);
  };

  const handleSubmit = () => {
    createUserPassword();
  };

  // Password Validation Color Changes

  const passwordLengthValidationColor = getPasswordLenValidationColors(
    createPassword.password
  );
  const passwordSpeacialCharValidationColor =
    getPasswordSpecialCharValidationColors(createPassword.password);

  const passwordNumValidationColor = getPasswordNumValidationColors(
    createPassword.password
  );

  const passwordUpperCharValidationColor = getPasswordUpperCharValidationColors(
    createPassword.password
  );

  // to password

  const createUserPassword = () => {
    const password =
      createPassword.password === createPassword.createPassword &&
      createPassword.password;
    if (password) {
      authentication
        .createPassword(password, token)
        .then((response) => {
          setMessage(response?.data?.data?.message);
          authentication.getUser()
          .then(response=>{
            dispatch(setUserData(response))
            dispatch(
              setWebbotId(response?.maigrateuserInfo?.id)
            );
            setTimeout(() => {
              setMessage("");
              navigateTo("intro");
            }, 2000);
          })
          .catch(()=>{})

        })
        .catch((error) => {
          setMessage(error?.response?.data?.message);
        });
    } else {
      setMessage("Password does not match");
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  };
  return (
    <div>
      {message && <CustomSuccessFrame message={message} />}
      <AuthNav />
      <div className="w-[75%] lg:w-[24%] md:w-[60%] text-left m-auto mt-[13vh]">
        <div className="text-[var(--primaryHeadingColor)] text-[23px] lg:text-[23px] md:text-[32px] font-semibold text-left">
          Don't Stop Now
        </div>
        <div className="text-[var(--primarySideHeadingColor)] text-[12px] lg:text-[10px] md:text-[15px] tracking-[0.020em] w-[90%] lg:w-[70%] md:w-[80%] font-semibold">
          Strengthening your defense - create a powerful password now
        </div>
        <div className="mt-4 w-[92%]">
          <p className="text-left text-[15px] md:text-[17px] lg:text-[11px] text-[var(--primaryLabelColor)] font-semibold">
            Password
          </p>
          <div className="flex border-[2px] border-[var(--primaryBorderColor)] rounded-md mt-1 justify-between p-[5px] md:p-[9px] lg:p-[0px]">
            <Input
              type={showPassword ? "text" : "password"}
              placeholder="Must be 6 characters"
              className="outline-none border-none w-[100%] flex-1"
              name="password"
              onChange={handleChange}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="text-gray-500 focus:outline-none bg-transparent text-xs"
            >
              {showPassword ? (
                <img src={hidePasswordLogo} alt="show" className="w-5 pr-1" />
              ) : (
                <img src={showPasswordLogo} alt="show" className="w-5 pr-1" />
              )}
            </button>
          </div>
        </div>
        <div className="mt-2 w-[92%]">
          <p className="text-left text-[15px] md:text-[17px] lg:text-[11px] text-[#9C9C9C] font-semibold">
            Confirm Password
          </p>
          <div className="flex border-[2px] border-[var(--primaryBorderColor)] rounded-md mt-1 justify-between p-[5px] md:p-[9px] lg:p-[0px]">
            <Input
              type={showCreatePassword ? "text" : "password"}
              placeholder="Must be 6 characters"
              className="outline-none border-none"
              name="createPassword"
              onChange={handleChange}
            />
            <button
              type="button"
              onClick={toggleCreatePasswordVisibility}
              className="text-gray-500 focus:outline-none bg-transparent text-xs"
            >
              {showCreatePassword ? (
                <img src={hidePasswordLogo} alt="show" className="w-5 pr-1" />
              ) : (
                <img src={showPasswordLogo} alt="show" className="w-5 pr-1" />
              )}
            </button>
          </div>
        </div>
        <div className="w-[99%] lg:w-[92%] md:w-[100%] m-auto mt-2 grid grid-cols-3  grid-rows-2 gap-2">
          <div
            className={`${passwordLengthValidationColor} text-[10px] md:text-[18px] lg:text-[10px] flex items-center gap-2 col-span-1 `}
          >
            {createPassword.password?.length > 5 ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : createPassword.password === "" ? (
              <FontAwesomeIcon icon={faStarOfLife} />
            ) : (
              <FontAwesomeIcon icon={faXmark} />
            )}
            <p>6 Characters</p>
          </div>
          <div
            className={`${passwordSpeacialCharValidationColor} text-[10px] md:text-[18px] lg:text-[10px] flex items-center gap-2 col-span-2`}
          >
            {createPassword.password?.match(RegExp(/[!@#$%^&*?]+/)) !== null ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : createPassword.password === "" ? (
              <FontAwesomeIcon icon={faStarOfLife} />
            ) : (
              <FontAwesomeIcon icon={faXmark} />
            )}
            <p>One special character!@#$%^&*</p>
          </div>
          <div
            className={`${passwordNumValidationColor} text-[10px] md:text-[18px] lg:text-[10px] col-span-1 flex items-center gap-2`}
          >
            {createPassword.password?.match(RegExp(/[0-9]/)) !== null ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : createPassword.password === "" ? (
              <FontAwesomeIcon icon={faStarOfLife} />
            ) : (
              <FontAwesomeIcon icon={faXmark} />
            )}
            <p>One number</p>{" "}
          </div>
          <div
            className={`${passwordUpperCharValidationColor} text-[10px] md:text-[18px] lg:text-[10px] col-span-2 flex items-center gap-2`}
          >
            {createPassword.password?.match(RegExp(/[A-Z]/)) !== null ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : createPassword.password === "" ? (
              <FontAwesomeIcon icon={faStarOfLife} />
            ) : (
              <FontAwesomeIcon icon={faXmark} />
            )}
            <p>One uppercase letter</p>
          </div>
        </div>
        <Button
          size="small"
          bgColor="primary"
          className="p-[6px] mt-2 w-[26%] m-auto"
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
