// slices/dataSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DataState {
  data: any; // Replace 'any' with the type of your API response
  createSkillName: boolean;
  updateInstruction: boolean;
  skillName: string;
  copilotName: string;
  skillInstructions: string;
  skillId: number;
  addedSuccesfully: boolean;
  updatedSuccesfully: boolean;
  deletedSuccesfully: boolean;
  likesResponse: boolean;
  messageResponse: boolean;
}

const initialState: DataState = {
  data: null,
  createSkillName: false,
  skillName: "",
  skillInstructions: "",
  skillId: 0,
  addedSuccesfully: false,
  updatedSuccesfully: false,
  deletedSuccesfully: false,
  updateInstruction: false,
  copilotName: "",
  likesResponse: false,
  messageResponse: false,
};

const skillsDataSlice = createSlice({
  name: "skillsData",
  initialState,
  reducers: {
    setSkillData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    toggleCreateSkillName: (state) => {
      // Toggle the createSkillName property
      state.createSkillName = !state.createSkillName;
    },
    toggleLikeResponse: (state) => {
      // Toggle the createSkillName property
      state.likesResponse = !state.likesResponse;
    },
    toggleMessageResponse: (state) => {
      // Toggle the createSkillName property
      state.messageResponse = !state.messageResponse;
    },
    toogleUpdateInstruction: (state) => {
      // Toggle the createSkillName property
      state.createSkillName = !state.createSkillName;
    },
    toogleAddedSuccesfully: (state) => {
      // Toggle the createSkillName property
      state.addedSuccesfully = !state.addedSuccesfully;
    },
    toogleUpdatedSuccesfully: (state) => {
      // Toggle the createSkillName property
      state.updatedSuccesfully = !state.updatedSuccesfully;
    },
    toogleDeletedSuccesfully: (state) => {
      // Toggle the createSkillName property
      state.deletedSuccesfully = !state.deletedSuccesfully;
    },
    newSkillName: (state, action) => {
      state.skillName = action.payload;
    },
    copilotName: (state, action) => {
      state.copilotName = action.payload;
    },
    skillInstructions: (state, action) => {
      state.skillInstructions = action.payload;
    },
    storeSkillId: (state, action) => {
      state.skillId = action.payload;
    },
    resetCreateSkill:(state)=>{
        state.createSkillName=false;
    },
    setCreateSkill:(state)=>{
        state.createSkillName=true;
    },
    resetSkillData:(state)=>{
      state.data=null;
      state.createSkillName= true;
      state.skillName= "";
      state.skillInstructions= "";
      state.skillId= 0;
      state.addedSuccesfully= false;
      state.updatedSuccesfully= false;
      state.deletedSuccesfully= false;
      state.updateInstruction=false;
      state.likesResponse= false;
      state.messageResponse=false;
    }
  },
});

export const {
  setSkillData,
  toggleCreateSkillName,
  newSkillName,
  skillInstructions,
  storeSkillId,
  toogleAddedSuccesfully,
  toogleDeletedSuccesfully,
  toogleUpdatedSuccesfully,
  toogleUpdateInstruction,
  copilotName,
  toggleLikeResponse,
  toggleMessageResponse,
  resetSkillData,
  resetCreateSkill,
  setCreateSkill
} = skillsDataSlice.actions;
export default skillsDataSlice.reducer;
