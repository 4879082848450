import React, { useEffect, useState } from "react";
import { migrateLogo } from "../../../pages/assets";
import { Button } from "../../atoms/Button/Button";
import { UseNavigation } from "../../../utlits/CustomHooks/Navigation";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { UpgradeDate } from "../../organisms/UpgradeDate";
import { authentication } from "../../../API/authentication";
import { setUserData } from "../../../Redux/Reducers/loginSlice";

export default function Navbar() {
  const location = useLocation();
  const routeName = location.pathname;
  const navigateTo = UseNavigation();
  const [finish, setFinish] = useState(false);
  const [user, setUser] = useState<any>({});
 
  //const webbot_id: any = useSelector((state: any) => state?.webbot?.id);
  const dispatch = useDispatch();
  const title = useSelector((state: any) => state?.data?.copilotName);

  const closePopup = () => {
    setFinish(false);
  };

  useEffect(() => {
    if (title.length === 0) {
      authentication.getUser().then((response) => {
        setUser(response);
      });
    }
  }, [title]);

  const buttonText = () => {
    switch (location.pathname) {
      case "/Configure":
        return "Update Copilot";
      case "/UseCasesDataSource":
        return "Next";
      case "/UseCaseData":
        return "Finish";
      case "/CopilotInstructions":
        return "Go Back";
      default:
        return "Go to dashboard";
    }
  };

  const handleButtonClick = () => {
    const { pathname } = location;
    if ((pathname === "/UseCaseData" || pathname === "/Configure") && !user.maigrateInitialize) {
      setFinish(true);
      authentication.maigrateUserStatus();
    } else {
      const navigatePath = pathname === "/UseCaseData" || pathname === "/Configure" || pathname === "/UseCasesDataSource"
        ? "Copilot"
        : pathname === "/CopilotInstructions"
          ? "UseCaseData"
          : "dashboard";
      navigateTo(navigatePath);
    }
  };

  const handleNoClick = () => setFinish(false);
  const handleYesClick = () => {
    setFinish(false);
    navigateTo("Copilot");
  };
  return (
    <div className="flex items-start justify-start">
      <div className="lg:w-[80%] pt-[10px] px-[20px] mx-auto flex justify-between">
        <img
          src={migrateLogo}
          alt="migrate logo"
          className="lg:w-[20%] h-25 self-center md:w-[30%] w-[35%]"
        />
        <div className="flex items-center gap-2">
          <UpgradeDate />
          <Button
            size="xsmall"
            bgColor="primary"
            className="md:py-[10px] rounded-[15px] md:px-[15px] md:text-[15px] py-[5px] px-[7px]"
            onClick={handleButtonClick}
          >
            <p className="text-white text-[16px] font-semibold">
              {buttonText()}
            </p>
          </Button>
          {finish && (location.pathname === "/UseCaseData" || location.pathname === "/Configure")  && (
            <>
              <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 z-20 flex flex-col w-[50vw] rounded-[10px]">
                <p>{`Your copilot Name is "${title}'s Copilot". Do you want to Continue? `}</p>
                <div className="flex gap-[10px] my-[20px] items-center justify-center">
                  <Button size="xsmall" bgColor="primary" className="md:py-[10px] rounded-[15px] md:px-[20px] md:text-[15px] py-[5px] px-[7px]" onClick={handleNoClick}>
                    <p className="text-white text-[16px] font-semibold">No</p>
                  </Button>
                  <Button size="xsmall" bgColor="primary" className="md:py-[10px] rounded-[15px] md:px-[20px] md:text-[15px] py-[5px] px-[7px]" onClick={handleYesClick}>
                    <p className="text-white text-[16px] font-semibold">Yes</p>
                  </Button>
                </div>
              </div>
              <div
                className="fixed inset-0 bg-black opacity-50 z-10"
                onClick={handleNoClick}
              ></div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
