import React from "react";
import { LoadingGif } from "../../../pages/assets";

interface Props{
className?:string,
}
export const Loading = ({className}:Props) => {
  return (
    <div className={`bg-[var(--primaryBackgroundColor)] w-full flex flex-col items-center justify-center min-h-screen mt-[-50px] ${className}`}>
      <img src={LoadingGif} alt="Your GIF" />
      <p className="text-[var(--primary)] md:text-[35px] font-bold mt-[-100px] text-[20px]">
        Please wait...
      </p>
    </div>
  );
};
