import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { SplashScreen } from "./Authentication/SplashScreen";
import { UseCases } from "./UseCases/UseCases";
import { UseCasesDataSource } from "./UseCases/UseCasesDataSource";
import { Signup } from "./Authentication/Signup";
import { SecurityCheck } from "./Authentication/SecurityCheck";
import { Login } from "./Authentication/Login";
import { LoginPassword } from "./Authentication/LoginPassword";
import { CreatePassword } from "./Authentication/CreatePassword";
import { UseCaseData } from "./UseCases/UseCaseData";
import { CopilotInstructions } from "./UseCases/CopilotInstructions";
// import { CopilotExport } from "../components/molecules/UseCasesStep3/CopilotExport";
import CopilotExport from "./CopilotExport";
import { CopilotIframe } from "../components/atoms/Iframes/CopilotIframe";
import { useParams } from "react-router-dom";
import { Introduction } from "./Intro/Introduction";
import { CustomCopilot } from "./UseCases/CustomCopilot";
import { CustomChatBot } from "../components/molecules/Custom/EmbededChatBot";
import { Dashboard } from "./Dashboard/Dashboard";
import { ForgottenPassword } from "./Authentication/ForgottenPassword";
import { PrivateRoute } from "../components/atoms/PrivateRoutes/PrivateRoute";
import { CopilotHistory } from "./Dashboard/CopilotHistory";
import { useDispatch } from "react-redux";
import { authentication } from "../API/authentication";
import { setUserData, setUserLoaded } from "../Redux/Reducers/loginSlice";
import { MainCopilotPage } from "./UseCases/Main";
export const RoutesPage = () => {
  const isSignedIn = localStorage.getItem("token");
  const location = useLocation();
  // const { isAuth } = useSelector((store: any) => store.loginReducer);
  const dispatch = useDispatch();
  const routeName = location.pathname;
  const id = useParams();

  // console.log(routeName, "routeName=");
  // const normalizedRoutePath = routeName.replace(/\/+$/, "");
  // const isLoginOrSplash =
  //   normalizedRoutePath === "/login" ||
  //   normalizedRoutePath === "/splash" ||
  //   normalizedRoutePath === "/security" ||
  //   normalizedRoutePath === "/login_password" ||
  //   normalizedRoutePath === "/create_password" ||
  //   normalizedRoutePath === "/signup";
  // console.log("isLoginOrSplash: ", isLoginOrSplash);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
     try{
      authentication.getUser()
      .then(res=>dispatch(setUserData(res)))
      .finally(()=>dispatch(setUserLoaded(true)))
     } 
     catch{}
    }
  }, []);

  useEffect(() => {
    if (
      routeName !== "/Copilot" &&
      routeName !== "/UseCaseData" &&
      routeName !== `/CopilotExport/${id}`
    ) {
      localStorage.removeItem("conversationId");
    }
  }, [routeName, id]);

  return (
    <div className="min-h-screen">
      <Routes>
        <Route
          path="/UseCases"
          element={
            <PrivateRoute isSignedIn={isSignedIn}>
              <UseCases />
            </PrivateRoute>
          }
        />
        <Route
          path="/intro"
          element={
            <PrivateRoute isSignedIn={isSignedIn}>
              <Introduction />
            </PrivateRoute>
          }
        />
        <Route
          path="/configure"
          element={
            <PrivateRoute isSignedIn={isSignedIn}>
              <MainCopilotPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/UseCasesDataSource"
          element={
            <PrivateRoute isSignedIn={isSignedIn}>
              <UseCasesDataSource />
            </PrivateRoute>
          }
        />
        <Route path="/" element={<SplashScreen />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login_password" element={<LoginPassword />} />
        <Route path="/security" element={<SecurityCheck />} />
        <Route path="/create_password" element={<CreatePassword />} />
        <Route path="/forgot_password" element={<ForgottenPassword />} />
        <Route
          path="/UseCaseData"
          element={
            <PrivateRoute isSignedIn={isSignedIn}>
              <UseCaseData />
            </PrivateRoute>
          }
        />
        <Route
          path="/CopilotInstructions"
          element={
            <PrivateRoute isSignedIn={isSignedIn}>
              <CopilotInstructions />
            </PrivateRoute>
          }
        />
        <Route
          path="/CopilotExport/:webbot_id"
          element={
              <CopilotExport />
          }
        />
        <Route
          path="/CopilotExport/:webbot_id/skip"
          element={
              <CopilotExport enableSkip />
          }
        />
        <Route
          path="/CopilotChat"
          element={
            <PrivateRoute isSignedIn={isSignedIn}>
              <CopilotIframe />
            </PrivateRoute>
          }
        />
        <Route
          path="/Copilot"
          element={
            <PrivateRoute isSignedIn={isSignedIn}>
              <MainCopilotPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/CustomChat/:id/:instructionId"
          element={
              <CustomChatBot />
          }
        />
        {/* <Route path="/Dashboard" element={<Dashboard />} /> */}
        <Route
          path="/dashboard"
          element={
            <PrivateRoute isSignedIn={isSignedIn}>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/copilot-history"
          element={
            <PrivateRoute isSignedIn={isSignedIn}>
              <CopilotHistory />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
};
