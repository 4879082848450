import { useEffect, useState } from "react";
import { copilotProcess } from "../../../API/copilotProcess";
import moment from "moment";
import MaigrateLogo from '../../../Assets/maigrateLogo.png'
import { Loading } from "../../atoms/Loader/Loading";
import ResponseComponent from "../../atoms/ResponseComponents/ResponseComponent";

interface Props {
  conversationId: number | null;
}

const CopilotHistoryChats = ({ conversationId }: Props) => {
  const [chats, setChats] = useState<any>(null);
  const [loading,setLoading]=useState(false);
  useEffect(() => {
    if (conversationId) {
      setLoading(true)
      copilotProcess.getQuestionAndAnswerBasedOnConversationId(
        conversationId,
        setChats
      )
      .finally(()=>setLoading(false))
    }else{
      setChats(null);
    }
  }, [conversationId]);

  if(loading){
    return <Loading className="lg:min-h-[100%]"/>
  }
  if(!chats){
    return(
      <div className="w-full h-full grid place-content-center text-center">
        <img className="w-[180px] mx-auto my-2" src={MaigrateLogo}/>
        <p className="text-sm ">Select a chat to view copilot’s conversation with users.</p>
      </div>
    )
  }
  if(!chats.length){
    return(
      <div className="w-full h-full grid place-content-center text-center">
        <img className="w-[180px] mx-auto my-2" src={MaigrateLogo}/>
        <p className="text-sm ">No conversation found !</p>
      </div>
    )
  }

  return (
    <div className="w-full">
      <div className="p-2 border-b-[1px] h-[40px]  w-full">
        <p className="text-sm text-[#a5a3a3] text-left pl-5">
         {chats.length && moment(chats[0]?.created_date).format("MMMM D, YYYY")}
        </p>
      </div>
      <div className="overflow-y-auto h-[calc(100vh_-_80px)] md:h-[calc(100%_-_40px)]">
        {chats &&
          chats.map((item: any) => {
            var stillUtc = moment.utc(item.created_date).toDate();
            let time = moment(stillUtc).local().format("LT");
            return (
              <>
                <ResponseComponent response={item.question} chatHistory={true} time={time} name="U" />
                <ResponseComponent chatHistory={true} response={item.answer}
                  time={time}
                  name="M"
                  isAns={true}
                  
                />
              </>
            );
          })}
      </div>
    </div>
  );
};

export default CopilotHistoryChats;
