import React, { useState } from "react";
import { UseCaseDescription } from "../molecules/UseCasesStep1/UseCaseDescription";
import { UseCaseOptions } from "../molecules/UseCasesStep1/UseCaseOptions";
import { StepIndicator } from "../atoms/Stepper/HorizantalStepper";
import  PremiumFeatureModal  from "../atoms/PremiumFeature/PremiumFeature";

export const UseCasesStep1 = () => {
  const [clicked, setClicked] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState(false)
  // const webbot_id: any = useSelector((state: any) => state?.webbot?.id);

  return (
    <div className="lg:w-[80%] m-auto lg:px-[1%] 2xl:w-[80%] p-[10px]">
      <div className="w-full">
        {/* To display Stepindicator */}
        <StepIndicator />
      </div>
      <div>
        <p className="text-[var(--primaryHeadingColor)] leading-trim-[both] text-cap font-poppins font-semibold md:text-3xl text-2xl">
          Select Use cases
        </p>
      </div>
      <div className="md:flex justify-between">
        <UseCaseOptions  onDivClick={() => setModalOpen(true)} setClicked={setClicked} clicked={clicked} />
        <UseCaseDescription setClicked={setClicked} clicked={clicked} />
      </div>
      <div className=" mt-[1.2%] flex justify-start"></div>
      <PremiumFeatureModal  text="This is a Premium Feature" isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </div>
  );
};
