import React, { useEffect, useState } from "react";
import { Button } from "../../atoms/Button/Button";
import { deleteLogo } from "../../../pages/assets";
import { useSelector } from "react-redux";
import { UseNavigation } from "../../../utlits/CustomHooks/Navigation";
import { copilotSetup } from "../../../API/copilotSetup";
import DeletePopup from "../../atoms/DeletePopup/DeletePopup";

interface DataSourceProps {
  setDeletedSuccesfully: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DataSource = ({ setDeletedSuccesfully }: DataSourceProps) => {
  const navigateTo = UseNavigation();
  const webbot_id: any = useSelector((state: any) => state?.webbot?.id);

  const [deleteRes, setDeleteRes] = useState<any>();
  const [dataSourceForTable, setDataSourceForTable] = useState<Object[]>([]);
  const [deleteId, setDeleteId]=useState(null);

  useEffect(() => {
    copilotSetup.getAllFiles(webbot_id, setDataSourceForTable).catch((err) => {
      console.error(err);
    });
  }, [deleteRes]);

  const deleteConfirm=(file_id:any)=>{
    copilotSetup.handleDeleteFromAllFiles(file_id, setDeleteRes, setDeletedSuccesfully).catch((error: any) => { console.error(error) })
  }

  return (
    <div className="flex flex-col justify-start items-start gap-5 bg-[var(--primarybackgroundWhite)] lg:p-[3%] lg:py-[4%] rounded-[20px] w-full mt-[20px] p-[10px] md:p-[0px] md:pl-[10px] md:py-[10px] ">
      {deleteId && <DeletePopup title="Are you sure you want to delete this data?" 
      onClose={()=>setDeleteId(null)}
      onConfirm={()=>{deleteConfirm(deleteId);setDeleteId(null)}}
      />}
      <div className="flex justify-between w-full">
        <div>
          <p className="text-[var(--primaryTextColor)] md:text-[120%] ">
            Knowledge
          </p>
        </div>
        <div>
          <Button
            size="xsmall"
            bgColor="primary"
            className="py-[4%] rounded-[10px] px-[10px] text-[80%]"
            onClick={() => navigateTo("UseCasesDataSource")}
          >
            Upload Data
          </Button>
        </div>
      </div>
      <div className="w-full flex flex-col gap-3">
        <div className=" flex flex-col gap-3 h-[25vh] overflow-y-auto custom-scrollbar mr-[10px] pr-[10px] ">
          <div className="w-full">
            <table className="w-[100%] border-collapse">
              <thead>
                <tr className=" text-[var(--primaryTextColor)] lg:text-[18px] border-b border-gray-400">
                  <th className="py-2 lg:px-3 text-left text-[13px] ">
                    Data Type
                  </th>
                  <th className="py-2 lg:px-3 text-left lg:text-[13px] text-[14px]">
                    File Name
                  </th>
                  <th className="py-2 lg:px-3 "></th>
                </tr>
              </thead>
              <tbody>
                {dataSourceForTable?.map((file: any, index: number) => {
                  return (
                    <>
                      {file?.active && (
                        <tr className="border-b border-gray-400" key={file.id}>
                          <td className="py-3 lg:px-4 text-left text-[#606060] text-[12px] sm:px-4 md:px-0">
                            {file.file_type === "" ? "unkown" : file.file_type}
                          </td>
                          <td className="py-3 lg:px-4 text-left text-[#606060] text-[12px] sm:px-4 md:px-0">
                            {file.name.length > 15
                              ? file.name.substring(0, 10)
                              : file.name}
                            ...
                          </td>
                          <td
                            className="py-3 px-4 cursor-pointer text-right flex "
                            onClick={() =>setDeleteId(file?.file_id)}
                          >
                            <img
                              src={deleteLogo}
                              alt="delete"
                              className="w-[20px] h-[20px]"
                            />
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
