import React, { InputHTMLAttributes } from "react";
// import { EyeIcon } from '@heroicons/react'
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  // Add your custom input props here, if needed
  type: string;
  placeholder?: string;
  className?: string;
  // border: "none" | "light";
}

export const Input = ({
  type,
  placeholder,
  className,
  ...rest
}: InputProps) => {
  return (
    <div className="bg-transparent w-full">
      <input
        type={type}
        placeholder={placeholder}
        className={`w-full bg-transparent border-[2px] rounded-md border-[var(--primaryBorderColor)] placeholder-[var(--primaryPlaceholderColor)] text-[12px] p-[10px] mt-1 text-[var(--primaryLabelColor)] outline-none font-semibold ${className}`}
        {...rest}
      />
    </div>
  );
};
