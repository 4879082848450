import { ButtonHTMLAttributes } from "react";
import { useSelector } from "react-redux";
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  bgColor?: "primary" | "secondary" | "none";
  color?:
    | "primary"
    | "secondary"
    | "warning"
    | "error"
    | "default"
    | "inherit"
    | "link";
  variant?: string;
  rounded?: "small" | "medium" | "large";
  isLoading?: boolean;
  fullWidth?: boolean;
  size?: "xsmall" | "small" | "medium" | "large";
}

export function Button({
  bgColor = "none",
  color = "primary",
  variant = "contained",
  rounded = "medium",
  className,
  type = "button",
  disabled = false,
  isLoading = false,
  fullWidth = false,
  size = "medium",
  ...rest
}: ButtonProps) {
  const bgColorClass =
    bgColor === "primary"
      ? "bg-[var(--primary)]"
      : bgColor === "secondary"
      ? "bg-[#1f323f]"
      : "";
  const colorClass =
    color === "primary"
      ? "text-white"
      : color === "secondary"
      ? "text-[var(--primaryLabelColor)]"
      : color === "default"
      ? "text-black"
      : "";

  const sizeClass =
    size === "xsmall"
      ? "text-[10px]"
      : size === "small"
      ? "text-sm"
      : size === "large"
      ? "text-lg"
      : "text-base";

  // const borderedClass = border === "none" ? "border-0" : border === "light" ? "border-2" : "none";

  const fullWidthClass = fullWidth ? "w-full" : "";

  const loadingClass = isLoading ? "cursor-not-allowed opacity-50" : "";

  const roundedClass =
    rounded === "small"
      ? "rounded-sm"
      : rounded === "large"
      ? "rounded-lg"
      : rounded === "medium"
      ? "rounded-md"
      : "rounded-large";

  const combinedClasses = `${bgColorClass} ${colorClass} ${sizeClass} ${fullWidthClass} ${loadingClass} ${roundedClass} ${
    className || ""
  }`;
  const webbot_id: any = useSelector((state: any) => state?.webbot?.id);

  return (
    <button
      className={`${combinedClasses} flex items-center justify-center`}
      type={type}
      disabled={disabled}
      {...rest}
    >
      {rest.children}
    </button>
  );
}
