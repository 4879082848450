import React, { useState } from "react";

interface ParagraghProps {
  content: string;
}

export function Paragraph({ content }: ParagraghProps) {
  const [showFullContent, setShowFullContent] = useState(false);
  const maxLength = 210;
  const truncatedContent = content.slice(0, maxLength);
  const shouldTruncate = content.length > maxLength;

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  return (
    <div>
      <div className="flex justify-start">
        <p className="text-[10px] text-left h-[fit-content] font-[600]">
          {showFullContent || !shouldTruncate
            ? `${content}...`
            : `${truncatedContent}...`}
        </p>
      </div>
      {shouldTruncate && (
        <div
          className="text-[10px] text-left font-bold text-[var(--primary)] mt-1 cursor-pointer"
          onClick={toggleContent}
        >
          {showFullContent ? "Read Less" : "Read More"}
        </div>
      )}
    </div>
  );
}
