import axios from "axios";
import { toggleMessageResponse } from "../Redux/Reducers/SkillsData";
import { NETWORK_URL } from "../utlits/Network";
import { useDispatch, useSelector} from "react-redux";
import { copilotProcess } from "./copilotProcess";
import { set } from "lodash";

/**
 * 
 * @param webbot_id 
 * @param textInMessageBox (Question)
 * @param setChatHistory
 * @param setTemporaryText 
 * @param setLoading 
 * @param conversationId 
 * @param instructionId 
 * @param setText 
 * @param dispatch 
 * @param setSuggestedQuestions (If Suggested questions should be generated, optional)
 * @returns list of all the questionAnswers for a conversationID after a question is posted
 */
export function PostChatQuestionAndAnswer (
  webbot_id: number,
  textInMessageBox: string,
  setChatHistory: Function,
  setTemporaryText: Function,
  setLoading: Function,
  conversationId: string,
  instructionId: string,
  setTextInMessageBox: Function,
  dispatch: Function,
  setSuggestedQuestions?: Function,
) :any {

  try {
    if (textInMessageBox.length === 0) {
      alert("Ask Some Question");
    } else {
      setTemporaryText(textInMessageBox);
      setLoading(true);
      dispatch(toggleMessageResponse());
      const payload = {
        webbot_id: webbot_id,
        conversation_id: conversationId,
        question: textInMessageBox,
        context: "string",
        instruction_id: instructionId,
      };
      setTextInMessageBox("");
      if (setSuggestedQuestions){
        copilotProcess.getChatSuggestions(webbot_id, setSuggestedQuestions, textInMessageBox).catch((err) => { console.trace(err);} );
        }
      return axios
        .post(`${NETWORK_URL}/questionanswer`, payload)
        .then(async(res: any) => {
          setLoading(false);
          dispatch(toggleMessageResponse());
          setTemporaryText("");
          copilotProcess.getQuestionAndAnswerBasedOnConversationId(conversationId, setChatHistory).catch((err) => { console.trace(err);});
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  } catch (error) {
    console.error(error);
  }
};
