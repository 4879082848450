import React from "react";
import { Button } from "../../../atoms/Button/Button";
import { integrateCopilotData } from "../../../../utlits/Data";

interface IntegrateCopilotProps {
  preview: string;
  setPreview: React.Dispatch<React.SetStateAction<string>>;
}

export const IntegrationSidePanel = ({
  preview,
  setPreview,
}: IntegrateCopilotProps) => {
  // const webbot_id: any = useSelector((state: any) => state?.webbot?.id);

  return (
    <div className="flex flex-col justify-start items-start gap-5 bg-[var(--primarybackgroundWhite)] lg:p-[20px] rounded-[20px] w-full mt-[20px] p-[20px] md:p-[10px]">
      <div className="flex justify-between w-full">
        <div>
          <p className="text-[var(--primaryTextColor)] md:text-[120%] ">
            Integrate your Copilot
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col gap-3">
        <div className=" flex flex-col gap-3 h-[18vh] overflow-y-auto custom-scrollbar mr-[10px] pr-[10px] ">
          <div className="w-full flex flex-wrap gap-[10px]">
            {integrateCopilotData?.map((data) => {
              return (
                <div
                  key={data.id}
                  className="flex justify-between w-full border-0 border-b-[1px] border-b-[#949da2] pb-[3%]"
                >
                  <p className="lg:text-[14px] text-center text-[var(--secondaryTextColor)] justify-center md:-[12px]">
                    {data.title}
                  </p>
                  <div>
                    <Button
                      size="xsmall"
                      bgColor={data.active ? "primary" : "secondary"}
                      className="py-[4%] rounded-[10px] px-[10px] text-[80%]"
                      onClick={() => {
                        console.debug("Button clicked");
                        setPreview(data.title);
                      }}

                      disabled={data.active === false}
                    >
                      Preview
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
