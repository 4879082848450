import React, { useEffect, useState } from "react";
import { Button } from "../../atoms/Button/Button";
import { AccordionCustomIcon } from "../../atoms/Accordian/DefaultAccordion";
import { UseNavigation } from "../../../utlits/CustomHooks/Navigation";
import { useSelector } from "react-redux";
import { copilotProcess } from "../../../API/copilotProcess";
import { useLocation } from "react-router-dom";

export const Instructions = () => {
  const navigateTo = UseNavigation();
  const location = useLocation();
  const [copilotInstructionData, setCopiedInstructionsData] = useState([]);
  const webbot_id: any = useSelector((state: any) => state?.webbot?.id);

  useEffect(() => {
    getInstructionsData();
  }, []);

  const getInstructionsData = () => {
    copilotProcess
      .getInstructions(webbot_id)
      .then((response) => {
        setCopiedInstructionsData(response?.data?.reverse());
      })
      .catch((error) => console.error(error));
  };

  const onCopilotPage = () => {
    return location.pathname == "/Copilot";
  };

  return (
    <div className="flex flex-col justify-start items-start gap-1 bg-[var(--primarybackgroundWhite)] lg:p-[4%] lg:py-[4%] rounded-[20px] w-full mt-[20px] p-[10px] ">
      <div className="flex justify-between w-full">
        <div>
          <p className="text-[var(--primaryTextColor)] md:text-[120%] ">
            {onCopilotPage() ?  "Choose Task to Use": "Tasks" }
          </p>
        </div>
       {onCopilotPage() ? null : <div>
          <Button
            size="xsmall"
            bgColor="primary"
            className="py-[4%] rounded-[10px] px-[10px] text-[80%]"
            onClick={() => navigateTo(`CopilotInstructions`)}
          >
            Create Task
          </Button>
        </div>} 
      </div>
      <div className="w-full flex flex-col gap-2 h-[22vh] overflow-y-auto custom-scrollbar">
        <div className="">
          <AccordionCustomIcon
            InstructionsAccordianData={copilotInstructionData}
          />
        </div>
      </div>
    </div>
  );
};
