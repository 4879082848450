import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Input } from "../Input/Input";
const topics = ['All', 'Subtopic 1', 'Subtopic 2', 'Subtopic 3', 'Hello world', 'Random', '000', 'Budgeting basic', 'Budgeting adv']

interface Props {
    onClose?: () => void;
}
const ChatFilterPopup = ({ onClose }: Props) => {
    return (<div className="h-[100vh] p-2 sm:0 overflow-auto w-[100vw] fixed min-w-[300px] top-0 left-0 bg-[#0E0E0E73] z-[999] grid place-content-center">
        <div className="max-w-[550px] mb-3 w-[96%] m-auto min-h-[100px]  bg-white py-4 px-4 relative">
            <button onClick={onClose} className="btn absolute top-2 right-2">
                <FontAwesomeIcon className="text-[grey] text-lg" icon={faTimes} />
            </button>
            <p className="text-lg text-left font-bold">Filter Chats</p>
            <div>
                <div className="w-full mt-4  block md:flex ">
                    <div className="w-[25%]  flex items-center text-right whitespace-nowrap text-sm font-bold">
                        <p className="text-right w-full pr-10">Satisfaction score</p>
                    </div>
                    <div className="ml-[2%] w-[73%] block md:flex items-center">
                        <div className="flex w-max items-center">
                            <label className="text-xs whitespace-nowrap px-3">Higher than</label>
                            <Input type="number" className="w-[60px] rounded-none border-[1px]" />
                        </div>
                        <div className="flex w-max items-center md:ml-2 mt-2 md:mt-0">
                            <label className="text-xs whitespace-nowrap px-3">Lower than</label>
                            <Input type="number" className="w-[60px] rounded-none border-[1px]" />
                        </div>
                    </div>
                </div>
                <div className="w-full mt-4 block md:flex">
                    <div className="w-[25%] flex items-center text-right whitespace-nowrap text-sm font-bold">
                        <p className="text-right w-full pr-10">Chatter count</p>
                    </div>
                    <div className="ml-[2%] w-[73%] block md:flex items-center">
                        <div className="flex w-max items-center">
                            <label className="text-xs whitespace-nowrap px-3">Higher than</label>
                            <Input type="number" className="w-[60px] rounded-none border-[1px]" />
                        </div>
                        <div className="flex w-max items-center mt-2 md:mt-0 md:ml-2">
                            <label className="text-xs whitespace-nowrap px-3">Less than</label>
                            <Input type="number" className="w-[60px] rounded-none border-[1px]" />
                        </div>
                    </div>
                </div>
                <div className="w-full mt-4 block md:flex">
                    <div className="w-[25%] flex pt-3 text-right whitespace-nowrap text-sm font-bold">
                        <p className="text-right w-full pr-10">Subtopic</p>
                    </div>
                    <div className="ml-[2%] w-[73%] flex flex-wrap">
                        {topics.map(text => (
                            <div className="flex w-max m-2 items-center">
                                <Input id="all" type="checkbox" className="w-[20px] rounded-none border-[1px]" />
                                <label htmlFor="all" className="text-xs whitespace-nowrap pl-2">{text}</label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="w-full mt-4 flex">
                    <div className="w-[25%] flex pt-3 text-right whitespace-nowrap text-sm font-bold">
                        <p className="text-right w-full pr-10">Timeline</p>
                    </div>
                    <div className="ml-[2%] w-[73%] flex flex-wrap">
                        <div className="flex w-max items-center">
                            <Input placeholder="All time" type="number" className="w-[100px] rounded-none border-[1px]" />
                        </div>
                    </div>
                </div>
                <div className="w-full mt-4 flex">
                    <div className="w-[25%] flex justify-items-end pt-3 text-right whitespace-nowrap text-sm font-bold">
                        <p className="text-right w-full pr-10"> Saved</p>
                    </div>
                    <div className="ml-[2%] w-[73%] flex flex-wrap">
                        <div className="flex w-max items-center">
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input type="checkbox" value="" className="sr-only peer" />
                                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default ChatFilterPopup;