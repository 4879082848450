// loginSlice.js
import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "loginEmail",
  initialState: {
    LoginEmail: "",
    signUpEmail: "",
    forgotPasswordEmail: "",
    isAuth: null,
    userData:null,
    isGuest:false,
    canSkipLogin:false,
    userLoaded:false,
  }, // Initial login value
  reducers: {
    setlogin: (state, action) => {
      // Ensure login doesn't go beyond 100
      state.LoginEmail = action.payload;
    },
    setSignup: (state, action) => {
      state.signUpEmail = action.payload;
    },
    setForgotPasswordEmail: (state, action) => {
      state.forgotPasswordEmail = action.payload;
    },
    setIsAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setUserData:(state,action)=>{
      state.userData=action.payload;
    },
    setUserGuest:(state,action)=>{
      state.isGuest=action.payload;
    },
    setCanSkipLogin:(state,action)=>{
      state.canSkipLogin=action.payload;
    },
    setUserLoaded:(state,action)=>{
      state.userLoaded=action.payload;
    },
  },
});

export const { setlogin, setSignup, setForgotPasswordEmail, setIsAuth,setUserData,setUserGuest,setCanSkipLogin,setUserLoaded } =
  loginSlice.actions;

export default loginSlice.reducer;
