import React from "react";

export function InstructionToOpenCopilot() {
  return (
    <div className="flex flex-col justify-start text-left text-[12px]">
      <p className="text-[var(--primaryTextColor)] text-[16px] font-extrabold">
        <u>Instructions :</u>
      </p>
      <ol>
        <li>
          <p className="text-[14px] font-extrabold">Step 1: Copy the Code</p>
          <p>First, copy the provided code that you want to use for Copilot.</p>
        </li>
        <li>
          <p className="text-[14px] font-extrabold">Step 2: Open a Web Page</p>
          <p>
            Open any web page in your browser that you'd like to use Copilot on.
          </p>
        </li>
        <li>
          <p className="text-[14px] font-extrabold">
            Step 3: View the Source Code
          </p>
          <ul>
            <li>Right-click anywhere on the web page.</li>
            <li>
              From the context menu that appears, select "Inspect" or "Inspect
              Element." This will open the browser's Developer Tools.
            </li>
          </ul>
        </li>
        <li>
          <p className="text-[14px] font-extrabold">
            Step 4: Edit the HTML Code
          </p>
          <ul>
            <li>
              In the Developer Tools, navigate to the "Elements" or "HTML" tab.
              This is where you can view and edit the webpage's source code.
            </li>
            <li>
              Locate the section of the HTML code where you want to insert the
              Copilot chat bot.
            </li>
            <li>
              Right-click on the appropriate place within the HTML code and
              select "Edit as HTML" or a similar option.
            </li>
          </ul>
        </li>
        <li>
          <p className="text-[14px] font-extrabold">
            Step 5: Paste the Copied Code
          </p>
          <p>
            Paste the Copilot code that you copied in Step 1 into the selected
            spot in the HTML code.
          </p>
        </li>
        <li>
          <p className="text-[14px] font-extrabold">Step 6: Activate Copilot</p>
          <ul>
            <li>After pasting the code, save your changes if prompted.</li>
            <li>
              You should now see Copilot displayed on the webpage in the
              location where you inserted the code.
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
}
