import React, { useState } from "react";
import "../../../styles/ScrollBar.css";
import { Accordion } from "../../atoms/Accordian/Accordian";
import { DataSourceAccordianData } from "../../../utlits/Data";
import PremiumFeatureModal from "../../atoms/PremiumFeature/PremiumFeature";
// import { useSelector } from "react-redux";

interface DataSourceListProps {
  setClicked: React.Dispatch<React.SetStateAction<boolean>>;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  files: File[];
  progress: number;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  setUrlResponse: any;
}

interface UserTypeData {
  video: string;
  url: string;
  website: string;
  s3Bucket: string;
  gitRepo: string;
}

export const DataSourceList = ({
  setClicked,
  setFiles,
  files,
  setProgress,
  progress,
  setUrlResponse,
}: DataSourceListProps) => {
  // const webbot_id: any = useSelector((state: any) => state?.webbot?.id);
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const [dataByUser, setDataByUser] = useState<UserTypeData>({
    video: "",
    url: "",
    website: "",
    s3Bucket: "",
    gitRepo: "",
  });
  const [isModalOpen, setModalOpen] = useState(false);
  //-----------------------TO OPEN ACCORDIAN-----------------------------------------

  const handleAccordionClick = (index: any) => {
    setOpenAccordionIndex(index === openAccordionIndex ? null : index);
  };
  

  return (
    <div className=" flex flex-col justify-center items-center gap-5 bg-[var(--primarybackgroundWhite)] lg:p-[1.5%] rounded-[20px] md:p-[1.5%] lg:w-[40%] md:w-[48%] mt-[1.5%] p-[10px] ">
      <div className="flex lg:text-[140%] text-[18px] items-center gap-13 w-[100%] rounded-[16px] justify-center">
        <p className="text-[var(--primaryHeadingColor)]font-semibold text-center">
          Select your source of Knowledge
        </p>
      </div>
      <div className="w-[100%] flex flex-col gap-3 h-[65vh] overflow-y-auto custom-scrollbar">
        <div className="flex flex-col gap-3 lg:mr-[1%] mr-[10px] ">
          {DataSourceAccordianData.map((e: any) => {
            return (
              <div
                className="p-[10px] items-center gap-13 w-[100%] bg-[var(--primaryInputbackgroundWhite)] rounded-[16px]"
                key={e.id}
              >
                <Accordion
                  title={e.title}
                  content={DataSourceAccordianData}
                  id={e.id}
                  isOpen={e.id === openAccordionIndex}
                  onClick={() => handleAccordionClick(e.id)}
                  setClicked={setClicked}
                  setFiles={setFiles}
                  files={files}
                  type={e.type}
                  setDataByUser={setDataByUser}
                  dataByUser={dataByUser}
                  progress={progress}
                  setProgress={setProgress}
                  setUrlResponse={setUrlResponse}
                  onDivClick={() => setModalOpen(true)} 
                />
              </div>
            );
          })}
        </div>
      </div>
      <PremiumFeatureModal text="This is a Premium Feature" isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </div>
  );
};
