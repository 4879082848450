import React from "react";
import "../../../styles/Shadow.css";
import { SendLogo } from "../../../pages/assets";
import { useSelector } from "react-redux";
// import { useSelector } from "react-redux";

interface MessageInputProps {
  textInMessageBox: string;
  setTextInMessageBox: React.Dispatch<React.SetStateAction<string>>;
  onSend: () => void;
  containerClass?:string;
  inputClass?:string,
  buttonContainerClass?:string,
  buttonImgClass?:string,
  buttonImage?:any,

}


export const MessageInput = ({
  textInMessageBox,
  setTextInMessageBox,
  onSend,
  containerClass,
  inputClass,
  buttonContainerClass,
  buttonImgClass,
  buttonImage,
}: MessageInputProps) => {
  // const webbot_id: any = useSelector((state: any) => state?.webbot?.id);
  const messageResponse = useSelector(
    (state: any) => state?.data?.messageResponse
  );
  // console.log(messageResponse, "Response after message");

  return (
    <>
      <div className={`w-[full] p-[1%] bg-[#d9d9d9] rounded-[13px] shadow-bottom flex px-[3%] items-center justify-between ${containerClass}`}>
        <input
          type="text"
          placeholder="Start Typing..."
          className={`text-[var(--primaryTextColor)] focus:outline-none focus:border-blue-500 placeholder-[var(--secondaryTextColor)] text-[14px] bg-transparent py-[8px] ${inputClass}`}
          style={{ width: "90%" }}
          value={textInMessageBox}
          onChange={(e) => {
            // setTemporaryText(e.target.value);
            setTextInMessageBox(e.target.value);
          }}
          onKeyDown={(event: any) => {
            if (event.key === "Enter" || event.keyCode === 13) {
              // Call your function here
              if (!messageResponse) onSend();
              else alert("Wait for response");
              // Call your function here
            }
          }}
        />
        <div
          onClick={() => {
            if (!messageResponse) onSend();
            else alert("Wait for response");
            // else alert("Wait for response");
          }}
          className={`${buttonContainerClass}`}
          // className={`${
          //   temporaryText?.length === 0
          //     ? "cursor-not-allowed"
          //     : "cursor-pointer"
          // }`}
        >
          <img
            src={buttonImage||SendLogo}
            alt="Send"
            className={`${buttonImgClass || 'w-[40px] h-[40px] cursor-pointer'}`}
          />
        </div>
      </div>
    </>
  );
};
