import { useState,useRef,useEffect } from "react";
import moment from "moment";
import ResponseComponent from "../atoms/ResponseComponents/ResponseComponent";
import RatingComponent from "../atoms/RatingComponent/RatingComponent";
import { MessageInput } from "../atoms/MessageInput/MessageInput";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PostChatQuestionAndAnswer } from "../../API/postChatQuestionAndAnswer";
import {
  GeneratingGif,
  sendIcon,
} from "../../pages/assets";
import { copilotProcess } from "../../API/copilotProcess";
import { sendGreyTransparent } from "../../pages/assets";

interface Props{
  skillID:number,
}
const ChatWindow=({skillID=0}:Props)=>{
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const divRef = useRef<HTMLDivElement>(null);
  const [temporaryText, setTemporaryText] = useState("");
  const [loading, setLoading] = useState(false);
  const [questionAndAnswers, setQuestionAndAnswers] = useState([]);
  const [postResponse, setPostResponse] = useState();
  const {webbot_id}: any = useParams();
  const [conversationId, setConversationId] = useState("");
  const [chatOptions, setChatOptions] = useState([]);
  const [initialMessage, setInitialMessage] = useState("How can I assist you ?");

  useEffect(() => {
    // This effect is being called twice, but only happens during development ebcause of strict mdoe being enabled
    copilotProcess.createConversationId(webbot_id).then((res: any) => {
      setConversationId(res);
    }).catch((err) => { console.error(err) });
    copilotProcess.getChatSuggestions(webbot_id, setChatOptions);
    copilotProcess.getInitialMessage(webbot_id, setInitialMessage);
  }, []);
 

  useEffect(() => {
    localStorage.setItem("conversationId", conversationId);
  }, [postResponse]);

  useEffect(() => {
    // Scroll to the bottom of the div
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [questionAndAnswers]);


  const handlePostChat = () => {
    PostChatQuestionAndAnswer(
      Number(webbot_id),
      text,
      setQuestionAndAnswers,
      setTemporaryText,
      setLoading,
      conversationId,
      skillID.toString(),
      setText,
      dispatch,
      setChatOptions
    );
  }
    return(<div className="w-full h-full overflow-hidden">
    <div className="p-2 border-b-[1px] h-[40px]  w-full">
      <p className="text-sm text-[#a5a3a3] text-left pl-5">
       {moment().format("MMMM D, YYYY")}
      </p>
    </div>
    <>
      <div style={{flexFlow:'column'}} className="  h-[calc(100%_-_40px)]  w-full relative  flex ">
        <div
          className="overflow-y-auto custom-scrollbar flex-1"
          ref={divRef}
        >
          <div className="">
            {questionAndAnswers?.length >= 0 ? (
               <ResponseComponent response={initialMessage} chatHistory={true} time={moment().local().format("LT")}
               name="M"
               isAns={true} />
            ) : null}
            {questionAndAnswers?.map((data: any, index: any) => {
              var stillUtc = moment.utc(data.created_date).toDate();
              let time = moment(stillUtc).local().format("LT");
              return (
                <div key={data.id}>
                <ResponseComponent response={data.question} chatHistory={true} time={time} name="U" />
                <div className=" pr-2 py-2.5 flex  justify-between bg-[#F4F4F4]">
                <ResponseComponent chatHistory={true} response={data.answer}
                  time={time}
                  name="M"
                  isAns={true}
                />
                <RatingComponent data={data}/>
                </div>
          
                </div>
              );
            })}
            {loading ? (
              <ResponseComponent response={temporaryText} chatHistory={true}  name="U" />
            ) : null}
            {loading ? (
              <div className="flex gap-[10px] my-[10px]">
                <img
                  src={sendIcon}
                  alt="copilot"
                  className="w-[20px] h-[20px]"
                />
                <div className="py-[1.5%] px-[2%] rounded-[10px] md:max-w-[70%] flex justify-start items-start">
                  <img
                    src={GeneratingGif}
                    alt="Your GIF"
                    className="w-[40px]"
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="w-full  overflow-x-auto horizantal-scrollbar">
          <div className="mt-[20px] flex gap-[10px] pl-[12px]">
            {chatOptions?.map((data: string, index: number) => (
              <div
                key={index}
                className="bg-[#F5EFFF] border-[#d3bef7] rounded-md my-[7px] px-[10px] py-[8px] cursor-pointer flex-shrink-0 min-w-[200px]"
                onClick={() => {
                  setText(data);
                  setTemporaryText(data);
                }}
              >
                <p className="text-[14px] text-[var(--primaryTextColor)]">
                  {data}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="py-2  px-[12px] bottom-[5px] bg-[#F5EFFF]">
          <MessageInput
            textInMessageBox={text}
            setTextInMessageBox={setText}
            onSend={handlePostChat}
            containerClass="h-[38px]  bg-white  rounded-sm  border-[1px] pr-2"
            inputClass="h-full m-0 p-0 border-none"
            buttonContainerClass=""
            buttonImgClass="h-[20px] w-auto mr-[-5px] "
            buttonImage={sendGreyTransparent}
          />
        </div>
      </div >
    </>
  </div>)
}

export default ChatWindow;