import React, { useEffect, useRef, useState } from "react";
import { MessageInput } from "../../atoms/MessageInput/MessageInput";
import ResponseComponent from "../../atoms/ResponseComponents/ResponseComponent";
import {
  GeneratingGif,
  disLikeButton,
  dislikeFrame,
  likeButton,
  likeFrame,
  sendIcon,
} from "../../../pages/assets";
import { LikeResponse } from "../../atoms/LikesPopUps/LikeResponse";

//--------------------------------------FOR RESIZABLE BOX----------------------------------------------------------

import Draggable, { DraggableEvent, DraggableData } from "react-draggable";
import { Resizable } from "re-resizable";
import _ from "lodash";
import { Button } from "../../atoms/Button/Button";
import { useSelector } from "react-redux";
import RatingComponent from "../../atoms/RatingComponent/RatingComponent";
import { copilotProcess } from "../../../API/copilotProcess";

interface CopilotForInstructionsProps {
  preview: boolean;
  setPreview: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  text: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
  temporaryText: string;
  setTemporaryText: React.Dispatch<React.SetStateAction<string>>;
  questionAndAnswers: string[];
  onSend: () => void;
  chatOptions: string[];
}

export const CopilotForInstructions = ({
  preview,
  setPreview,
  questionAndAnswers,
  text,
  setText,
  onSend,
  loading,
  setLoading,
  temporaryText,
  setTemporaryText,
  chatOptions,
}: CopilotForInstructionsProps) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [unlikePopup, setUnlikePopup] = useState(false);
  const webbot_id: any = useSelector((state: any) => state?.webbot?.id);
  const [initialMessage, setInitialMessage] = useState("How can I assist you ?");

  useEffect(() => {
    // Scroll to the bottom of the div
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [questionAndAnswers]);

  useEffect(() => {
    //copilotProcess.getChatSuggestions(webbot_id, setChatOptions);
    copilotProcess.getInitialMessage(webbot_id, setInitialMessage);
  }, []);

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedback("");
  };

  const closeUnlikePopup = () => {
    setUnlikePopup(false);
    setFeedback("");
  };

  const handleLikeFeedBack = () => {
    console.debug("Liked the Response", feedback);
    setFeedback("");
    setIsPopupOpen(false);
  };
  const handleDisLikeFeedBack = () => {
    console.debug("Not Liked the Response", feedback);
    setFeedback("");
    setUnlikePopup(false);
  };

  const [isLikePopupOpen, setIsLikePopupOpen] = useState<any>([]); // State to track the open/close status of LikeResponse popups
  const [isUnlikePopupOpen, setIsUnlikePopupOpen] = useState<any>([]); // State to track the open/close status of LikeResponse popups

  const openLikePopup = (index: any) => {
    const updatedState = [...isLikePopupOpen];
    updatedState[index] = true;
    setIsLikePopupOpen(updatedState);
  };

  // Function to close the LikeResponse popup at a specific index
  const closeLikePopup = (index: any) => {
    const updatedState = [...isLikePopupOpen];
    updatedState[index] = false;
    setIsLikePopupOpen(updatedState);
  };

  const openUnlikePopup = (index: any) => {
    const updatedState = [...isLikePopupOpen];
    updatedState[index] = true;
    setIsUnlikePopupOpen(updatedState);
  };

  // Function to close the LikeResponse popup at a specific index
  const closeUnlikedPopup = (index: any) => {
    const updatedState = [...isLikePopupOpen];
    updatedState[index] = false;
    setIsUnlikePopupOpen(updatedState);
  };

  //----------------------------------------------------FOR RESIZABLE BOX----------------------------------------------------------------------------

  const [isResizing, setIsResizing] = useState(false);
  const [position, setPosition] = useState<[number, number]>([0, 0]);
  const [maxHeight, setMaxHeight] = useState<number | string>("30vh");
  const [bounds, setBounds] = useState<
    { left?: number; right?: number; top?: number; bottom?: number } | undefined
  >(undefined);

  const onResizeStart = (event: unknown, direction: string) => {
    switch (direction) {
      case "bottom":
      case "right":
        setIsResizing(true);
        break;
      case "left":
        setBounds({
          ...bounds,
          left: -Infinity,
          right: Infinity,
        });
        break;
      case "top":
        setBounds({
          ...bounds,
          top: -Infinity,
          bottom: 10,
        });
        break;
    }
  };

  const onResizeEnd = (e: any, direction: any, ref: any, delta: any) => {
    const newMaxHeight = ref.style.height || "30vh"; // Default to 20vh if no height is set

    setMaxHeight(newMaxHeight);
    setIsResizing(false);
    setBounds(undefined);
  };

  const onDragStart = () => {
    if (isResizing) {
      return false;
    }
  };

  const onDrag = (event: DraggableEvent, data: DraggableData) => {
    if (isResizing) {
      return false;
    }

    setPosition([data.x, data.y]);
  };

  return (
    <div className="bg-[#f5f5f5] max-h-[55vh] ">
      <Draggable
        onStart={onDragStart}
        onDrag={onDrag}
        bounds={bounds}
        position={_.zipObject(["x", "y"], position) as { x: number; y: number }}
      >
        <div className="relative bg-[#f5f5f5] rounded-[10px]">
          <Resizable
            className="dupa z-10 bg-[#f5f5f5] p-[10px] border-t-gray-800 rounded-[10px]"
            defaultSize={{
              width: "",
              height: "",
            }}
            onResizeStart={onResizeStart}
            onResizeStop={onResizeEnd}
            enable={{
              top: true,
              right: false,
              bottom: false,
              left: false,
              topRight: true,
              bottomRight: false,
              bottomLeft: false,
              topLeft: true,
            }}
            // maxHeight={maxHeight}
          >
            <div
              className={` overflow-y-auto custom-scrollbar `}
              // className="h-full"
              ref={divRef}
              style={{ height: maxHeight }}
            >
              <div>
                {questionAndAnswers?.length >= 0 ? (
                  <div className="flex gap-[10px] my-[10px] pr-[8px]">
                    <img
                      src={sendIcon}
                      alt="copilot"
                      className="w-[20px] h-[20px]"
                    />
                    <div className="py-[1.5%] px-[2%] bg-[#fff] rounded-[10px] w-full flex justify-between">
                      <p className="text-[#707070] font-extrabold text-[80%] text-left w-[91%]">
                        {initialMessage}
                      </p>
                      {/* <div className="flex gap-[10px] justify-start items-start ">
                        <img
                          src={likeButton}
                          alt="like"
                          className="w-[20px] h-[20px] cursor-pointer"
                          onClick={() => setIsPopupOpen(true)}
                        />
                        {isPopupOpen && (
                          <LikeResponse
                            onClose={closePopup}
                            setFeedBack={(newFeedback) =>
                              setFeedback(newFeedback)
                            }
                            feedback={feedback}
                            handleFunction={handleLikeFeedBack}
                            image={likeFrame}
                            placeholder="What do you like about the response?"
                          />
                        )}
                        {isPopupOpen && (
                          <div
                            className="fixed inset-0 bg-black opacity-50 z-10"
                            onClick={closePopup}
                          ></div>
                        )}
                        <img
                          src={disLikeButton}
                          alt="like"
                          className="w-[20px] h-[20px] cursor-pointer"
                          onClick={() => setUnlikePopup(true)}
                        />
                        {unlikePopup && (
                          <LikeResponse
                            onClose={closeUnlikePopup}
                            setFeedBack={(newFeedback) =>
                              setFeedback(newFeedback)
                            }
                            feedback={feedback}
                            handleFunction={handleDisLikeFeedBack}
                            image={dislikeFrame}
                            placeholder="What was the issue with the response?How could it be improved?"
                          />
                        )}
                        {unlikePopup && (
                          <div
                            className="fixed inset-0 bg-black opacity-50 z-10"
                            onClick={closeUnlikePopup}
                          ></div>
                        )}
                      </div> */}
                    </div>
                  </div>
                ) : null}
                {questionAndAnswers?.map((data: any, index: any) => {
                  return (
                    <div key={data.id}>
                      {data.answer && (
                        <div className="flex gap-[10px] my-[10px]">
                          <div className="w-[20px] h-[20px] border border-[var(--other)] bg-[var(--other)] rounded-full"></div>
                          <div className="py-[1.5%] px-[2%] bg-[var(--other)] rounded-[10px] md:w-full mr-2">
                            <p className="text-[var(--primaryTextColor)] text-[80%] text-left">
                              {data?.question}
                            </p>
                          </div>
                        </div>
                      )}
                      {data.question && (
                        <div className="flex gap-[10px] my-[10px]">
                          <img
                            src={sendIcon}
                            alt="copilot"
                            className="w-[20px] h-[20px] mt-[2px]"
                          />
                          <div className="flex justify-between py-[1.5%] px-[2%] bg-[#fff] rounded-[10px] md:w-[95%] mr-2">
                            <div className="w-[90%]">
                              <ResponseComponent
                                response={data?.answer}
                                // response={
                                //   "I apologize for the inconvenience. Here are the images of the recommended dance party outfits:\n\n1. Ribbed Signature Dress - Xs / CandyRed\n   ![Ribbed Signature Dress - Xs / CandyRed](https://www.zykaz.com/cdn/shop/products/1666206236-jung12_1800x1800.jpg?v=1688984130)\n\n2. How You Doin Skinfit\n   ![How You Doin Skinfit](https://www.zykaz.com/cdn/shop/files/IMG_4289_1800x1800.jpg?v=1691227363)\n\nPlease let me know if you like any of these options or if you have any specific preferences."
                                // }
                              />
                            </div>
                            <RatingComponent data={data} />
                            {/* <div
                              key={index}
                              className="flex gap-[10px] justify-start items-start "
                            >
                              <img
                                src={likeButton}
                                alt="like"
                                className="w-[20px] h-[20px] cursor-pointer"
                                onClick={() => openLikePopup(index)}
                              />
                              {isLikePopupOpen[index] && (
                                <>
                                  <LikeResponse
                                    onClose={() => closeLikePopup(index)}
                                    setFeedBack={(newFeedback) =>
                                      setFeedback(newFeedback)
                                    }
                                    feedback={feedback}
                                    handleFunction={handleLikeFeedBack}
                                    image={likeFrame}
                                    placeholder="What do you like about the response?"
                                  />
                                  <div
                                    className="fixed inset-0 bg-black opacity-50 z-10"
                                    onClick={() => closeLikePopup(index)}
                                  ></div>
                                </>
                              )}
                              <img
                                src={disLikeButton}
                                alt="like"
                                className="w-[20px] h-[20px] cursor-pointer"
                                onClick={() => openUnlikePopup(index)}
                              />
                              {isUnlikePopupOpen[index] && (
                                <>
                                  <LikeResponse
                                    onClose={() => closeUnlikedPopup(index)}
                                    setFeedBack={(newFeedback) =>
                                      setFeedback(newFeedback)
                                    }
                                    feedback={feedback}
                                    handleFunction={handleDisLikeFeedBack}
                                    image={dislikeFrame}
                                    placeholder="What was the issue with the response?How could it be improved?"
                                  />
                                  <div
                                    className="fixed inset-0 bg-black opacity-50 z-10"
                                    onClick={() => closeUnlikedPopup(index)}
                                  ></div>
                                </>
                              )}
                            </div> */}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
                {loading ? (
                  <div className="flex gap-[10px] my-[10px]">
                    <div className="w-[20px] h-[20px] border border-[var(--other)] bg-[var(--other)] rounded-full"></div>
                    <div className="py-[1.5%] px-[2%] bg-[var(--other)] rounded-[10px] md:w-full">
                      <p className="text-[var(--primaryTextColor)] text-[80%] text-left">
                        {temporaryText}
                      </p>
                    </div>
                  </div>
                ) : null}
                {loading ? (
                  <div className="flex gap-[10px] my-[10px]">
                    <img
                      src={sendIcon}
                      alt="copilot"
                      className="w-[20px] h-[20px]"
                    />
                    <div className="py-[1.5%] px-[2%] rounded-[10px] md:max-w-[70%] flex justify-start items-start">
                      <img
                        src={GeneratingGif}
                        alt="Your GIF"
                        className="w-[40px]"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </Resizable>
          <div className="w-full overflow-x-auto horizantal-scrollbar px-[10px]">
            <div className="flex gap-[10px]">
              {chatOptions?.map((data: string, index: number) => (
                <div
                  key={index}
                  className="bg-[var(--teritary)] rounded-[10px] px-[12px] py-[10px] cursor-pointer flex-shrink-0 min-w-[200px]"
                  onClick={() => {
                    setText(data);
                    setTemporaryText(data);
                  }}
                >
                  <p className="text-[14px] text-[var(--primaryTextColor)]">
                    {data}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="px-[10px]">
            <MessageInput
              textInMessageBox={text}
              setTextInMessageBox={setText}
              onSend={onSend}
            />
          </div>
          {/* <div className="wi-full flex justify-between items-center px-[10px] pt-[1%]">
            <div className="flex items-center cursor-pointer">
              <p className="text-[red] text-[90%]">Cancel</p>
            </div>
            <div className="flex items-center justify-center pt-[1%]">
              <Button
                size="xsmall"
                bgColor="primary"
                className="py-[8px] rounded-[10px] px-[25px] text-[80%]"
                onClick={() => {
                  // setTestCopilot(!testCopilot);
                }}
              >
                Submit
              </Button>
            </div>
          </div> */}
        </div>
      </Draggable>
    </div>

    //    <div className="w-full overflow-x-auto horizantal-scrollbar">
    //   <div className="flex gap-[10px]">
    //     {chatOptions?.map((data: string, index: number) => (
    //       <div
    //         key={index}
    //         className="bg-[var(--teritary)] rounded-[10px] px-[12px] py-[10px] cursor-pointer flex-shrink-0 min-w-[200px]"
    //       >
    //         <p className="text-[14px] text-[var(--primaryTextColor)]">
    //           {data}
    //         </p>
    //       </div>
    //     ))}
    //   </div>
    // </div>
    // <div>
    //   <MessageInput
    //     text={text}
    //     setText={setText}
    //     onSend={onSend}
    //     temporaryText={temporaryText}
    //     setTemporaryText={setTemporaryText}
    //   />
    // </div>
    // <div className="wi-full flex justify-between items-center px-[1%] pt-[1%]">
    //   <div className="flex items-center cursor-pointer">
    //     <p className="text-[red] text-[90%]">Cancel</p>
    //   </div>
    //   <div className="flex items-center justify-center pt-[1%]">
    //     <Button
    //       size="xsmall"
    //       bgColor="primary"
    //       className="py-[8px] rounded-[10px] px-[25px] text-[80%]"
    //       onClick={() => {
    //         // setTestCopilot(!testCopilot);
    //       }}
    //     >
    //       Submit
    //     </Button>
    //   </div>
    // </div>
    // </div>
  );
};
