import React, { useState, useEffect } from "react";
import { WhiteCopilotLogo, cross, migrateLogo } from "../../../pages/assets";
import { CopilotForBot } from "../UseCasesStep3/CopilotForBot";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { copilotProcess } from "../../../API/copilotProcess";
import { PostChatQuestionAndAnswer } from "../../../API/postChatQuestionAndAnswer";

var callfunction = 0;

const ChatBotModal = ({ isOpen, onClose }: any) => {

  const dispatch = useDispatch();
  const { id, instructionId } = useParams();
  const webbot_id = id;
  const skillId = instructionId!;
  const [preview, setPreview] = useState(false);
  const [questionAndAnswers, setQuestionAndAnswers] = useState([]);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [temporaryText, setTemporaryText] = useState("");
  const [conversationId, setConversationId] = useState("");
  const [chatSuggestions, setChatSuggestions] = useState<string[]>([]);

  useEffect(() => {
    if (callfunction === 0) {
      callfunction = 1;
      getConversationId();
    }
  }, []);

  const getConversationId = () => {
    copilotProcess
      .createConversationId(webbot_id)
      .then((res: any) => {
        callfunction = 0;
        setConversationId(res);
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchData = () => {
    copilotProcess
      .getQuestionAndAnswerBasedOnConversationId(conversationId)
      .then((response) => {
        setQuestionAndAnswers(response?.data?.questionanswers.reverse());
      })
      .catch((error) => {
        return error;
      });
  };
  const handlePostChat = () => {
    PostChatQuestionAndAnswer(
      Number(webbot_id),
      text,
      setQuestionAndAnswers,
      setTemporaryText,
      setLoading,
      conversationId,
      skillId,
      setText,
      dispatch,
      setChatSuggestions
    );
  };

  return (
    <div id="chatBotModal" className="fixed bottom-[70px] right-[0rem] md:right-9 ">
      <div className="bg-[#F5F5F5] h-[88vh] w-screen md:w-[400px] px-0 md:px-0 mx-auto shadow-xl rounded-[20px]">
        <div className="flex justify-between bg-[var(--primary)] rounded-tl-[20px] rounded-tr-[20px] p-[13px] w-full items-center">
          <div className="flex gap-1 items-center">
            <img
              src={WhiteCopilotLogo}
              alt="Logo"
              className="w-[20px] h-[20px]"
            />
            <p className="text-[20px] text-[#ffff] font-extrabold ">AI Copilot</p>
          </div>
          <div
            className=" hover:cursor-pointer text-[var(--primary)] "
            onClick={() => onClose(false)}
          >
            <img
              src={cross}
              alt="Logo"
              className="w-[35px] p-1 bg-[#6f2bd7] rounded-[50%]"
            />
          </div>
        </div>
        <div className=" w-full p-[10px] min-h-[80vh] ">
          <CopilotForBot
            preview={preview}
            setPreview={setPreview}
            questionAndAnswers={questionAndAnswers}
            text={text}
            setText={setText}
            temporaryText={temporaryText}
            setTemporaryText={setTemporaryText}
            onSend={handlePostChat}
            loading={loading}
            setLoading={setLoading}
            chatSuggestions={chatSuggestions}
            setChatSuggestions={setChatSuggestions}
            webbot_id={webbot_id!}
          />
          <div className="flex items-center justify-center pt-[5px] gap-2">
            <p className="text-[10px] text-[var(--primaryTextColor)] font-extrabold">
              Powered by{" "}
            </p>
            <span>
              <img src={migrateLogo} alt="Maigrate" className="w-[70px]" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CustomChatBot = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const modalElement = document.getElementById('chatBotModal');
    console.log(isModalOpen, "isModalOpen");
      if (isModalOpen) {
        // Modal is open, send dimensions
        window.parent.postMessage({
          type: 'MODAL_OPENED',
        }, '*'); // Use '*' if this is meant to be embedded anywhere
      } else {
        // Modal is closed, send close message
        window.parent.postMessage({
          type: 'MODAL_CLOSED'
        }, '*'); // Use '*' if this is meant to be embedded anywhere
      }
  }, [isModalOpen]);

  const openModal = () => {
    if (isModalOpen) {
      setIsModalOpen(false);
      hover ? setHover(false) : setHover(true);
      return;
    }
    setIsModalOpen(true);
    hover ? setHover(false) : setHover(true);
  };
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };
  return (
    <>
      <div
        onClick={openModal}
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
        className="p-[5px] h-[50px] bg-[var(--primary)] rounded-[50px] flex items-center gap-2 cursor-pointer fixed bottom-4 right-[0rem] md:right-9 justify-center"
        style={{
          width: hover ? 100 : 50,
          transition: "ease-out 0.9s",
        }}
      >
        <img src={WhiteCopilotLogo} alt="Logo" className={`w-[25px] h-[25px] ${hover ? "block" : "hidden"}  ` }/>

        <h5
          className={`text-[#fff] ${hover ? "block" : "hidden"}`}
          style={{
            transition: "ease-out 0.5s",
          }}
        >
          copilot
        </h5>
        <h5
          className={`text-[#fff] ${hover ? "hidden" : "block"}`}
          style={{
            transition: "ease-out 0.5s",
          }}
        >
          AI
        </h5>
      </div>
      {isModalOpen ? (
        // <div className="">
        <ChatBotModal isOpen={isModalOpen} onClose={setIsModalOpen} />
      ) : // </div>
      null}
    </>
  );
};
